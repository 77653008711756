import React from "react";
import PropTypes from "prop-types";

import {Form} from "react-bootstrap";
import {VehicleSelectFormRenderer} from "./vehicle-select-form-renderer";


export function VehicleSelectForm(props) {
    const hooks = {
        onSubmit: props.onSubmit,
        onChangeMake: props.onChangeMake,
        onChangeModel: props.onChangeModel,
    };
    return (
        <VehicleSelectFormRenderer
            {...hooks}
            render={({MakeDropdown, ModelDropdown, SubmitButton, ErrorMessage}) => (
                <Form className={props.className}>
                    <MakeDropdown/>
                    <ModelDropdown/>
                    <SubmitButton variant={props.buttonVariant} to={props.submitUrl}>
                        {props.buttonCta}
                    </SubmitButton>
                    <ErrorMessage/>
                </Form>
            )}
        />
    );
}

VehicleSelectForm.propTypes = {
    className: PropTypes.string,

    // SubmitButtonProps
    submitUrl: PropTypes.string,
    buttonVariant: PropTypes.oneOf([
        "dark",
        "primary",
        "success",
    ]).isRequired,
    buttonCta: PropTypes.oneOf([
        "Search",
        "MSRP & FINANCING OFFERS",
        "Submit",
        "Get Instant Offers"
    ]).isRequired,

    // hooks
    onSubmit: PropTypes.func,
    onChangeMake: PropTypes.func,
    onChangeModel: PropTypes.func,
};

VehicleSelectForm.defaultProps = {
    buttonVariant: "success",
    buttonCta: "Get Instant Offers",
};
