import { decorate, observable, computed, action } from 'mobx';
import { BaseStore } from '../../../stores/base-store';

import { fetchFlexData } from '../utils/flex';
import { toScreamingSnakeCase } from '../utils/to-screaming-snake-case';
import VEHICLE_TYPES from '../vehicles-types';

const PRICE_INITIAL_VALUE = 15000;
const CONDITION_INITIAL_VALUE = 'N'; // New


export class VehicleSelfSelectionStore extends BaseStore {
    PRICE_MIN = 1000;
    PRICE_MAX = 30000;

    YEAR_FROM_NEW = 2025;
    YEAR_FROM_USED = 2024;
    YEARS_TO_SHOW = 20;

    priceSliderOpen = false;

    // observables
    type = {
        id: null,
        subcategories: null,
        default: null,
        name: null,
        icon: null,
        anchor: null,
        minValue: null,
        maxValue: null,
    };
    flexData = null;
    isFlexExperience = false;

    condition = CONDITION_INITIAL_VALUE;
    year = null;
    make = { id: 'none', name: 'none', preselected: false };
    model = { id: 'none', name: 'none', vehicle_type: 'none', rv_type: 'none' };
    price = PRICE_INITIAL_VALUE;

    makesOptions = [];
    modelsOptions = [];

    vehicleTypes = VEHICLE_TYPES.types.filter((type) => type.enabled);

    // to enabled/disabled continue button
    get canContinue() {
        return !!(
            this.type.id &&
            this.condition &&
            this.year &&
            this.make.id &&
            this.model.id &&
            this.price
        );
    }

    loadMakes() {
        // just load makes if we have type, condition and year selected
        if (!this.type.id || !this.condition || !this.year) return;

        if (this.make?.preselected) {
            // trigger load of models since make is already selected
            this.loadModels();
            return;
        }

        // reset make/model selection
        this.make = { id: 'none', name: 'none' };
        this.model = { id: 'none', name: 'none', vehicle_type: 'none' };
        this.makesOptions = [];
        this.modelsOptions = [];

        const vehicleType = this.buildVehicleTypesList(this.type);
        let endpoint = '/vehicle_self_selection/makes';
        endpoint += `?vehicle_type_list=${vehicleType}&condition=${this.condition}&year=${this.year}`;

        this.apiStore
            .fetch(endpoint)
            .then((result) => {
                const res = result.response;
                const listMakes = res.map((make) => {
                    make['name'] = make['name'].toUpperCase();
                    return make;
                });
                this.makesOptions = listMakes;
            })
            .catch((error) => {
                console.error(error, '[VSS] error loading makes', endpoint);
            });
    }

    loadModels() {
        if (
            !this.type.id ||
            !this.condition ||
            !this.year ||
            this.make.id === 'none'
        )
            return;

        // reset model options and selection
        this.model = { id: 'none', name: 'none', vehicle_type: 'none', rv_type: 'none' };
        this.modelsOptions = [];

        const vehicleType = this.buildVehicleTypesList(this.type);
        let endpoint = '/vehicle_self_selection/models';
        endpoint += `?make_id=${this.make.id}&vehicle_type_list=${vehicleType}&condition=${this.condition}&year=${this.year}`;

        this.apiStore
            .fetch(endpoint)
            .then((result) => {
                const res = result.response;
                const listModels = res.map((model) => {
                    model['name'] = model['name'].toUpperCase();
                    return model;
                });
                this.modelsOptions = listModels;
            })
            .catch((error) => {
                console.error(error, '[VSS] error loading Models', endpoint);
            });
    }

    buildVehicleTypesList(type) {
        if (type.subcategories) {
            return type.subcategories.join(',');
        }

        return type.id;
    }

    updateType(type) {
        this.type = type;
        // set anchor, min and max values for Slider Price
        this.priceSliderOpen = false;
        this.price = type.anchor;
        this.PRICE_MIN = type.lowerBound;
        this.PRICE_MAX = type.upperBound;
    }

    updateModelSelected(modelSelected) {
        this.model = modelSelected;
        this.priceSliderOpen = false;

        if (modelSelected.name === 'none') this.price = this.type.anchor;

        // update price based on condition
        if (this.condition === 'N' && modelSelected.msrp)
            this.price = modelSelected.msrp;

        if (this.condition === 'U' && modelSelected.price)
            this.price = modelSelected.price;

        if (!modelSelected.msrp && !modelSelected.price) {
            console.error(
                'VSS: an error occurred trying to set price based on model price/msrp',
                modelSelected
            );
            this.priceSliderOpen = true;
        }
    }

    updateField(field, value) {
        this[field] = value;
        return this;
    }

    async setupFlex(flexSlug, makePreselected) {
        if (!flexSlug) return;

        try {
            this.isFlexExperience = true;
            const flexData = await fetchFlexData(
                flexSlug,
                this.apiStore,
                makePreselected
            );
            if (!flexData) this.historyStore.history.push('/'); // Redirects to octane.co when flex is not enabled

            this.flexData = flexData;

            if (makePreselected && flexData.make_id === null) {
                const currentUrl = new URL(window.location.href);
                currentUrl.searchParams.delete('make');
                window.location.replace(currentUrl.href);
            }

            if ((flexData && makePreselected) || (flexData?.vehicle_types)) {
                this.preselectMake(flexData, makePreselected); 
                this.vehicleTypes = this.vehicleTypes = this.filterVehicleTypesByMake(flexData);
            }

            this.flexData.partner_dealer_selector_enabled =
                await this.dealerSelectorStore.isDealerSelectorEnabled(
                    flexData.partner_id
                );
        } catch (e) {
            console.error(e, 'VSS an error occurred trying to setupFlex');
        }
    }

    buildVssQueryParams() {
        // get currents query Params
        // to redirect to prequal form with VSS query params
        const rawType = this.getRawTypeSelected();
        let url = `rawVehicleData=1&rawType=${rawType}&rawCondition=${this.condition}&rawYear=${this.year}`;

        if (this.make.id && this.make.id !== 'none')
            url += `&rawMake=${this.make.name}`;
        if (this.make.id === 'none')
            // sent dash character "-" for when there is not a Make selected
            // I am not sure - option
            url += `&rawMake=-`;

        if (this.model.id && this.model.id !== 'none')
            url += `&rawModel=${this.model.name}`;
        if (this.model.id === 'none')
            // sent dash character "-" for when there is not a Model selected
            // I am not sure - option
            url += `&rawModel=-`;

        if (this.price) url += `&rawPrice=${this.price}`;
        if (rawType === 'rv' && this.model.rv_type && this.model.rv_type !== 'none')
            url+= `&rawSubcategory=${toScreamingSnakeCase(this.model.rv_type)}`;

        if (this.isFlexExperience && this.flexData) {
            const flexUrlData = this.addFlexData();
            url += flexUrlData;
        }

        return url;
    }

    addFlexData() {
        let flexUrlData = '';
        if (
            this.flexData.dealership_id &&
            this.flexData.partner_id &&
            this.flexData.flow_name
        ) {
            flexUrlData += `&isFlex=1`;
            flexUrlData += `&partner=${this.flexData.partner_id}`;
            flexUrlData += `&flow=${this.flexData.flow_name}`;

            // only append dealership id if dealer selector is disabled for that partner
            if (!this.flexData.partner_dealer_selector_enabled)
                flexUrlData += `&dealership=${this.flexData.dealership_id}`;
        } else {
            console.error(
                'Failed to add flexData to query params.',
                this.flexData
            );
        }

        return flexUrlData;
    }

    getVehicleName({ raw_make, raw_model, raw_type }) {
        if (raw_make === '-') return this.getCategoryName(raw_type);
        const model = !raw_model || raw_model === '-' ? '' : raw_model;
        return `${raw_make} ${model}`;
    }

    getCategoryName(vehicleType) {
        const category = VEHICLE_TYPES.types.find(
            (type) => type.id === vehicleType
        );
        return category ? category.name : '-';
    }

    getVehicleCategory(vehicleTypeId) {
        return VEHICLE_TYPES.types.find((type) => {
            if (type.id === vehicleTypeId) return true;
            return (
                type.subcategories && type.subcategories.includes(vehicleTypeId)
            );
        });
    }

    getRawTypeSelected() {
        if (this.model.vehicle_type && this.model.vehicle_type !== 'none')
            return this.model.vehicle_type;

        // if has subcategories and not model selected
        // return default subcategory
        if (this.type.subcategories) return this.type.default;

        // if doesn't have subcategories
        return this.type.id;
    }

    filterVehicleTypesByMake() {
        const listVehicleTypes = this.flexData.vehicle_types;

        // just show vehicle types enabled and allowed for that Make	
        return VEHICLE_TYPES.types.filter((type) => {
            return (
                type.enabled &&
                listVehicleTypes.find(
                    (typeByMake) =>
                        typeByMake === type.id ||
                        type.subcategories?.includes(typeByMake)
                )
            );
        });
    }

    preselectMake(flexData, makePreselected) {
        if (makePreselected && flexData && flexData.make_id) {
            this.make = {
                id: flexData.make_id,
                name: makePreselected,
                preselected: true,
            };
            this.makesOptions = [this.make];
        }
    }
}

decorate(VehicleSelfSelectionStore, {
    type: observable,
    price: observable,
    make: observable,
    model: observable,
    condition: observable,
    year: observable,
    makesOptions: observable,
    modelsOptions: observable,
    flexData: observable,
    isFlexExperience: observable,
    vehicleTypes: observable,

    canContinue: computed,

    buildVssQueryParams: action,
    buildVehicleTypesList: action,
    loadModels: action,
    updateField: action,
    getCategoryName: action,
    getVehicleCategory: action,
    filterVehicleTypesByMake: action,
});
