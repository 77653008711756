import url from 'url';
import 'isomorphic-fetch';
import promise from 'es6-promise';
import mapValues from 'lodash.mapvalues';
import {BaseStore} from "./base-store";

promise.polyfill();


/**
 * @class ApiStore
 *
 * Global Store for fetching data from the api server
 *
 * @property {string} apiUri
 * @property {string} apiServerRoot
 */
export class ApiStore extends BaseStore {
    apiUris = {
        apiV1: "api/v1",
        apiV2: "api/v2",
    }
    apiServerRoot = null;

    preRenderSetup() {
        const {BASE_API_URL, BASE_API_URL_SERVER} = this.rideOctaneStore.settings;

        if (this.rideOctaneStore.isUXServer) {
            this.apiServerRoot = BASE_API_URL_SERVER;
        } else {
            this.apiServerRoot = BASE_API_URL;
        }

        return super.preRenderSetup();
    }

    dehydrateState() {
        const state = super.dehydrateState();

        delete state.apiServerRoot;

        return state;
    }

    /**
     * @param {string} endpoint
     * @param {{data?: Object|FormData|BodyInit, method?: string, query?: object}} options
     * @return {Promise<Response>}
     * @throws {Error} if no endpoint given
     */
    fetch(endpoint, options = {}) {
        const {data, method, query} = options;
        const fetchUrl = this.getFetchUrl(endpoint, query);
        const fetchOptions = {headers: {}};

        if (method) {
            fetchOptions.method = method;
        }

        if (data) {
            if (data instanceof FormData) {
                fetchOptions.body = data;

            } else if (data instanceof Object) {
                fetchOptions.body = JSON.stringify(data);
                fetchOptions.headers['Content-Type'] = 'application/json';

            } else {
                fetchOptions.body = data;
            }
        }

        return fetch(fetchUrl, fetchOptions).then(res => {
            const {status} = res;
            const contentType = res.headers.get("content-type");
            if (!contentType || contentType.indexOf("application/json") === -1) {
                return res;
            }
            return res.json().then(response => ({status, response}));
        });
    }

    /**
     * Returns the full API server url to fetch
     *
     * @param {string} endpoint
     * @param {object} [query]
     * @returns {string}
     * @throws {Error} if not endpoint supplied
     */
    getFetchUrl(endpoint, query) {

        if (!endpoint) {
            throw new Error(`ApiStore.getFetchUrl expected endpoint to be defined and not blank, instead received: ${endpoint}`);
        }

        let fetchUrl = endpoint;

       // add default api uri (api/v1) when one is not already present
       const urlContainsApiUri = Object.values(this.apiUris).filter((apiUri) => fetchUrl.indexOf(apiUri) > -1).length > 0
       if (!urlContainsApiUri) {
            if (fetchUrl[0] !== '/') {
                fetchUrl = "/" + fetchUrl;
            }
            fetchUrl = this.apiUris.apiV1 + fetchUrl;
        }

        // add "https://ride-api.octane.co"
        if (fetchUrl.indexOf(this.apiServerRoot) === -1) {
            if (fetchUrl[0] !== '/') {
                fetchUrl = "/" + fetchUrl;
            }
            fetchUrl = this.apiServerRoot + fetchUrl;
        }

        // if there are query params in the endpoint string, move them to query
        if (fetchUrl.includes('?')) {
            const urlParts = fetchUrl.split('?');
            fetchUrl = urlParts[0];

            // merge the query variable with the query parsed from the endpoint string
            const searchParams = new URLSearchParams(urlParts[1]);
            query = {...query, ...Object.fromEntries(searchParams.entries())};
        }

        // add trailing slash
        if (fetchUrl[fetchUrl.length - 1] !== '/') {
            fetchUrl = fetchUrl + '/';
        }

        // add query params
        if (query) {
            fetchUrl = url.format({
                pathname: fetchUrl,
                query: mapValues(query, queryParam => {
                    if (Array.isArray(queryParam)) {
                        return queryParam.join(',');
                    }
                    return queryParam;
                })
            });
        }

        return fetchUrl;
    }

    /**
     * Returns a promise that waits for a specified duration. This is helpful for polling.
     * @param {number} wait - number of milliseconds to wait before resolving.
     * @returns {Promise}
     */
    getWaitPromise = wait => new Promise(resolve => setTimeout(resolve, wait));

    /**
     * Polls endpoint with the specified logic.
     * @param {string} endpoint - the endpoint to poll.
     * @param {function} shouldContinuePolling - a function that takes in the status and the response from fetch and returns
     * a boolean to indicate whether or not to keep polling.
     * @param {number} waitDuration - number of milliseconds to wait before polling again. This defaults to 5000.
     * @returns {Promise<any>}
     */
    pollUrl(endpoint, shouldContinuePolling, waitDuration = 5000) {
        const waitPromise = this.getWaitPromise(waitDuration);

        // wait the specified interval, then fetch endpoint
        const fetchPromise = waitPromise.then(() => this.fetch(endpoint));
        // pass response to the provided response handler.
        return fetchPromise
            .then(({status, response}) => {
                if (shouldContinuePolling(status, response)) {
                    return this.pollUrl(endpoint, shouldContinuePolling, waitDuration);
                }
                return {status, response};
            });
    }
}
