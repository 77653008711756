import {decorate, observable, computed, action} from 'mobx';

import {BaseStore} from "../base-store";
import {ModelPageStore} from "./model-page-store";
import {SubmodelPageStore} from "./submodel-page-store";
import {MakePageStore} from "./make-page-store";


/**
 * @class ListingsStore
 * @inheritDoc
 *
 * Global store for managing Listing Pages
 *  (e.g.  pages with slug matching a
 *      - vehicle make
 *      - vehicle model
 *      - vehicle type
 *      - vehicle sub model
 *      - vehicle
 *  )
 *
 *  @property {string} pageType - one of ListingsStore.PAGE_TYPES
 *  @property {object} pageStore - page specific store
 */
export class ListingsStore extends BaseStore {
    static PAGE_TYPES = {
        MODEL: 'model',
        SUBMODEL: 'submodel',
        MAKE: 'make',
    };
    static PAGE_STORES = {
        [ListingsStore.PAGE_TYPES.MODEL]: ModelPageStore,
        [ListingsStore.PAGE_TYPES.SUBMODEL]: SubmodelPageStore,
        [ListingsStore.PAGE_TYPES.MAKE]: MakePageStore,
    };

    pageType = null;
    pageStore = null;
    isUpdated = true;

    get isLoading() {
        return !this.slugsStore.isUpdated || !this.isUpdated;
    }

    /**
     * Called on both the server and client this sets the initial state of the store
     * before any setup or rendering is performed
     *
     * @param {object} [pageStore] - initial state of the page store
     * @param initialState
     * @returns {ListingsStore}
     */
    setInitialState({pageStore, ...initialState}) {
        super.setInitialState(initialState);
        this.pageStore = this._pageStore(this.pageType, pageStore);

        return this;
    }

    /**
     * Called on both the server and the client after setInitialState and is responsible
     * for preforming any apiCalls that need to happen before the initial render of the application.
     *
     * @returns {ListingsStore}
     */
    preRenderSetup() {
        // store was setup by the server and initialized on the client in setInitialState
        if (this.pageStore) {
            this.pageStore.initializeStore().then(() => (this.isReady = true));
            return this;
        }

        // store was setup on server, but has no pageStore
        if (this.pageType) {
            this.isReady = true;
            return this;
        }

        // store has not been setup yet
        this.slugsStore.onReady(() => {
            this.onSlugOrPathSegmentsChange({slugChanged: true}).then(() => (this.isReady = true));
        });

        return this;
    }

    /**
     * Called on the server and returns the state that is passed to setInitialState on the client
     */
    dehydrateState() {
        const state = super.dehydrateState();

        delete state.isUpdated;
        if (state.pageStore) {
            state.pageStore = this.pageStore.dehydrateState();
        }

        return state;
    }

    /**
     * Called only on the client after the page has rendered
     *
     * @returns {ListingsStore}
     */
    postRenderSetup() {
        return this.onReady(() => {
            // listen for any changes to slug in the slugs store & call handler
            this.slugsStore.onChange(() => {
                this.onSlugOrPathSegmentsChange({slugChanged: true}).then(() => {

                    // listen for changes to pathSegments if there is an active pageStore
                    if (this.pageStore) {
                        this.slugsStore.onChangePathSegments(() => this.onSlugOrPathSegmentsChange());
                    }
                });
            });

            // listen for changes to pathSegments if there is an active pageStore
            if (this.pageStore) {
                this.slugsStore.onChangePathSegments(() => this.onSlugOrPathSegmentsChange());
            }
        });
    }

    /**
     * Invoked whenever the slug changes or a path for a given slug
     *  - sets the pageType
     *  - initializes the appropriate pageStore
     *
     * @param {boolean} slugChanged
     */
    onSlugOrPathSegmentsChange({slugChanged = false} = {}) {
        const slugsStore = this.slugsStore;
        const pageType = this._pageType(slugsStore);

        // store is up to date
        if (!this.shouldUpdateStore({pageType, slugChanged})) {
            return Promise.resolve(this);
        }

        // no matching pageStore for pageType (e.g. non listing slugs, 404 or 500 pages)
        if (!this.hasPageStore(pageType)) {
            return Promise.resolve(this.updateStore({pageType, pageStore: null}));
        }

        // asynchronously update pageType / pageStore
        this.isUpdated = false;
        return this._pageStore(pageType).initializeStore()
            .then(pageStore => {
                if (pageStore.hasError) {
                    return this.updateStore({pageType: null, pageStore: null});
                }
                return this.updateStore({pageType, pageStore});
            });
    }

    /**
     * Called in onSlugOrPathChanged (based on the pageType, determines if updateStore should be called)
     *  SHOULD NOT UPDATE IF...
     *      - non listing page => non listing page
     *      - same pageType for same slug
     * @param {string|null} pageType
     * @param {boolean} slugChanged
     * @returns {boolean}
     */
    shouldUpdateStore({pageType, slugChanged}) {
        // non listing page => non listing page
        if (!this.pageType && !pageType) {
            return false;
        }

        // changed path for same slug
        if ((pageType === this.pageType) && !slugChanged) {
            return false;
        }

        return true;
    }

    /**
     * Updates appropriate store parameters
     *
     * @param {string|null} pageType
     * @param {BasePageStore|null} pageStore
     * @returns {ListingsStore}
     */
    updateStore({pageType, pageStore}) {
        this.isUpdated = true;
        this.pageType = pageType;
        this.pageStore = pageStore;

        return this;
    }

    /**
     * Determines the pageType based on the slugsStore state
     *
     * @param {SlugsStore} slugsStore
     * @returns {string|null}
     * @private
     */
    _pageType(slugsStore) {
        const {slugData} = slugsStore;

        if (slugData) {
            if (slugData.vehicle_configuration) {
                // we have no vehicle_configuration pages

            } else if (slugData.vehicle_trim) {
                // we have no vehicle_trim pages

            } else if (slugData.vehicle_sub_model) {
                return this.constructor.PAGE_TYPES.SUBMODEL;

            } else if (slugData.vehicle_model) {
                return this.constructor.PAGE_TYPES.MODEL;

            } else if (slugData.vehicle_make) {
                return this.constructor.PAGE_TYPES.MAKE;
            }
        }

        // encountered no matching listing page types
        return null;
    }

    /**
     * Constructs the correct pageStore model based on the pageType
     *
     * @param {string} pageType
     * @param {object} [initialState]
     * @returns {BasePageStore|null}
     * @private
     */
    _pageStore(pageType, initialState) {
        if (this.hasPageStore(pageType)) {
            const PageStore = this.getPageStoreConstructor(pageType);
            return new PageStore(this, initialState);
        }
        return null;
    }

    hasPageStore = pageType => !!this.getPageStoreConstructor(pageType);
    getPageStoreConstructor = pageType => this.constructor.PAGE_STORES[pageType] || null;
}

decorate(ListingsStore, {
    pageType: observable,
    pageStore: observable,
    isUpdated: observable,

    isLoading: computed,

    updateStore: action,
});
