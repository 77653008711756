import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {observer, inject} from 'mobx-react';

import copy from './copy.json';
import styles from './trim-and-color-picker-form.module.scss';

import {ListDropdown} from "../dropdown";
import {TrimAndColorPickerFormStore} from "./trim-and-color-picker-form-store";
import {pushSelectItem} from "../../utils/gtm-utils";


export const TrimAndColorPickerForm = inject('apiStore')(
    observer(
        class _TrimAndColorPickerForm extends React.Component {
            static propTypes = {
                apiStore: PropTypes.shape({
                    fetch: PropTypes.func.isRequired,
                }).isRequired,
                vehicle_sub_model: PropTypes.shape({
                    uuid: PropTypes.string.isRequired,
                }),
                vehicle_configuration: PropTypes.shape({
                    uuid: PropTypes.string.isRequired,
                    msrp: PropTypes.number.isRequired,
                }),

                // hooks
                onSubmit: PropTypes.func,
                updateSelectedVehicle: PropTypes.func,
            };

            store = new TrimAndColorPickerFormStore(this.props);

            componentDidMount() {
                this.store.populateOptions(this.props);
            }

            TrimPicker = observer(({copy}) => (
                <div data-oid="trim-picker">
                    <ListDropdown
                        controlId={copy.id}
                        label={copy.label}
                        placeholder={copy.placeholder}
                        items={this.store.trims}
                        selected={this.store.selectedTrim}
                        onChange={this.updateTrim}
                    />
                    <small className="float-right">
                        {copy.sub_text}
                    </small>
                </div>
            ));

            ColorPicker = observer(({copy}) => (
                <div data-oid="color-picker" id={copy.id}>
                    <h6>{copy.label}</h6>
                    <div>
                        {this.store.colors.map(color => (
                            <img
                                key={color.uuid}
                                alt={color.name}
                                src={color.color_url}
                                className={classnames(
                                    styles.colorSwatch,
                                    'rounded-circle mr-1',
                                    {[styles.chosenColor]: this.store.isSelectedColor(color)}
                                )}
                                onClick={() => this.updateColor(color)}
                            />
                        ))}
                    </div>
                    <div className={classnames('mb-4 small', styles.capitalize)}>
                        {this.store.selectedColorLabel}
                    </div>
                </div>
            ));

            updateTrim = selectedTrim => {
                this.store.updateSelectedTrim(selectedTrim);
                if (this.props.updateSelectedVehicle && this.store.colors.length > 0) {
                    this.props.updateSelectedVehicle(this.store.colors[0]);
                }
            };

            updateColor = selectedColor => {
                this.store.updateSelectedColor(selectedColor);
                if (this.props.updateSelectedVehicle) {
                    this.props.updateSelectedVehicle(selectedColor);
                }
            };

            render() {
                return (
                    <div>
                        {pushSelectItem(
                            this.props.vehicle_sub_model,
                            this.props.apiStore.rideOctaneStore,
                        )}
                        <this.TrimPicker copy={copy.trim_picker}/>
                        <this.ColorPicker copy={copy.color_picker}/>
                    </div>
                );
            }
        }
    )
);
