import React from 'react';
import classnames from 'classnames';

import styles from './fancy-spinner.module.scss';


const defaultValues = {
    spinnerSize: 66,
    spinnerRadius: 28,
};
const toInt = (key, props) => {
    const intVal = parseInt(props[key] || styles[key]);
    return !isNaN(intVal) ? intVal : defaultValues[key];
};

export function FancySpinner(props) {
    const spinnerSize = toInt('spinnerSize', props);
    const spinnerRadius = toInt('spinnerRadius', props);
    const svgAttrs = {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: `0 0 ${spinnerSize} ${spinnerSize}`,
    };
    const circleAttrs = {
        r: spinnerRadius,
        cx: spinnerSize / 2,
        cy: spinnerSize / 2,
    };

    return (
        <div className={classnames(styles.fancySpinner, props.className)}>
            <Stroke svgAttrs={svgAttrs} circleAttrs={circleAttrs}/>
            <Stroke svgAttrs={svgAttrs} circleAttrs={circleAttrs}/>
            <Stroke svgAttrs={svgAttrs} circleAttrs={circleAttrs}/>
            <Stroke svgAttrs={svgAttrs} circleAttrs={circleAttrs}/>
        </div>
    );
}

function Stroke({svgAttrs, circleAttrs}) {
    return (
        <svg className={styles.stroke} {...svgAttrs}>
            <circle {...circleAttrs}/>
        </svg>
    );
}

