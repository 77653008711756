import React from "react";
import PropTypes from "prop-types";
import classnames from 'classnames';

// svg configs
import octaneLogo from './octane-logo.json';
import cmpIcon from './complete-my-paperwork-icon';
import check from './check';
import uploadComplete from './upload-complete';
import processingIcon from './processing-icon';
import curvedArrow from './curved-arrow';
import pickVehicleMobile from './pick-vehicle-mobile';
import pickVehicleDesktop from './pick-vehicle-desktop';
import badgeAndPaper from './badge-and-paper';
import badgeMobile from './badge-mobile';
import signAndDrive from './sign-and-drive';
import signAndDriveMobile from './sign-and-drive-mobile';
import timeSaving from './time-saving';
import speedometer from './speedometer';
import supportHeadphones from './support-headphones';
import reminderIcon from './reminder-icon';
import emailSent from './email-sent';
import noVehicle from './no-vehicle';
import tradeIn from './trade-in';
import weWillCall from './we-will-call.json';
import moreInformation from './more-information';
import customIntercomIcon from './custom-intercom-icon.json';

export const SVG_CONFIGS = {
    'octane-logo': octaneLogo,
    'complete-my-paperwork-icon': cmpIcon,
    'check': check,
    'upload-complete': uploadComplete,
    'processing-icon': processingIcon,
    'curved-arrow': curvedArrow,
    'pick-vehicle-mobile': pickVehicleMobile,
    'pick-vehicle-desktop': pickVehicleDesktop,
    'badge-and-paper': badgeAndPaper,
    'badge-mobile': badgeMobile,
    'sign-and-drive': signAndDrive,
    'sign-and-drive-mobile': signAndDriveMobile,
    'time-saving': timeSaving,
    'speedometer': speedometer,
    'support-headphones': supportHeadphones,
    'reminder-icon': reminderIcon,
    'email-sent': emailSent,
    'no-vehicle': noVehicle,
    'trade-in': tradeIn,
    'we-will-call': weWillCall,
    'more-information': moreInformation,
    'custom-intercom-icon': customIntercomIcon,
};

export function Svg({svg, ...props}) {
    const config = SVG_CONFIGS[svg];
    const attrs = {
        ...config.attrs,
        className: classnames(`${svg}-svg`, props.className),
    };

    if (props.alt) {
        attrs.alt = props.alt;
    }
    if (props.title) {
        attrs.title = props.title;
    }
    if (props.style) {
        attrs.style = {...attrs.style, ...props.style};
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...attrs}>
            {config.shapes.map((shape, i) => <SvgShape key={i} shape={shape}/>)}
        </svg>
    );
}

Svg.propTypes = {
    alt: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    svg: PropTypes.oneOf(Object.keys(SVG_CONFIGS)).isRequired,
};


function SvgShape({shape}) {
    if (shape.type === 'circle') {
        return <circle fill={shape.fill} cx={shape.cx} cy={shape.cy} r={shape.r}/>;
    }

    if (shape.type === 'polygon') {
        return <polygon fill={shape.fill} stroke={shape.stroke} strokeWidth={shape.strokewidth} points={shape.points}/>;
    }
    if (shape.type === 'g') {
        return (
            <g transform={shape.transform} fill={shape.fill} stroke={shape.stroke} strokeWidth={shape.strokewidth}
               opacity={shape.opacity}>
                {shape.shapes.map((shape, i) => <SvgShape key={i} shape={shape}/>)}
            </g>
        );
    }
    if (shape.type === 'path') {
        return (
            <path fillRule={shape.fillRule} d={shape.d}/>
        );
    }

    return <path d={shape}/>;
}
