/**
 * Formats a number to USD Price
 *  e.g.:
 *      price = 1000
 *      formatPrice(price) === "$1,000.00"
 *
 * @param {string|number} price
 * @param {boolean} [includeCents]
 * @returns {string}
 */
export function formatPrice(price, includeCents = false) { 
    const formattedPrice = parseFloat(price).toLocaleString('en', {style: 'currency', currency: 'USD'});
    if (includeCents) {
        return formattedPrice;
    }
    return formattedPrice.slice(0, -3);
}

/**
 * Takes an input string, cleans it, and returns it
 * properly formatted.
 * @param {string} input 
 */
export function formatPriceInput(input) {
    input = input.replace(/\D/g, '');
    if (isNumeric(input[input.length - 1])) {
        return formatPrice(input);
    } else if (input === '') {
        return '';
    }
}

function isNumeric(s) {
    return !isNaN(s - parseInt(s));
}

/**
 * Takes an input string, cleans it, and returns
 * a nicely formatted number in the following format:
 * (XXX) XXX-XXXX
 * @param {string} input 
 */
export function formatPhoneNumber(input) {
    input = input.replace(/\D/g, '');

    // keep to 10 digits
    let limitLength = input.match(/\d{1,10}/g);
    if (limitLength ) {
        input = limitLength[0];
    }

    // add parens and dash
    let addChars = input.match(/\d{1,3}/g);
    let toConcat = '';
    let i = 0;
    if (addChars) {
        for (i = 0; i < addChars.length; i++) {
            if (i === 0 && input.length > 3) {
                toConcat += '(' + addChars[i] + ') ';
            } else if (i === 1 && input.length > 6) {
                toConcat += addChars[i] + '-';
            } else {
                toConcat += addChars[i];
            }
        }
    }
    return toConcat;
}

/**
 * Formats ssn input to the following format: XXX-XX-XXXX
 * @param input {string}
 * @returns {string} formatted ssn input.
 */
export function formatSSN(input) {
    // remove non numeric chars, and limit stripped input to first 9 numbers
    let val = input.replace(/\D/g, '');
    val = val.replace(/^(\d{3})/, '$1-');
    val = val.replace(/-(\d{2})/, '-$1-');
    val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
    if (val[val.length - 1] === '-') {
        return val.substr(0, val.length - 1);
    }
    return val;
}
/**
 * Takes a string and removes non numeric characters.
 * @param input
 */
export function cleanNumberString(input){
    if (input && typeof input === 'string'){
        return input.replace(/\D/g, '');
    }
    return input;
}

/**
 * Takes a number and returns its "ordinal suffix"
 *  (e.g.
 *      getOrdinalSuffix(1)     ->  "st"
 *      getOrdinalSuffix(2)     ->  "nd"
 *      getOrdinalSuffix(3)     ->  "rd"
 *      getOrdinalSuffix(4)     ->  "th"
 *  )
 *
 * @param {string|number} num
 * @returns {string}
 */
export function getOrdinalSuffix(num) {
    const onesDigit = num % 10;
    const tensDigit = Math.floor((num % 100) / 10);

    // special cases "xx1st", "xx2nd", "xx3rd" except for teens
    if (tensDigit !== 1) {
        if (onesDigit === 1) {
            return "st";
        }
        if (onesDigit === 2) {
            return "nd";
        }
        if (onesDigit === 3) {
            return "rd";
        }
    }
    return "th";
}

/**
 * Takes a number and returns the number with it's "ordinal suffix"
 *  (e.g.
 *      getOrdinalSuffix(1)     ->  "1st"
 *      getOrdinalSuffix(2)     ->  "2nd"
 *      getOrdinalSuffix(3)     ->  "3rd"
 *      getOrdinalSuffix(4)     ->  "4th"
 *  )
 *
 * @param {string|number} num
 * @returns {string}
 */
export function withOrdinalSuffix(num) {
    return `${num}${getOrdinalSuffix(num)}`;
}

/**
 * Pads the beginning of a numerical string (if necessary) to have it match the desired length.
 * @param numericString {string}
 * @param desiredLength {number}
 * @param paddingCharacter {string} - this is "0" by default. This value is what the function will use to pad.
 * @returns {string}
 */
export function padNumericString(numericString, desiredLength, paddingCharacter = "0") {
    const numDigitsToPad = desiredLength - numericString.length;

    if (numDigitsToPad > 0) {
        return `${paddingCharacter.repeat(numDigitsToPad)}${numericString}`;
    }
    return numericString;
}
