import React from 'react'

export class VehicleListingPlaceholder extends React.Component {
    componentDidMount() {
        if (window && window.history) {
            window.history.scrollRestoration = "manual"
        }
    };

    componentWillUnmount() {
        if (window && window.history) {
            window.history.scrollRestoration = "auto"
        }
    };

    render() {
        return (
            <div className="min-vh-100">
                <div className="spinner-border spark-text-secondary d-block my-5 mx-auto">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }
}