import React from "react";
import {Route, Switch} from 'react-router-dom';

import {Page} from "./page";
import {withTracker} from "../analytics";

// Stores
import {ApiStore} from "../stores/api-store";
import {UserStore} from "../stores/user-store";
import {SlugsStore} from "../stores/slugs-store";
import {ListingsStore} from "../stores/listings-store";

// Components
import {Homepage} from "../homepage";
import {Listings} from "../listings";
import {VehicleSelfSelection} from '../prequal/vehicle-self-selection';
import {ReturnToServer} from "../components/return-to-server";

const HomepageWithTracker = withTracker(Homepage);
const ListingsWithTracker = withTracker(Listings);
const VehicleSelfSelectionWithTracker = withTracker(VehicleSelfSelection);


// Marketplace Page
export function marketplace() {

    const store = Page.createStore({
        isMarketplace: true,
        stores: {
            apiStore: new ApiStore(),
            userStore: new UserStore(),
            slugsStore: new SlugsStore(),
            listingsStore: new ListingsStore(),
        },
    });
    
    const component = Page.createComponent({
        content: (
            <Switch>
                {/* Note: these routes are indifferent to trailing slashes */}
                <Route exact path="/" component={HomepageWithTracker}/>
                <Route exact path={['/vehicle-selection','/flex/:flexSlug']} component={VehicleSelfSelectionWithTracker}/>
                <Route exact path="/:slug/:year?" component={ListingsWithTracker} />
                <Route component={ReturnToServer} />
            </Switch>
        )
    });

    return {store, component};
}
