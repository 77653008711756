
// document types allowed for income verification, determined by LOS django setting ALLOWED_DOCUMENT_CONTENT_TYPES
export const LOS_ALLOWED_DOCUMENT_CONTENT_TYPES = [
    '.pdf',
    '.doc',
    '.docx',
    '.jpg',
    '.jpeg',
    '.tiff',
    '.tif',
    '.gif',
    '.bmp',
    '.png'
];
