/**
 * Fetches flow data from API
 * @param {string} flexSlug
 * @param {object} apiStore
 * @returns {object}
 */
export const fetchFlexData = async (flexSlug, apiStore, makePreselected) => {
    if (!flexSlug) {
        throw new Error('No flexSlug given');
    }

    let endpoint = `/flex_routing/${flexSlug}/`;

    // append make preselected to load data specific to it
    if (makePreselected) endpoint += `?make=${makePreselected}`;

    try {
        const { status, response } = await apiStore.fetch(endpoint);
        if (status !== 200 || !response) {
            if (status !== 404) {
                console.error('Failed to fetch flex data', {
                    endpoint,
                    status,
                    response,
                });
            }
            return null;
        }

        return response;
    } catch (error) {
        console.error('Failed to fetch flex data', { endpoint, error });
        return null;
    }
};
