
/**
 * id: Used by the ListDropdown component as the default for when we pick the form and sync to the API server.
 * name: Used by the ListDropdown component as the value to display to the user.
 */
 export const relationshipToPrimary = [
    {id: 'SPOUSE', name: 'Spouse'},
    {id: 'PARENT', name: 'Parent'},
    {id: 'SIBLING', name: 'Sibling'},
    {id: 'OTHER', name: 'Other'},
];
