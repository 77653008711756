import url from "url";
import {BaseStore} from "./base-store";
import {decorate, observable, computed, action} from 'mobx';
import { createBrowserHistory } from 'history';

/**
 * @class HistoryStore
 *
 * @property {array} preservedQueryParams - a list of query params that are preserved across our experience
 */
export class HistoryStore extends BaseStore {
    history = null;
    preservedQueryParams = [
        'make',
        'partner',
        'partnerVehicleMatch',
        'appTheme',
        'dealership',
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'external_dealership_id',
        // Id of the assigned dealer link
        'a',
        // google tag id from partner
        'partnerGId',
        'flow',
        'isFlex',
        'inStore',
        'instore',
    ];

    // Vehicle Self Selection query params
    vssQueryParams = [
        'rawVehicleData',
        'rawType',
        'rawCondition',
        'rawMake',
        'rawModel',
        'rawYear',
        'rawPrice',
        'rawSubcategory',
    ]

    vssUrlAllowed = [
        'apply',
        'apply/offers',
        'apply/coapplicant',
        'apply/ssn-required',
    ]

    blacklistedCharacters = [
        '<',
        '>',
    ];

    hash = null;
    search = null;
    pathname = null;

    get queryParams() {
        if (!this.search) {
            return {};
        }
        return url.parse(this.search, true).query;
    }

    /**
     * @return {Location}
     */
    currentLocation() {
        const currentLocation = new URL(window.location.href);
        currentLocation.pathname = this.pathname;
        const params = new URLSearchParams(this.search);
        currentLocation.search = params.toString();
        currentLocation.hash = this.hash;
        return currentLocation;
    }

    removeBlacklistedCharacters(search) {
        if(search) {
            this.blacklistedCharacters.forEach(character => {
                search = search.replace(
                    new RegExp(character,"g"),
                    '',
                );
            })
        }
        return search;
    }

    setHash(hash) {
        const {search, pathname} = this.history.location;
        this.history.push(pathname + search + `#${hash}`);
        return this;
    }

    clearHash() {
        const {search, pathname} = this.history.location;
        this.history.push(pathname + search);
        return this;
    }

    preRenderSetup(config) {
        this.updateLocation(config.location);

        if (!this.rideOctaneStore.isUXServer) {
            this.history = this.createRideOctaneHistory();
            this.history.listen(location => this.updateLocation(location));
        }

        return super.preRenderSetup();
    }

    createRideOctaneHistory() {
        const history = createBrowserHistory();

        // override default push / replace
        const overrideDefaultFn = fn => (path, state) => {
            const {pathname, query} = url.parse(path, true);

            // Check if each of our preserved query params exist in the URL and preserve them
            this.preservedQueryParams.forEach(
                param => {
                    if (this.queryParams[param] && !query[param]) {
                        query[param] = this.queryParams[param];
                    }
                }
            );

            // preserve vssParams only in prequal routes
            const isVssUrlAllowed = this.vssUrlAllowed.some(url => pathname.includes(url))
            if(this.queryParams['rawVehicleData'] === '1' && isVssUrlAllowed) {
                this.vssQueryParams.forEach(param => {
                    if (this.queryParams[param] && !query[param]) {
                        query[param] = this.queryParams[param];
                    }
                })
            }

            return fn(url.format({pathname, query}), state);
        }

        history.push = overrideDefaultFn(history.push.bind(history));
        history.replace = overrideDefaultFn(history.replace.bind(history));

        return history;
    }

    dehydrateState() {
        const state = super.dehydrateState();

        delete state.history;

        return state;
    }

    updateLocation({hash, search, pathname}) {
        this.hash = hash || "";
        this.search = this.removeBlacklistedCharacters(search) || "";
        this.pathname = pathname || "";
        return this;
    }

    /**
     * @param {Location} location
     */
    replaceLocation(location) {
        this.hash = location.hash || "";
        this.search = this.removeBlacklistedCharacters(location.search) || "";
        this.pathname = location.pathname || "";
        this.history.replace(location.toString())
    }
}

decorate(HistoryStore, {
    search: observable,
    pathname: observable,
    hash: observable,

    queryParams: computed,

    preRenderSetup: action,
    updateLocation: action,
});
