import React from 'react';
import PropTypes from 'prop-types';

import styles from './fixed-top-bar.module.scss';


export const FixedTopBar = ({className = "", children}) => {
    return (
        <div className={`spark-bg-light ${styles.container} ${className}`}>
            {children}
        </div>
    );
}

FixedTopBar.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

