import {decorate, observable} from "mobx";
import {BaseStore} from "./base-store";

/**
 * @class WaffleStore
 * @inheritDoc
 *
 *  Store for waffle flags and switches
 */
export class WaffleStore extends BaseStore{

    waffleData = {};

    async preRenderSetup(){
        if(this.rideOctaneStore.isUXServer){
            this.waffleData = await this.fetchWaffleData();
            super.preRenderSetup();
            return this;
        }
        return super.preRenderSetup();
    }

    async fetchWaffleData() {
        const {BASE_API_URL, BASE_API_URL_SERVER} = this.rideOctaneStore.settings;
        const serverRoot = this.rideOctaneStore.isUXServer ? BASE_API_URL_SERVER : BASE_API_URL;
        const endpoint = `${serverRoot}/feature_flags/waffle_status`;

        try{
            const response = await fetch(endpoint);
            return await response.json()
        } catch(error){
            console.error("Failed fetching Waffle data", error);
        }
    }

    isSwitchActive(switchName) {
        return this.waffleData?.switches?.[switchName]?.is_active ?? false;
    }

    isFlagActive(flagName) {
        return this.waffleData?.flags?.[flagName]?.is_active ?? false;
    }
}

decorate(WaffleStore, {
    waffleData: observable,
});