import React from 'react';
import PropTypes from 'prop-types';

import {Button} from 'react-bootstrap';

import styles from './file-upload-button.module.scss';

export class FileUploadButton extends React.Component {

    static propTypes = {
        oid: PropTypes.string,
        size: PropTypes.string,
        label: PropTypes.string,
        variant: PropTypes.string,
        className: PropTypes.string,
        isDisabled: PropTypes.bool,

        single: PropTypes.bool,
        inputId: PropTypes.string,
        fieldName: PropTypes.string,
        allowedDocumentTypes: PropTypes.array,

        onClick: PropTypes.func,
        onFilesSelected: PropTypes.func,
    };

    static defaultProps = {
        label: 'Select Files',
        variant: 'primary',
    };

    static idCounter = 0;

    static getUniqueId() {
        return `${this.name}-${this.idCounter++}`;
    }

    files = [];
    input = React.createRef();

    constructor(props, context) {
        super(props, context);

        // because component requires an id and ids must be unique
        // this will ensure that if not passed the id wil be unique
        this.inputId = this.constructor.getUniqueId();
    }

    onChange = () => {
        // update the saved list of files
        const files = this.files = [];
        for (const file of this.input.current.files) {
            this.files.push(file);
        }

        // call onFilesSelected
        if (this.props.onFilesSelected) {
            this.props.onFilesSelected({files});
        }
    };

    HiddenInput = ({inputId}) => {
        const {single, fieldName, allowedDocumentTypes} = this.props;
        const allowedDocTypes = allowedDocumentTypes ? allowedDocumentTypes.join(', ') : null;

        return (
            <input
                type="file"
                id={inputId}
                name={fieldName}
                multiple={!single}
                accept={allowedDocTypes}

                ref={this.input}
                className={styles.input}
                onChange={this.onChange}
            />
        );
    };

    render() {
        const inputId = this.props.inputId || this.inputId;
        const {oid, className, variant, label, size, onClick, isDisabled} = this.props;

        let buttonProps = {
            disabled: isDisabled,
            size,
            variant,
            className,
            onClick,
        };
        if (!isDisabled) {
            buttonProps = {
                ...buttonProps,
                type: '',
                as: 'label',
                htmlFor: inputId,
            };
        }

        return (
            <React.Fragment>
                {/* Hidden Input */}
                <this.HiddenInput inputId={inputId}/>
                {/* Visible Button */}
                <Button block data-oid={oid} {...buttonProps}>
                    {label}
                </Button>
            </React.Fragment>
        );
    }
}
