import React from "react";
import PropTypes from "prop-types";
import noop from 'lodash.noop';
import classnames from 'classnames';
import {inject, observer} from 'mobx-react';

import {Button} from "react-bootstrap";
import {ListDropdown} from "../dropdown";
import {RideOctaneLinkContainer as LinkContainer} from '../../pages/url-renderer';
import {VehicleSelectFormStore} from "./vehicle-select-form-store";
import {pushOfferSearch} from "../../utils/gtm-utils";

export const VehicleSelectFormRenderer = inject('apiStore', 'partnerStore', 'historyStore')(
    observer(
        class _VehicleSelectFormRenderer extends React.Component {
            static propTypes = {
                apiStore: PropTypes.shape({
                    fetch: PropTypes.func.isRequired,
                }).isRequired,
                partnerStore: PropTypes.shape({
                    partnerIdentifier: PropTypes.string,
                }).isRequired,
                render: PropTypes.func.isRequired,

                // hooks
                onSubmit: PropTypes.func,
                onChangeMake: PropTypes.func,
                onChangeModel: PropTypes.func,
            };

            static defaultProps = {
                onSubmit: noop,
                onChangeMake: noop,
                onChangeModel: noop,
            };

            store = new VehicleSelectFormStore(this.props);

            componentDidMount() {
                this.store.populateDropdowns();
            }

            componentWillUnmount() {
                this.store.disposeListeners();
            }

            MakeDropdown = observer(props => (
                <ListDropdown
                    {...props}
                    controlId="make"
                    placeholder="Select Make"
                    items={this.store.makes}
                    error={this.store.missingMake}
                    selected={this.store.selectedMake}
                    onChange={this.store.changeMake}
                    disabled={this.store.shouldDisableMakeSelection}
                />
            ));

            ModelDropdown = observer(props => (
                <ListDropdown
                    {...props}
                    controlId="model"
                    placeholder="Select Model"
                    items={this.store.models}
                    error={this.store.missingModel}
                    selected={this.store.selectedModel}
                    onChange={this.store.changeModel}
                    disabled={!this.store.isMakeSelected()}
                />
            ));

            SubmitButton = observer(({to, variant, children}) => (
                <LinkContainer to={to || this.store.submitUrl}>
                    <Button
                        block
                        size="lg"
                        variant={variant}
                        children={children}
                        data-oid="vehicle-select-submit"
                        onClick={(e) => {this.store.submitForm(e); pushOfferSearch(children, to || this.store.submitUrl);}}
                    />
                </LinkContainer>
            ));

            ErrorMessage = observer(props => (
                <p {...props}
                   className={classnames("spark-text-error text-center mt-1", props.className, {'d-none': !this.store.errorMessage})}>
                    {this.store.errorMessage}
                </p>
            ));

            render() {
                return this.props.render({
                    store: this.store,
                    MakeDropdown: this.MakeDropdown,
                    ModelDropdown: this.ModelDropdown,
                    SubmitButton: this.SubmitButton,
                    ErrorMessage: this.ErrorMessage,
                });
            }
        }
    )
);
