import {computed, decorate, action, observable} from "mobx";
import {BaseStore} from "./base-store";


/**
 * This is a dictionary containing the valid modal routes.
 * @type {{general_application_disclosure: string, e_sign_act_disclosure: string, coppa_disclosure: string, patriot_act_disclosure: string, alternate_lenders_disclosure: string}}
 */
export const RIDE_OCTANE_MODAL_ROUTES = {
    e_sign_act_disclosure: 'e_sign_act_disclosure',
    general_application_disclosure: 'general_application_disclosure',
    coppa_disclosure: 'coppa_disclosure',
    patriot_act_disclosure: 'patriot_act_disclosure',
    alternate_lenders_disclosure: 'alternate_lenders_disclosure',
};

/**
 * @class RideOctaneModalStore
 * @inheritDoc
 *
 * Global Store for rendering our modal.
 *
 * Usage:
 *  1. Modal specified by route
 *      a) Launching via a Link
 *          <Link href="/current/path#modal=${specifiedModalRoute}"/>
 *                                            ^ @see RIDE_OCTANE_MODAL_ROUTES
 *
 *  2. Modal specified by config
 *      a) Launching manually using openModal()
 *          rideOctaneModalStore.openModal({
 *              [props]: {object}                   (optional)
 *              ModalHeader: {React.Component}      (optional)
 *              ModalContent: {React.Component}     (required)
 *          });
 *
 * @property {boolean} shouldShowModal
 * @property {object|null} specifiedModalConfig
 * @property {string|null} specifiedModalRoute
 */
export class RideOctaneModalStore extends BaseStore {

    specifiedModalConfig = null;
    isClosing = false;
    preventClose = false; // preventClose does not prevent closing from the store, only from RideOctaneModal

    get specifiedModalRoute() {
        const hashComponents = this.historyStore.hash.split('#modal=');

        // could not find a modal route specified (or something was invalid)
        if (hashComponents.length !== 2) {
            return null;
        }

        // if we find the specified modal route in the routes dictionary, return the route.
        const specifiedModalRoute = hashComponents[1];
        return isValidModalRoute(specifiedModalRoute) ? specifiedModalRoute : null;
    }

    get shouldShowModal() {
        return !!(this.specifiedModalConfig || this.specifiedModalRoute) && !this.isClosing;
    }

    setPreventClose = preventClose => {
        this.preventClose = preventClose;
    };

    /**
     * Launches the modal manually with a config object
     *
     * @param {object} specifiedModalConfig
     * @returns RideOctaneModalStore
     */
    openModal = specifiedModalConfig => {
        if (isValidModalConfig(specifiedModalConfig)) {
            this.historyStore.clearHash();
            this.specifiedModalConfig = specifiedModalConfig;
            this.preventClose = specifiedModalConfig.preventClose;
        }
        return this;
    };

    closeModal = () => {
        this.isClosing = true;
        return this;
    };

    clearModal = () => {
        this.isClosing = false;
        // modal specified by route
        this.historyStore.clearHash();
        // modal specified by config
        this.specifiedModalConfig = null;
        return this;
    };
}

decorate(RideOctaneModalStore, {
    specifiedModalConfig: observable,
    isClosing: observable,
    preventClose: observable,

    specifiedModalRoute: computed,
    shouldShowModal: computed,

    setPreventClose: action,
    openModal: action,
    closeModal: action,
});

function isValidModalRoute(specifiedModalRoute) {
    if (!RIDE_OCTANE_MODAL_ROUTES[specifiedModalRoute]) {
        console.error(`Invalid specifiedModalRoute="${specifiedModalRoute}"`);
        return false;
    }
    return true;
}

function isValidModalConfig(specifiedModalConfig) {
    const {props, ModalHeader, ModalContent} = specifiedModalConfig || {};
    const isPropsValid = ['object', 'undefined'].includes(typeof props);
    const isModalHeaderValid = (!ModalHeader || typeof ModalHeader === 'function');
    const isModalContentValid = (typeof ModalContent === 'function');

    if (!specifiedModalConfig) {
        console.error("Invalid specifiedModalConfig=" + specifiedModalConfig);
        return false;
    }
    if (!(isPropsValid && isModalHeaderValid && isModalContentValid)) {
        console.error(
            "Invalid specifiedModalConfig=" +
            `{props: ${props}, ModalHeader: ${ModalHeader}, ModalContent: ${ModalContent}}`
        );
        return false;
    }
    return true;
}
