import React from "react";

export const documentUploaded = (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.9881 11.9971L57.754 11.8542C56.5687 11.1703 55.1685 10.9483 53.8244 11.2376L18.5113 18.8458L18.2445 18.9102C16.922 19.2646 15.774 20.0982 15.0278 21.2541C14.2319 22.4872 13.9585 23.9858 14.2676 25.4204L16.5464 35.9997H18.5924L16.2227 24.9992L16.1811 24.7694C16.0568 23.9248 16.2423 23.0605 16.7081 22.3388C17.2164 21.5515 18.0166 20.9983 18.9325 20.801L53.1144 13.4367L55.9372 26.5358C56.1942 27.7284 56.9144 28.7701 57.9394 29.4317C58.9644 30.0934 60.2103 30.3207 61.4029 30.0638L74.9254 27.1497L78.1244 41.9997H80.1704L76.7287 26.022L76.6642 25.7553C76.3099 24.4335 75.4769 23.2864 74.322 22.5404L57.9881 11.9971ZM57.8924 26.1146L55.0929 13.1152C55.7313 13.1341 56.3583 13.3259 56.904 13.6778L73.2371 24.2206L73.4396 24.3616C73.7678 24.6077 74.0485 24.9075 74.2712 25.2459L60.9817 28.1086C60.3076 28.2539 59.6034 28.1254 59.024 27.7514C58.4447 27.3774 58.0376 26.7886 57.8924 26.1146Z"
        />
        <path
            d="M83.1816 76.1516C83.5721 76.5421 84.2053 76.5421 84.5958 76.1516C84.9863 75.761 84.9863 75.1279 84.5958 74.7373L80.1513 70.2929C79.7608 69.9024 79.1276 69.9024 78.7371 70.2929L74.2927 74.7373C73.9022 75.1279 73.9022 75.761 74.2927 76.1516C74.6832 76.5421 75.3164 76.5421 75.7069 76.1516L78.444 73.4145V87C78.444 87.5523 78.8917 88 79.444 88C79.9963 88 80.444 87.5523 80.444 87V73.414L83.1816 76.1516Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.6672 35.8747C68.2071 35.7584 68.5505 35.2264 68.4341 34.6865C68.3178 34.1466 67.7859 33.8032 67.246 33.9196L38.438 40.1256L36.4992 37.7991C35.5493 36.6591 34.142 36 32.6581 36H15C12.2386 36 10 38.2386 10 41V80.3333C10 83.0948 12.2386 85.3333 15 85.3333H62.1458C64.7085 92.1498 71.2878 97 79 97C88.9411 97 97 88.9411 97 79C97 73.2013 94.2581 68.0431 90 64.7511V47C90 44.2386 87.7614 42 85 42H42.3419C41.7533 42 41.1767 41.8963 40.6359 41.7L67.6672 35.8747ZM88 47V63.4081C85.3524 61.8765 82.2786 61 79 61C69.0589 61 61 69.0589 61 79C61 80.4939 61.182 81.9454 61.525 83.3333H15C13.3431 83.3333 12 81.9902 12 80.3333V41C12 39.3431 13.3431 38 15 38H32.6581C33.5485 38 34.3928 38.3955 34.9628 39.0794L36.9643 41.4813C38.2943 43.0772 40.2644 44 42.3419 44H85C86.6569 44 88 45.3431 88 47ZM95 79C95 87.8366 87.8366 95 79 95C70.1634 95 63 87.8366 63 79C63 70.1634 70.1634 63 79 63C87.8366 63 95 70.1634 95 79Z"
        />
        <path
            d="M40.0688 26.4766C40.6087 26.3603 40.9521 25.8283 40.8358 25.2884C40.7194 24.7485 40.1875 24.4052 39.6476 24.5215L24.984 27.6807C24.4441 27.7971 24.1008 28.329 24.2171 28.8689C24.3334 29.4088 24.8654 29.7522 25.4053 29.6359L40.0688 26.4766Z"
        />
    </svg>
);
