import {withOrdinalSuffix} from "./number-utils";


export const YEARS_NUMBERED = _range(1900, new Date().getFullYear());
export const DATES_NUMBERED = _range(1, 31);
export const MONTHS_ABBREVIATED = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

/**
 * Helper function that returns an array of integers
 */
function _range(start, end) {
    const arr = [];
    for (let i = start; i <= end; i++) {
        arr.push(i);
    }
    return arr;
}

/**
 * Takes a utc formatted date string and returns a presentable date (in the local time zone).
 *  (e.g.
 *      getDateString("2001-01-01T05:00:00.0Z")     ->  'Jan. 1st, 2001' (in New York)
 *  )
 *
 * @param {string} utcDateStr
 * @returns {string}
 */
export function getDateString(utcDateStr) {
    const date = new Date(utcDateStr);
    if (isNaN(date)) {
        return '';
    }
    return [
        `${MONTHS_ABBREVIATED[date.getMonth()]}.`,
        `${withOrdinalSuffix(date.getDate())},`,
        `${date.getFullYear()}`,
    ].join(' ');
}

/**
 * Returns true if the given year is a leap year
 *
 * @param {string|int} year
 * @returns {boolean}
 */
export function isLeapYear(year) {
    return (parseInt(year) % 4) === 0;
}

/**
 * Helper function to get the current year.
 */
export function getCurrentYear() {
    return new Date().getFullYear();
}

/**
 * Returns the number of days in the given year's month
 *
 * @param {string|int} month - month number
 * @param {string|int} year
 * @returns {number}
 */
export function getNumberOfDaysInMonth(month, year) {
    switch (parseInt(month)) {
        // February
        case 2:
            if (isLeapYear(year)) {
                return 29;
            }
            return 28;
        
        // April, June, September, November
        case 4:
        case 6:
        case 9:
        case 11:
            return 30;

        default:
            return 31;
    }
}

/**
 * Returns the numeric month for the given month
 *
 * @param {string} month - month from `MONTHS_ABBREVIATED`
 * @returns {number|null}
 */
export function getNumericMonth(month) {
    const numericMonth = MONTHS_ABBREVIATED.indexOf(month);
    if (numericMonth === -1) {
        return null;
    }
    return numericMonth + 1;
}

/**
 * Returns an object that has the years & months elapsed from the specified date until now.
 * 
 * @param {Date} date - JS date object to compare with Date.now()
 * @returns {object}
 * e.g.
 * {
 *      'years': {number},
 *      'months': {number}
 *      'totalMonths': {number}
 * }
 */
export function getTimeSinceDate(date) {
    const today = new Date(Date.now());
    const totalMonths = (today.getFullYear() - date.getFullYear()) * 12 + (today.getMonth() - date.getMonth());

    const yearsElapsed = Math.floor(totalMonths/12);
    const monthsElapsed = totalMonths % 12;
    return {years: yearsElapsed, months: monthsElapsed, totalMonths};
}

/**
 * Returns an object that has the years & months elapsed from the specified date until now.
 * 
 * @param {object} date - Date Object containing {year, month, day}
 * @returns {string}
 * e.g.
 * input
 * {
 *      year' 2022,
 *      month: 09,
 *      day: 15
 * }
 * returns:
 * {
 *      'years': {number},
 *      'months': {number},
 *      'totalMonths': {number}
 * }
 */
 export const getTimeSinceDateObject = (dateObject) =>{
    const date = dateObject ? new Date(parseInt(dateObject.year), parseInt(dateObject.month)) : new Date();
    return getTimeSinceDate(date);
}

export const DateInputType = {
    FULL_DATE: "MM / DD / YYYY",
    MONTH_AND_YEAR: "MM / YYYY"
}

/**
 * Returns a date string from a date object
 * 
 * @param {object} date - Date Object containing {year, month, day}
 * @param {string} separator - Date separator. Defaults to "-"
 * @returns {string}
 * e.g.
 * input
 * {
 *      year' 2022,
 *      month: 09,
 *      day: 15
 * }
 * returns:
 * '2022-09-15'
 */
export const getDateStringFromObject = (date, separator="-") =>
    date ? [`${date.year}`, `${date.month}`.padStart(2, 0), `${date.day}`.padStart(2, 0)].join(separator) : "";