/** 
 * Checks if the window global variable is defined
 * 
 * Warning !!!
 * Using this function to conditionally render components may break SSR.
 * For function-based components, use the useIsServerRender hook instead
 * @returns boolean
*/
export function isWindowDefined() {
    return (typeof window !== 'undefined');
}

export function findMatchInArray(searchString, array){
	for (const match of array){
		if (searchString.includes(match)) return match;
	}
	return null;
}
