import {decorate, computed} from 'mobx';
import {BaseStore} from "./base-store";


export class ThemeStore extends BaseStore {
    // themes
    static THEME_DEFAULT = null;
    static THEME_BLACK = Object.freeze({
        key: "black",
        md5_hash: "3a49337c5724d18079602a4ca5a91626",
        color: "#111111"
    });
    static THEME_POLARIS_BLUE = Object.freeze({
        key: "polaris_blue",
        md5_hash: "8a6c53818dc819ec971b6031d49e9373",
        color: "#004e97"
    });
    static THEME_SLINGSHOT_BLACK = Object.freeze({
        key: "slingshot_black",
        md5_hash: "c9d8f0a1ac49790e7e87d9e3fee85877",
        color: "#000000"
    });
    static THEME_INDIAN_RED = Object.freeze({
        key: "indian_red",
        md5_hash: "8e150fc012f9d2eb7b61e16abca9462a",
        color: "#862633"
    });
    static THEME_CAMPING_WORLD_BLUE = Object.freeze({
        key: "camping_world_blue",
        md5_hash: "70ef967be58c3d1c5981f43a42c09037",
        color: "#00669E"
    });

    /**
     * Mapping of all accepted theme hashes to our themes
     */
    static THEME_HASH_APP_THEME_MAPPING = Object.freeze({
        [ThemeStore.THEME_BLACK.md5_hash]: ThemeStore.THEME_BLACK,
        [ThemeStore.THEME_POLARIS_BLUE.md5_hash]: ThemeStore.THEME_POLARIS_BLUE, // reveo
        [ThemeStore.THEME_SLINGSHOT_BLACK.md5_hash]: ThemeStore.THEME_SLINGSHOT_BLACK, // slingshot
        [ThemeStore.THEME_INDIAN_RED.md5_hash]: ThemeStore.THEME_INDIAN_RED, // indian motorcycles
        [ThemeStore.THEME_CAMPING_WORLD_BLUE.md5_hash]: ThemeStore.THEME_CAMPING_WORLD_BLUE, // camping world (RV.com)

        // Note: deprecated theme hashes (still used by some experiences, but should not be reused)
        "c21f969b5f03d33d43e04f8f136e7682": ThemeStore.THEME_DEFAULT,  // default
        "6f81a9dd183438cecec18c0dc1aa2861": ThemeStore.THEME_DEFAULT,  // brp
        "1b5d3e832d157714b8fe8d2cd64ed3e7": ThemeStore.THEME_BLACK, // cycle world
    });

    /**
     * The theme object applied to the experience
     */
    get theme() {
        // pull hash from url appTheme query param
        const themeHash = this.historyStore.queryParams.appTheme;

        // find appropriate theme from mapping object
        return this.constructor.THEME_HASH_APP_THEME_MAPPING[themeHash] || null;
    }

    /**
     * The class applied to the html tag
     */
    get htmlClass() {
        if (this.theme) {
            return `html_${this.theme.md5_hash}`;
        }
        return "";
    }
    /**
     * Used to determine if reveo marketplace in the ReveoCTAFooter
     */
    get isReveoMarketplace() {
        return !!this.theme && this.theme.key === 'polaris_blue';
    }

}

decorate(ThemeStore, {
    theme: computed,
    htmlClass: computed,
    isReveoMarketplace: computed,
});
