import React from 'react';
import {observer, inject} from "mobx-react";
import {Container, Row, Col} from "react-bootstrap";
import {VehicleSelectForm} from "../components/vehicle-select-form";
import {Svg} from "../components/svg"

import copy from './copy.json';
import styles from "./homepage.module.scss";
import classnames from "classnames";
import {PageTitles, withRideOctaneTitle} from "../pages/octane-title";
import {Helmet} from "react-helmet";
import {
    OG_TITLE, OG_DESC, OG_IMAGE, OG_URL, OpenGraphMetaTags
} from "../components/open-graph";
import {pushPageView} from "../utils/gtm-utils";


export function createOgMetaData(homepageCopy, baseUrl) {
    const ogMetaData = {};

    ogMetaData[OG_TITLE] = homepageCopy.header_section.title;
    ogMetaData[OG_DESC] = homepageCopy.header_section.subtitle;
    ogMetaData[OG_IMAGE] = `${baseUrl}/images/Octane-logo-og.png`;
    ogMetaData[OG_URL] = `${baseUrl}/`;

    return ogMetaData;
}

export const Homepage = inject('rideOctaneStore')(observer(({rideOctaneStore}) => {
    const homepageComponent = (
        <div data-oid="homepage-page">
            <Helmet>
                <meta name="description" content={copy.meta_description}/>
            </Helmet>
            <OpenGraphMetaTags ogMetaData={
                createOgMetaData(copy, rideOctaneStore.settings.BASE_UX_URL)
            }/>
            <HeaderSection/>
            <HowItWorksSection/>
            <ValueProps/>
        </div>
    );

    pushPageView('homepage');

    return withRideOctaneTitle(homepageComponent,[PageTitles.HOMEPAGE_TITLE, PageTitles.OCTANE_TITLE],' | ');
}));

function HeaderSection() {
    const _copy = copy.header_section;

    return (
        <div className="spark-bg-primary">
            <Container className="py-5">
                <Row className="w-100 mx-0">
                    <Col xs={12} lg={7} style={{marginTop: "25px", marginBottom: "30px"}}>
                        <h1 className="spark-text-white font-weight-bold text-center text-lg-left">
                            {_copy.title}
                        </h1>
                        <p className={`d-none d-lg-block ${styles.subtitle}`}>
                            {_copy.subtitle}
                        </p>
                    </Col>
                    <Col xs={12} lg={5}>
                        <div className="spark-bg-primary-dark rounded p-3 w-350px mw-100 mx-auto mx-lg-0 float-none float-lg-right">
                            <p className="spark-text-white text-center mb-3">
                                {_copy.form_instructions}
                            </p>
                            <VehicleSelectForm/>
                        </div>
                    </Col>
                </Row>
                <p className="text-center spark-text-white-50 mt-5 pt-5 d-none d-lg-block">
                    {_copy.features.map((feature, i) => (
                        <span key={i} className={styles.feature}>{feature}<br /></span>
                    ))}
                </p>
            </Container>
        </div>
    );
}

function HowItWorksSection() {
    const _copy = copy.how_it_works_section;

    const HowItWorksIcon = ({step}) => {
        return (
            <div className={styles.howItWorksIconContainer}>
                {/*<img className={'d-inline-block d-lg-none mx-auto'} src={step.icon.mobile} alt={step.title}/>*/}
                {/*<img className={'d-none d-lg-block mx-auto'} src={step.icon.desktop} alt={step.title}/>*/}
                <Svg className={'d-inline-block d-lg-none mx-auto'}  svg={step.icon.mobile}/>
                <Svg className={'d-none d-lg-block mx-auto'} svg={step.icon.desktop}/>
            </div>
        );
    };
    const HowItWorksText = ({step}) => (
        <div className={styles.howItWorksTextContainer}>
            <p className={styles.howItWorksStepTitle}>{step.title}</p>
            <p className={styles.howItWorksStepDescription}>{step.description}</p>
        </div>
    );
    const HowItWorksStep = ({step}) => (
        <div className={`${styles.howItWorksStep} mb-3`}>
            <HowItWorksIcon step={step}/>
            <HowItWorksText step={step}/>
        </div>
    );
    const CurvedArrow = ({flipped}) => (
        <Svg className={classnames((flipped ? "" : styles.flippedY), "spark-text-primary", styles.curvedArrow)} svg="curved-arrow" style={{height: 14}}/>
    );
    return (
        <Container className="px-4">
            <div className="py-5">
                <h4 className="text-center mb-3">
                    {_copy.title}
                </h4>
                <div className={`${styles.howItWorksSteps} mb-5 align-items-center`}>
                    <HowItWorksStep step={_copy.steps.select_vehicle}/>
                    <CurvedArrow flipped={true}/>
                    <HowItWorksStep step={_copy.steps.instant_decision}/>
                    <CurvedArrow/>
                    <HowItWorksStep step={_copy.steps.sign_and_drive}/>
                </div>
            </div>
        </Container>
    );
}

function ValueProps() {

    const _copy = copy.valueProps;

    return (
        <div>

            {/*First section - Financing Options */}

            <div className="container-fluid py-5 spark-bg-light">
                <section className="px-md-5 mx-md-5 dark-grey-text text-center text-lg-left">
                    <div className="row justify-content-center flex-column-reverse flex-md-row">
                        <div className="col-lg-4 my-auto">
                            <h2 className="font-weight-bold pt-2">{_copy.financing_options.subtitle}</h2>
                            <h5 className="font-weight-normal spark-text-secondary pt-2">{_copy.financing_options.body} </h5>
                        </div>
                        <div className="col-lg-4 mb-4 mb-lg-10 d-flex align-items-center justify-content-center">
                            <img src={_copy.financing_options.image} className="img-fluid" alt={_copy.financing_options.image_alt}/>
                        </div>
                    </div>
                </section>
            </div>

            {/*Second section - Seamless Buying Experience*/}

            <div className="container-fluid py-5">
                <section className="px-md-5 mx-md-5 dark-grey-text text-center text-lg-left">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 mb-4 mb-lg-0 d-flex align-items-center justify-content-center">
                            <img src={_copy.seamless_buying_experience.image} className="img-fluid" alt={_copy.seamless_buying_experience.image_alt}/>
                        </div>
                        <div className="col-lg-4 my-auto pl-5">
                            <h2 className="font-weight-bold pt-2">{_copy.seamless_buying_experience.subtitle}</h2>
                            <h5 className="font-weight-normal spark-text-secondary pt-2">{_copy.seamless_buying_experience.body}</h5>
                        </div>

                    </div>
                </section>
            </div>

            {/*Third section - Lightning-Fast Decisions*/}

            <div className="container-fluid py-5 spark-bg-light">
                <section className="px-md-5 mx-md-5 dark-grey-text text-center text-lg-left">
                    <div className="row justify-content-center flex-column-reverse flex-md-row">
                        <div className="col-lg-4 my-auto">
                            <h2 className="font-weight-bold pt-2">{_copy.lightning_fast_decisions.subtitle}</h2>
                            <h5 className="font-weight-normal spark-text-secondary pt-2">{_copy.lightning_fast_decisions.body} </h5>
                        </div>
                        <div className="col-lg-4 mb-4 mb-lg-0 d-flex align-items-center justify-content-center">
                            <img src={_copy.lightning_fast_decisions.image} className="img-fluid" alt={_copy.lightning_fast_decisions.image_alt}/>
                        </div>
                    </div>
                </section>
            </div>

            {/*End of sections*/}
        </div>
        // End of value props

);
}
