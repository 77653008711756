import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import template from 'lodash.template';
import {formatPrice} from "../../../utils/number-utils";
import {VehicleImg} from "../../vehicle-img";

import {Row, Col, Button} from 'react-bootstrap';
import {RideOctaneLinkContainer as LinkContainer} from '../../../pages/url-renderer';

import copy from './copy.json';
import styles from './vehicle-model-tile.module.scss';


export function VehicleModelTile(props) {
    return (
        <LinkContainer to={template(copy.view_button.href)(props)} className={classnames(styles.tile)}>
            <div className={classnames(styles.tileWrap, "mb-4")}>
                <div className={classnames("shadow-sm border rounded position-relative")}>
                    {/* Image */}
                    <div className={classnames(styles.imageWrap, "pl-4")}>
                        <VehicleImg hideBroken className="img-fluid d-block" src={props.default_image} alt={template(copy.title)(props)}/>
                    </div>

                    <div className={classnames(styles.contentWrap)}>
                        <div className="mt-2 pl-4 pr-4 pb-4">
                            {/* Title */}
                            <p className="font-weight-bold">
                                {template(copy.title)(props)}
                            </p>

                            {/* Description */}
                            <p className="mb-3">
                                {!!props.description && (
                                    template(copy.description)(props)
                                )}
                            </p>

                            {/* Action Button */}
                            <Row>
                                <Col xs={4}>
                                {/* Price */}
                                {!!props.msrp && (
                                    <div>
                                        <small className="d-block">
                                            {copy.starting_at}
                                        </small>
                                        <strong>
                                            {template(copy.msrp, {imports: {formatPrice}})(props)}
                                        </strong>
                                    </div>
                                )}
                                </Col>

                                <Col xs={props.msrp ? 8 : 12}>
                                    <LinkContainer to={template(copy.view_button.href)(props)}>
                                        <Button className="text-nowrap" variant="primary" block>
                                            {copy.view_button.label}
                                        </Button>
                                    </LinkContainer>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </LinkContainer>
    );
}

VehicleModelTile.propTypes = {
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    default_image: PropTypes.string.isRequired,
    vehicle_make: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    msrp: PropTypes.number,
};
