import React from "react";

export const connectionLost = (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M56.4352 65.7071C56.0447 65.3166 55.4115 65.3166 55.021 65.7071L50.0712 70.6569L45.1214 65.7071C44.7309 65.3166 44.0977 65.3166 43.7072 65.7071C43.3167 66.0976 43.3167 66.7308 43.7072 67.1213L48.657 72.0711L43.7073 77.0208C43.3168 77.4113 43.3168 78.0445 43.7073 78.435C44.0978 78.8256 44.731 78.8256 45.1215 78.435L50.0712 73.4853L55.0209 78.435C55.4114 78.8256 56.0446 78.8256 56.4351 78.435C56.8256 78.0445 56.8256 77.4113 56.4351 77.0208L51.4854 72.0711L56.4352 67.1213C56.8258 66.7308 56.8258 66.0976 56.4352 65.7071Z"/>
        <path
            fillRule="evenodd"
            d="M82.7465 41.1477C89.3268 43.6629 94 50.0357 94 57.5C94 67.165 86.165 75 76.5 75H64.6999C63.3101 81.8467 57.2569 87 50 87C42.7431 87 36.6899 81.8467 35.3001 75H23.5C13.835 75 6 67.165 6 57.5C6 49.3828 11.5264 42.5565 19.022 40.5782C19.2015 38.8649 20.4626 37.4736 22.1102 37.0993C22.3963 37.0343 22.6942 37 23 37H24.0259C24.0087 36.6688 24 36.3354 24 36C24 35.6646 24.0087 35.3312 24.0259 35C24.5459 24.9714 32.842 17 43 17H47C54.6423 17 61.2307 21.512 64.2468 28.0174C64.4964 28.0059 64.7475 28 65 28H67C74.863 28 81.4013 33.672 82.7465 41.1477ZM35.0328 73H23.5C14.9396 73 8 66.0604 8 57.5C8 50.3133 12.8929 44.2643 19.5324 42.512L20.1998 42.3358C20.6015 42.2298 20.8958 41.8864 20.9391 41.4732L21.0111 40.7867C21.1162 39.7834 21.9679 39 23 39H24.0259C25.1711 39 26.0825 38.0401 26.0232 36.8964C26.0078 36.5997 26 36.3009 26 36C26 26.6112 33.6112 19 43 19H47C53.5962 19 59.3188 22.7568 62.1379 28.2553C55.6848 29.4206 50.5776 34.4558 49.3058 40.8712C49.1877 41.4665 49.6597 42 50.2666 42C50.7688 42 51.1907 41.6293 51.2929 41.1376C52.5761 34.961 57.929 30.2756 64.4215 30.0117C64.6133 30.0039 64.8061 30 65 30H67C74.0529 30 79.8878 35.2154 80.8582 42H80.8678L80.978 42.6129L82.0324 43.0159C87.864 45.2449 92 50.8919 92 57.5C92 66.0604 85.0604 73 76.5 73H64.9672C64.9889 72.6695 65 72.336 65 72C65 63.7157 58.2843 57 50 57C41.7157 57 35 63.7157 35 72C35 72.336 35.011 72.6695 35.0328 73ZM50 85C57.1797 85 63 79.1797 63 72C63 64.8203 57.1797 59 50 59C42.8203 59 37 64.8203 37 72C37 79.1797 42.8203 85 50 85Z"/>
    </svg>
);
