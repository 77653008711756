import React from 'react';
import {Route} from 'react-router-dom';
import {inject, observer} from 'mobx-react';

import {VehicleListing} from "./vehicle-listing";
import {VehicleListingPlaceholder} from "./vehicle-listing-placeholder";
import {VehicleMake} from "./vehicle-make";
import {ReturnToServer} from "../components/return-to-server";
import {withRideOctaneTitle} from "../pages/octane-title";
import {PageNotFound} from "../components/interstitials";
import {pushPageView} from "../utils/gtm-utils";


/**
 * Top Level Component for Listing Pages
 *  (e.g. pages with slug matching a
 *      - vehicle make
 *      - vehicle model
 *      - vehicle type
 *      - vehicle sub model
 *      - vehicle
 *  )
 *
 * @param {ListingsStore} listingsStore - global store that handles logic determining which view to render
 *  {
 *      pageType: <string>      (@see ListingsStore.PAGE_TYPES)
 *      pageStore: <object>
 *  }
 *  @param {object} match - react router match object that contains information about how a <Route path> matched the URL
 *  {
 *      url: {string}
 *  }
 *  @param {object} history - history object (from "history" package)
 *  {
 *      location: {
 *          pathname: {string}
 *      }
 *  }
 */
export const Listings = inject('listingsStore')(observer(
    function _Listings({listingsStore, match, history}) {
        const {pageStore, pageType} = listingsStore;
        const {PAGE_TYPES} = listingsStore.constructor;

        // check if data is still being fetched or the url is still updating
        const isUrlUpdating = match && history && (match.url !== history.location.pathname);
        if (listingsStore.isLoading || isUrlUpdating) {
            return <VehicleListingPlaceholder/>;
        }

        //* GA4 datalayer for pageview*//
        pushPageView(pageType);
        //* end of GA4 datalayer *//

        // determine which page to render based on the pageType
        switch(pageType) {
            case PAGE_TYPES.MODEL:
            case PAGE_TYPES.SUBMODEL:
                const pageTitleWords = pageStore.pageTitleWords;
                if(this.props.match && this.props.match.params.year){
                    const liveYears = Array.from(pageStore.getPrimaryVehicleObject().live_years);
                    const year = parseInt(this.props.match.params.year);
                    if (liveYears.includes(year)) {
                        return withRideOctaneTitle(
                             <VehicleListing
                                pageStore={pageStore}
                                isVehicleModelPage={pageType === PAGE_TYPES.MODEL}
                                isVehicleSubmodelPage={pageType === PAGE_TYPES.SUBMODEL}
                            />, pageTitleWords, ' | '
                        );
                    }
                    else{
                        return <Route path="/" component={ReturnToServer}/>;
                    }
                }
                else{
                    return withRideOctaneTitle(
                        <VehicleListing
                            pageStore={pageStore}
                            isVehicleModelPage={pageType === PAGE_TYPES.MODEL}
                            isVehicleSubmodelPage={pageType === PAGE_TYPES.SUBMODEL}
                        />, pageTitleWords, ' | '
                );
                }
            case PAGE_TYPES.MAKE:
                return withRideOctaneTitle(
                    <VehicleMake pageStore={pageStore}/>, pageStore.pageTitleWords, ' | '
                );
            default:
                return <Route path="/" component={ReturnToServer}/>;
        }
    }
));
