import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from 'prop-types';
import {CaptchaDisclosure} from "./captcha-disclosure";


export function Captcha(props) {
    return (
        <React.Fragment>
            {!props.hideDisclosure && (
                <CaptchaDisclosure/>
            )}
            <div className="d-none">
                <ReCAPTCHA
                    ref={props.captchaRef}
                    size="invisible"
                    onChange={props.onClickContinue}
                    // to avoid call the onChange with null to signify expired callback.
                    onExpired={()=>{}}
                    sitekey="6Lf01QMhAAAAAPJjEgLjCAW-4b0ZQmaQ3G_aG3w6"
                />
            </div>
        </React.Fragment>
    );
}

Captcha.propTypes = {
    onClickContinue: PropTypes.func.isRequired,
    captchaRef: PropTypes.object.isRequired,
    hideDisclosure: PropTypes.bool,
};