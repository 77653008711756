import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ReactSlider from "react-slider";

import { formatPriceInput } from "../../utils/number-utils";
import { Input } from "../input";

import styles from "./sliderRange.module.scss";

export const SliderRange = ({
    id,
    label,
    step,
    value,
    minValue,
    maxValue,
    disabled,
    formatValueLabel,
    withPriceField,
    onChange,
}) => {
    const [rawValue, setRawValue] = useState(value);
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setRawValue(value);
        setInputValue(value);
    }, [value]);

    const getThumbClasses = classnames(
        styles.thumb,
        "spark-shadow-element",
        "spark-text-karla",
        { [styles.thumbBasic]: withPriceField },
        { [styles.disabled]: disabled }
    );

    const getTrackClasses = (index) => {
        return classnames(
            styles.track,
            { [styles.bgSelected]: index === 0 },
            { [styles.bgArea]: index === 1 },
            { [styles.disabled]: disabled }
        );
    };

    const getValueLabel = (valueNow) =>
        formatValueLabel ? formatValueLabel(valueNow) : valueNow;

    const renderThumb = (props, state) => {
        const thumbContent = withPriceField
            ? "|||"
            : getValueLabel(state.valueNow);
        return <div {...props}>{thumbContent}</div>;
    };

    const renderTrack = (props, state) => {
        return <div {...props} className={getTrackClasses(state.index)} />;
    };

    const handleChangeTrack = (value) => triggerChangeValue(value);

    const handleChangeInput = ({ value }) => setInputValue(value);

    const handleBlurInput = ({ value }) => {
        let newValue = parseInt(value) || 0;
        if (newValue < minValue) newValue = minValue;
        if (newValue > maxValue) newValue = maxValue;

        triggerChangeValue(newValue);
    };

    const triggerChangeValue = (newValue) => {
        setRawValue(newValue);
        setInputValue(newValue);
        onChange && onChange(newValue);
    };

    return (
        <div>
            <div className={styles.top}>
                <label id={id} className={styles.label}>
                    {label}
                </label>
                {withPriceField && (
                    <div className={styles.priceField}>
                        <Input
                            id="slider-price-field"
                            value={inputValue.toString()}
                            disabled={disabled}
                            formatter={formatPriceInput}
                            onChange={handleChangeInput}
                            onBlur={handleBlurInput}
                            unformatter={(val) => val.replace(/\D/g, "")}
                            autoValidateAndFormat
                        />
                    </div>
                )}
            </div>

            <ReactSlider
                value={rawValue}
                defaultValue={rawValue}
                ariaLabelledby={id}
                disabled={disabled}
                min={minValue}
                max={maxValue}
                className={styles.slider}
                step={step || 10}
                thumbClassName={getThumbClasses}
                trackClassName={styles.track}
                renderTrack={renderTrack}
                renderThumb={renderThumb}
                onChange={(value) => handleChangeTrack(value)}
            />
        </div>
    );
};

SliderRange.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    step: PropTypes.number,
    value: PropTypes.number,
    minValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    formatValueLabel: PropTypes.func,
    withPriceField: PropTypes.bool,

    // handlers
    onChange: PropTypes.func,
};
