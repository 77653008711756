import {decorate, observable, computed, action} from 'mobx';
import {getSortedVehicleData} from "../../utils/vehicle-data-logic";


export class TrimAndColorPickerFormStore {
    apiStore = null;

    vehicle_configurations = null;

    selectedTrim = null;
    selectedColor = null;

    /**
     * Transforms vehicle configurations into an array of trims with an array of colors (one per configuration)
     *
     * (e.g.
     *      vehicle_configurations = [
     *          {color: <color-0>, vehicle_trim: {uuid: 0}},
     *          {color: <color-1>, vehicle_trim: {uuid: 0}},
     *          {color: <color-2>, vehicle_trim: {uuid: 1}},
     *          {color: <color-3>, vehicle_trim: {uuid: 1}},
     *      ]
     *
     *      trims = [
     *          {uuid: 0, colors: [<color-0>, <color-1>]}
     *          {uuid: 1, colors: [<color-2>, <color-3>]}
     *      ]
     */
    get trims() {
        const sortedConfigArray = !!this.vehicle_configurations ? this.vehicle_configurations.getObjects() : null;
        if (!!sortedConfigArray) {
            const sortedVehicleData = getSortedVehicleData({vehicleConfigurations: sortedConfigArray});
            return sortedVehicleData.vehicleTrims.getObjects();
        }
        return [];
    }

    get colors() {
        if (this.selectedTrim) {
            return this.selectedTrim.colors.slice().sort((a,b) => a.msrp - b.msrp);
        }

        // if there is no trim selected, show all colors
        return this.trims.reduce((colors, trim) => {
            colors.push(...trim.colors);
            return colors;
        }, []);
    }

    get selectedColorLabel() {
        if (!this.selectedTrim) {
            return 'No Trim Selected';
        }
        if (!this.selectedColor) {
            return 'No Color Selected';
        }
        return this.selectedColor.name;
    }

    constructor({apiStore}) {
        this.apiStore = apiStore;

        this.updateOptions = this.updateOptions.bind(this);
        this.updateSelectedTrim = this.updateSelectedTrim.bind(this);
        this.updateSelectedColor = this.updateSelectedColor.bind(this);
    }

    populateOptions({vehicle_sub_model, vehicle_configuration} = {}) {
        const query = {fields: ['uuid', 'slug', 'vehicle_trim', 'color', 'msrp']};

        if (vehicle_sub_model) {
            query.vehicle_sub_models = [vehicle_sub_model.uuid];
        }

        return this.apiStore.fetch('configurations', {query})
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.warn(`Failed to fetch configurations (status=${status})`);
                    return this;
                }

                return this.updateOptions({
                    vehicle_configuration,
                    vehicle_configurations: response.results,
                });
            })
            .catch(() => {
                console.warn('Failed to fetch configurations (status=500)');
                return this;
            });
    }

    updateOptions({vehicle_configurations, vehicle_configuration}) {
        const sortedVehicleData = getSortedVehicleData({vehicleConfigurations: vehicle_configurations});
        this.vehicle_configurations = sortedVehicleData.vehicleConfigurations;

        // getSortedVehicleData provides some additional data from other levels of the taxonomy
        const configUUID = !!vehicle_configuration && !!vehicle_configuration.uuid ?
            vehicle_configuration.uuid : null;
        const processedConfig = !!configUUID ? sortedVehicleData.vehicleConfigurations.getObject(configUUID) : null;
        vehicle_configuration = processedConfig || vehicle_configuration;
        if (vehicle_configuration) {
            return this.updateSelectedColor(vehicle_configuration);
        }
        return this;
    }

    updateSelectedTrim(selectedTrim) {
        selectedTrim = this.findMatchingTrim(selectedTrim);

        if (selectedTrim && !this.isSelectedTrim(selectedTrim)) {
            this.selectedTrim = selectedTrim;
            if (this.selectedColor && !this.isSelectedTrim(this.selectedColor.trim)) {
                this.updateSelectedColor(selectedTrim.colors[0]);
            }
        }
        return this;
    }

    updateSelectedColor(selectedColor) {
        selectedColor = this.findMatchingColor(selectedColor);

        if (selectedColor && !this.isSelectedColor(selectedColor)) {
            this.selectedColor = selectedColor;
            if (selectedColor && !this.isSelectedTrim(selectedColor.trim)) {
                this.selectedTrim = this.findMatchingTrim(selectedColor.trim);
            }
        }
        return this;
    }

    /**
     * Helper - determines if two objects are equal based on whether their slugs are the same
     *
     * @param {object|null} objA
     * @param {object|null} objB
     * @returns {boolean}
     */
    _equal(objA, objB) {
        return (!objA && !objB) || (!!objA && !!objB && (objA.uuid === objB.uuid));
    }

    /**
     * Helper - returns the full object in a list with a matching uuid
     *
     * @param {object|null} obj
     * @param {array} list
     * @returns {object|null}
     */
    _findMatching(obj, list) {
        if (!obj) {
            return null;
        }
        return list.find(_obj => this._equal(_obj, obj)) || null;
    }

    isSelectedTrim = vehicle_trim => this._equal(vehicle_trim, this.selectedTrim);
    findMatchingTrim = vehicle_trim => this._findMatching(vehicle_trim, this.trims);

    isSelectedColor = color => this._equal(color, this.selectedColor);
    findMatchingColor = color => this._findMatching(color, this.colors);
}

decorate(TrimAndColorPickerFormStore, {
    vehicle_configurations: observable,
    selectedTrim: observable,
    selectedColor: observable,

    trims: computed,
    colors: computed,
    selectedColorLabel: computed,

    updateOptions: action,
    updateSelectedTrim: action,
    updateSelectedColor: action,
});

