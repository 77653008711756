import React from "react";
import {Helmet} from 'react-helmet';
import {observer, inject} from "mobx-react";
import {withRouter} from "react-router";
import {Route, Switch} from 'react-router-dom';
import get from 'lodash.get';
import {renderUrl} from './url-renderer';

// Stores
import {RideOctaneStore} from "../stores/ride-octane-store";
import {ThemeStore} from "../stores/theme-store";
import {UXCopyStore} from "../stores/ux-copy-store";
import {HistoryStore} from "../stores/history-store";
import {DealershipStore} from "../stores/dealership-store";
import {PartnerStore} from "../stores/partner-store";
import {RideOctaneModalStore} from "../stores/ride-octane-modal-store";
import {VehicleSelfSelectionStore} from "../prequal/vehicle-self-selection/store/vehicle-self-selection-store";
import {WaffleStore} from "../stores/waffle-store";
import {VehicleStore} from "../stores/vehicle-store";
import { DealerSelectorStore } from "../prequal/dealer-selector/store/dealer-selector-store";

// Components
import {OctaneNav, OctaneLogoOnlyNav} from "../components/octane-nav";
import {FixedTextTopBar} from "../components/fixed-text-top-bar";
import {FixedLogoTopBar} from "../components/fixed-logo-top-bar";
import {RideOctaneFooter} from "../ride-octane-footer";
import {RideOctaneModal} from "../components/ride-octane-modal";
import {ShowCommit} from "../show-commit";
import {PageTitles} from "./octane-title";
import {Intercom} from "../components/intercom/intercom";

// Utils
import {isInIFrame, HeightObserver} from "../utils/iframe-utils";

export class Page {

    static createStore(options) {
        return new RideOctaneStore({
            ...options,
            stores: {
                themeStore: new ThemeStore(),
                uxCopyStore: new UXCopyStore(),
                historyStore: new HistoryStore(),
                rideOctaneModalStore: new RideOctaneModalStore(),
                vehicleStore: new VehicleStore(),
                dealershipStore: new DealershipStore(),
                partnerStore: new PartnerStore(),
                vehicleSelfSelectionStore: new VehicleSelfSelectionStore(),
                waffleStore: new WaffleStore(),
                dealerSelectorStore: new DealerSelectorStore(),
                ...(options.stores || {}),
            }
        });
    }

    static createComponent(options) {
        return (
            <React.Fragment>
                {/* Default <head/>, portions can be overridden. See react-helmet docs.*/}
                <HeadTag/>
                <Canonical/>
                <GoogleTagManager/>
                <VWO/>
                <OneTrust/>
                <Route component={IntercomLoader}/>

                {/* Global Nav */}
                <Route component={GlobalNav}/>

                {/* Content */}
                <ApiPreconnect/>
                <HeightPostContainer>
                    {options.content}
                </HeightPostContainer>

                {/* Global Footer */}
                <Route component={GlobalFooter}/>

                {/* Global Modal */}
                <RideOctaneModal/>

                {/* Bundled Scripts */}
                <BodyScriptTags/>
            </React.Fragment>
        );
    }
}


export class HeightPostContainer extends React.Component {
    contentRef = React.createRef();
    heightObserver = new HeightObserver();

    componentDidMount() {
        if (isInIFrame() && !!this.contentRef.current) {
            this.heightObserver.init(this.contentRef.current);
        }
    }

    componentDidUpdate() {
        if (isInIFrame()) {
            if (!!this.contentRef.current) {
                this.heightObserver.init(this.contentRef.current);
            }
        } else {
            this.heightObserver.uninit();
        }
    }

    componentWillUnmount() {
        this.heightObserver.uninit();
    }

    render() {
        return (
            <div className="d-flex flex-column" ref={this.contentRef}>
                {this.props.children}
            </div>
        );
    }
}


const HeadTag = inject('themeStore', 'rideOctaneStore')(
    observer(({themeStore, rideOctaneStore}) => (
        <React.Fragment>
            <Helmet>
                {/* HTML */}
                <html lang="en" className={themeStore.htmlClass}/>

                {/* Meta */}
                <meta charSet="utf-8"/>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <meta name="theme-color" content="#000000"/>

                {/* Title (Default) */}
                <title>{PageTitles.OCTANE_TITLE}</title>

                {/* Links */}
                <link rel="manifest" href="/manifest.json"/>
                <link rel="shortcut icon" href="/octane-favicon.png"/>

                {/* Stylesheets */}
                <link rel="stylesheet" {...rideOctaneStore.assets.stylesheets['main']}/>
                {rideOctaneStore.assets.stylesheets.chunks.map(
                    stylesheet => <link rel="stylesheet" {...stylesheet}/>
                )}

                {/* Scripts */}
                <script type="application/javascript">
                    {`window.rideOctane = ${JSON.stringify(rideOctaneStore.dehydrateState())};`}
                </script>
            </Helmet>
            <Switch>
                {/* If we are in paths '/apply' or '/slug/apply' or '/vehicle-selection', add noindex nofollow */}
                <Route path={["/:slug?/apply","/vehicle-selection", '/flex']}>
                    <Helmet>
                        <meta name="robots" content="noindex, nofollow"/>
                    </Helmet>
                </Route>
            </Switch>
        </React.Fragment>
    ))
);

/**
 * Loads the Intercom widget based on the route.
 */
export const IntercomLoader = inject('partnerStore', 'historyStore')(
    observer(({partnerStore, historyStore}) => {
        const launchIntercom = historyStore.queryParams['launchIntercom'];
        const shouldShowForCMP = get(partnerStore, 'partner.show_intercom_on_cmp') ?? true;
        return (
            <Switch>
                <Route exact path="/" render={(props) => <Intercom {...props} launchIntercom={launchIntercom}/>}/>
                <Route path="/paperwork/:appUuid"
                       render={(props) => <Intercom {...props} showIntercom={shouldShowForCMP}/>}/>
                <Route path="/:slug?" render={(props) => <Intercom {...props} showIntercom={false}/>}/>
            </Switch>
        )
    })
);

const BodyScriptTags = inject('rideOctaneStore')(
    observer(({rideOctaneStore}) => (
        <React.Fragment>
            <script type="application/javascript" {...rideOctaneStore.assets.scripts['runtime-main']}/>
            {rideOctaneStore.assets.scripts.chunks.map(script =>
                <script type="application/javascript" {...script}/>
            )}
            <script type="application/javascript" {...rideOctaneStore.assets.scripts['main']}/>
        </React.Fragment>
    ))
);

/**
 * Renders a <link rel="canonical" ...> tag based on the current page route.
 */
const Canonical = withRouter(({...props}) => (
    <Helmet>
        <link rel="canonical"
              href={`https://octane.co${renderUrl(props.location.pathname)}`}
        />
    </Helmet>
));

/**
 * Handles GoogleTagManager
 */

export const GoogleTagManager = inject('rideOctaneStore', 'historyStore')(
    observer(({rideOctaneStore, historyStore}) => {
        // First, check if the partner has supplied the tag id as a query param
        let partnerTagId = historyStore.queryParams['partnerGId'];
        if (!partnerTagId &&
            rideOctaneStore.partnerStore &&
            rideOctaneStore.partnerStore.partner) {
                const retrieved_tag_id = rideOctaneStore.partnerStore.partner.google_tag_id;
                if (retrieved_tag_id){
                    partnerTagId = retrieved_tag_id;
                }
        }
        const googleTagIds = [rideOctaneStore.settings.GOOGLE_TAG_MANAGER_ID, partnerTagId];


        return (
            googleTagIds.map((id) => {
                if (!id) {
                    return null
                } else {
                    return (
                        <div>
                            <Helmet>
                                <script>
                                    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${id}')`}
                                </script>
                            </Helmet>
                            <noscript>
                                <iframe src={`https://www.googletagmanager.com/ns.html?id=${id}`}
                                        height="0" width="0" style={{"display": "none", "visibility": "hidden"}}>
                                </iframe>
                            </noscript>
                        </div>
                    )
                }
            })
        )
    }));

/**
 * Handles preconnect to Ride API
 */
export const ApiPreconnect = inject("rideOctaneStore")(
    observer(({rideOctaneStore}) => {
        const apiDomain = rideOctaneStore.settings.BASE_API_URL;
        // set crossOrigin to empty string to avoid JSX errors
        return (
            <link rel="preconnect" href={`${apiDomain}`} crossOrigin={''} data-testid={'apiPreconnect'} />
        );
    })
);

/**
 * Handles VWO
 */
export const VWO = inject("rideOctaneStore")(
    observer(({rideOctaneStore}) => {
        const env = rideOctaneStore.settings.ENVIRONMENT_KEY;
        if (env === "local") {
            return null;
        }
        return (
            <Helmet>
                <script type="text/javascript">
                    {`window._vwo_code = window._vwo_code || (function(){
                    var account_id=489935,
                    settings_tolerance=5000,
                    library_tolerance=5000,
                    use_existing_jquery=false,
                    is_spa=1,
                    hide_element='body',

                    /* DO NOT EDIT BELOW THIS LINE */
                    f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){
                    window.settings_timer=setTimeout(function () {_vwo_code.finish() },settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());`}
                </script>
            </Helmet>
        );
    }),
);

/**
 * Handles OneTrust
 */
export const OneTrust = inject("rideOctaneStore")(
    observer(({rideOctaneStore}) => {
        const env = rideOctaneStore.settings.ENVIRONMENT_KEY;
        const otAutoBlockScript = env === 'production'
            ? <script type="text/javascript" src="https://cdn.cookielaw.org/consent/28f86d69-ab9c-443c-b21d-ef8eecc43bce/OtAutoBlock.js"></script>
            : null;

        const domainScript = env === 'production' ? "28f86d69-ab9c-443c-b21d-ef8eecc43bce" : "28f86d69-ab9c-443c-b21d-ef8eecc43bce-test";

        return (
            <Helmet>
                {otAutoBlockScript}
                <script
                    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script={domainScript}
                ></script>
                <script type="text/javascript">
                {`function OptanonWrapper() { }`}
                </script>
            </Helmet>
        );
    }),
);

/**
 * Flex Nav
 */
export const FlexNav = inject('dealershipStore', 'vehicleSelfSelectionStore', 'rideOctaneStore', 'partnerStore')(
    observer(({dealershipStore, vehicleSelfSelectionStore, rideOctaneStore, partnerStore}) => {

        let dealershipName;
        let partnerLogo;
        let use_theme_for_nav_bg;

        // While loading /flex/:flexSlug the dealeshipStore isn't populated yet
        // because we don't have a dealership query param.
        // In this case, dealership_name comes from the flexData object
        if(vehicleSelfSelectionStore.flexData){
            dealershipName = vehicleSelfSelectionStore.flexData.dealership_name;
            if(vehicleSelfSelectionStore.flexData.partner_logo) {
                partnerLogo = vehicleSelfSelectionStore.flexData.partner_logo;
            }
            if(vehicleSelfSelectionStore.flexData.use_theme_for_nav_bg) {
                use_theme_for_nav_bg = vehicleSelfSelectionStore.flexData.use_theme_for_nav_bg;
            }
        } else if (dealershipStore.dealership){
            dealershipName = dealershipStore.dealership.name;
        };

        if (!partnerLogo && partnerStore.partner) {
            partnerLogo = partnerStore.partner.logo;
        }

        if(!use_theme_for_nav_bg && partnerStore.partner) {
            use_theme_for_nav_bg = partnerStore.partner.use_theme_for_nav_bg;
        }

        if (rideOctaneStore.settings.ENVIRONMENT_KEY === 'local' && partnerLogo) {
            partnerLogo = rideOctaneStore.settings.BASE_API_URL + partnerLogo;
        }

        return partnerLogo ? (
            <FixedLogoTopBar
            logo={partnerLogo}
            alt={dealershipName}
            useTheme={use_theme_for_nav_bg}
            />
        ) : (
            <FixedTextTopBar text={dealershipName}/>
        );
    }
));


/**
 * Global Nav
 */
export const GlobalNav = inject('partnerStore', 'vehicleSelfSelectionStore', 'historyStore')(
    observer(({partnerStore,  vehicleSelfSelectionStore, historyStore}) => {

        /* If this is a flex experience, render a nav with the dealer name */
        const isFlexExperience = vehicleSelfSelectionStore.isFlexExperience || historyStore.queryParams.isFlex === "1";
        if (isFlexExperience) {
            return (
                <FlexNav/>
            );
        }

        /* If this is a partner experience, render a nav if show_nav is true and there is a logo */
        if (partnerStore && partnerStore.partner) {
            const {name, show_nav, logo, use_theme_for_nav_bg} = partnerStore.partner;

            return show_nav ? (
                <FixedLogoTopBar
                    logo={logo}
                    alt={name}
                    useTheme={use_theme_for_nav_bg}
                />
            ) : null;
        }

        /* Render default Octane navs */
        return (
            <Switch>
                {/* If we are in path '/flex/' shows the dealership name*/}
                <Route path={'/flex/:flexSlug'}>
                    <FlexNav/>
                </Route>
                {/* If we are in paths '/vehicle-selection' show the logo only version of the Octane Nav*/}
                <Route path={"/vehicle-selection"}>
                    <OctaneLogoOnlyNav/>
                </Route>
                {/* If we are in paths '/apply' or '/slug/apply', show the logo only version of the Octane Nav*/}
                <Route path="/:slug?/apply">
                    <OctaneLogoOnlyNav/>
                </Route>
                {/* If we are in path '/paperwork', show the logo only version of the Octane Nav*/}
                <Route path="/paperwork">
                    <OctaneLogoOnlyNav/>
                </Route>
                {/* If we are in path '/consent', show the logo only version of the Octane Nav*/}
                <Route path="/consent">
                    <OctaneLogoOnlyNav/>
                </Route>
                {/* Default OctaneNav */}
                <Route component={OctaneNav}/>
            </Switch>
        );
    })
);


/**
 * Global Footer
 */
export const GlobalFooter = inject('rideOctaneStore', 'partnerStore')(
    observer(({rideOctaneStore, partnerStore}) => {
        if (rideOctaneStore.isPage404 || rideOctaneStore.isPage500) {
            return null;
        }

        // Render the Octane footer if this is not a partner experience or
        // if this a partner experience with the Octane footer
        if (!(partnerStore && partnerStore.partner && !partnerStore.partner.show_footer)) {
            return (
                <Switch>
                    {/* If we are in path '/vehicle-selection' then show only the commit */}
                    <Route path={["/vehicle-selection",'/flex']} component={ShowCommit}/>
                    {/* If we are in paths '/apply' or '/slug/apply', then show only the commit */}
                    <Route path="/:slug?/apply" component={ShowCommit}/>
                    {/* If we are in path '/paperwork' then show only the commit */}
                    <Route path="/paperwork" component={ShowCommit}/>
                    {/* If we are in path '/consent' then show only the commit */}
                    <Route path="/consent" component={ShowCommit}/>
                    {/* Otherwise show the whole footer */}
                    <Route component={RideOctaneFooter}/>
                </Switch>
            );
        }
        return <ShowCommit/>;
    })
);
