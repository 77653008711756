import React from "react";
import PropTypes from "prop-types";

import { Select } from "octane-spark";

import copy from "../copy.json";

export const Make = ({ value, options, disabled, onChange }) => {
    const handleSelection = (makeId) => {
        if (makeId === "none") return onChange({ id: "none", name: "none" });
        const makeSelected = options.find((option) => option.id === makeId);
        onChange(makeSelected);
    };

    return (
        <Select
            id="select-make"
            label={copy.fields.make.label}
            placeholder={copy.fields.make.placeholder}
            value={value}
            disabled={disabled}
            onChange={handleSelection}
        >
            <Select.Option key="none" value="none">
                {copy.fields.make.notSure}
            </Select.Option>
            {options.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                    {option.name}
                </Select.Option>
            ))}
        </Select>
    );
};

Make.propTypes = {
    value: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};
