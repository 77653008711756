import {computed, decorate, action, observable} from "mobx";
import {validateEmail} from "../../utils/validation";

/**
 * @class AlertSignupModalStore
 *
 * store for alert signup request modals
 *
 * @property {ApiStore} apiStore
 * @property {string} email
 * @property {string} fieldState
 * @property {string} submitState
 * @property {string} captchaResponse
 */
export class AlertSignupModalStore {
    static FIELD_STATES = {
        VALID: 'valid',
        INVALID: 'invalid',
        UNVALIDATED: 'unvalidated',
    };

    static SUBMIT_STATES = {
        DEFAULT: 'default',
        SUBMITTING: 'submitting',
        SUCCESS: 'success',
        DUPLICATE: 'duplicate',
        FAILURE: 'failure',
        DISCLOSURE: 'disclosure',
    };

    apiStore = null;
    slug = null;
    email = null;
    fieldState = this.constructor.FIELD_STATES.UNVALIDATED;
    submitState = this.constructor.SUBMIT_STATES.DEFAULT;
    captchaResponse = null;

    constructor(apiStore, slug) {
        this.apiStore = apiStore;
        this.slug = slug;
    }

    get isFieldValid() {
        return this.fieldState === this.constructor.FIELD_STATES.VALID;
    };

    get isFieldInvalid() {
        return this.fieldState === this.constructor.FIELD_STATES.INVALID;
    };

    updateEmail = (email) => {
        if (validateEmail(email)) {
            this.fieldState = this.constructor.FIELD_STATES.VALID;
            this.email = email;
        } else if (email) {
            this.fieldState = this.constructor.FIELD_STATES.INVALID;
            this.email = null;
        } else {
            this.fieldState = this.constructor.FIELD_STATES.UNVALIDATED;
            this.email = null;
        }
    };

    updateCaptchaResponse(captchaResponse) {
        this.captchaResponse = captchaResponse;
        return this;
    }

    toggleDisclosure() {
        this.submitState = this.submitState === this.constructor.SUBMIT_STATES.DISCLOSURE ?
            this.constructor.SUBMIT_STATES.DEFAULT :
            this.constructor.SUBMIT_STATES.DISCLOSURE;
    }

    submitRequest = () => {
        if (!this.isFieldValid) {
            this.fieldState = this.constructor.FIELD_STATES.INVALID;
            return;
        }
        this.submitState = this.constructor.SUBMIT_STATES.SUBMITTING;
        const options = {
            method: 'POST',
            data: {
                slug: this.slug,
                correspondence_type: "financeability_alert",
                email: this.email,
                captcha: this.captchaResponse,
            },
        };

        return this.apiStore.fetch('vehicle_correspondences', options)
            .then(({response, status}) => {
                if (status === 400 &&
                    response.non_field_errors &&
                    response.non_field_errors.length &&
                    response.non_field_errors[0] === "The fields correspondence_type, slug, email must make a unique set.") {
                    this.submitState = this.constructor.SUBMIT_STATES.DUPLICATE;
                    return;
                }
                if (status !== 200) {
                    console.error(`Received status code: ${status} (expected 200) after notification`);
                    this.submitState = this.constructor.SUBMIT_STATES.FAILURE;
                } else {
                    this.submitState = this.constructor.SUBMIT_STATES.SUCCESS;
                }
            })
    };
}

decorate(AlertSignupModalStore, {
    isSubmitting: observable,
    fieldState: observable,
    submitState: observable,
    captchaResponse: observable,

    isFieldValid: computed,
    isFieldInvalid: computed,

    submitRequest: action,
    updateCaptchaResponse: action,
});
