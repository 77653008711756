import React from 'react';
import styles from './hamburger-menu-button.module.scss';

const HamburgerMenuButton = props => (
    <button className={styles.hamburgerButton} onClick={props.click}>
        <div className={styles.hamburgerButtonLine} />
        <div className={styles.hamburgerButtonLine} />
        <div className={styles.hamburgerButtonLine} />
    </button>
);

export default HamburgerMenuButton;