import React from 'react';
import {CustomIntercomButton} from "../../components/intercom/custom-intercom-button";
import PropTypes from 'prop-types';
import classnames from 'classnames';
import template from 'lodash.template';
import {formatPrice} from "../../utils/number-utils";

import {RideOctaneLinkContainer as LinkContainer} from '../../pages/url-renderer';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {VehicleSubmodelTile} from "../../components/vehicle-tile";
import {TrimAndColorPickerForm} from "../../components/trim-and-color-picker-form";
import {BrpGaPixel} from "../../components/brp-ga-pixel";

import copy from './copy.json';
import styles from "./vehicle-listing.module.scss";
import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import {SpecDetails} from "./specifications";
import {VehicleListingOgTags} from "./vehicle-listing-open-graph";
import {AlertSignupModalContent} from "./alert-signup-modal";
import {Helmet} from "react-helmet";
import {pushOfferClick, pushViewItem} from "../../utils/gtm-utils";

export const VehicleListing = observer(
    class _VehicleListing extends React.Component {
        static propTypes = {
            pageStore: PropTypes.shape({
                pageTitle: PropTypes.string.isRequired,
                breadcrumbs: PropTypes.array.isRequired,
                description: PropTypes.array.isRequired,
                pageCarousel: PropTypes.object.isRequired,
                selected_vehicle_configuration: PropTypes.object,
                default_vehicle_configuration: PropTypes.object.isRequired,
                vehicleSubmodels: PropTypes.object,
                vehicleSubmodelColorsAndStyles: PropTypes.object,
            }).isRequired,

            isVehicleModelPage: PropTypes.bool,
            isVehicleSubmodelPage: PropTypes.bool,
        };

        vehicleConfig (pageStore) {
            let vehicle_configuration = pageStore.selected_vehicle_configuration;
            if (!vehicle_configuration) {
                vehicle_configuration = pageStore.default_vehicle_configuration;
            }
            if (!vehicle_configuration) {
                console.error(`Vehicle listing with no default configuration. ${pageStore.logSummary}`);
            }
            return vehicle_configuration
        }

        constructor(props, context) {
            super(props, context);

            this.priceActionButtonRef = React.createRef();
            this.store = observable({
                footer: false
            });
        }

        componentDidMount() {
            if (this.props.pageStore &&
                this.props.pageStore.default_vehicle_configuration &&
                this.props.pageStore.vehicle_type) { // if not undefined
                pushViewItem({
                    'uuid': this.props.pageStore.default_vehicle_configuration.uuid,
                    'name': this.props.pageStore.vehicleName,
                    'vehicle_make': this.props.pageStore.vehicle_make,
                    'd2c_vehicle_type': this.props.pageStore.vehicle_type['name'],
                    'slug': this.props.pageStore.default_vehicle_configuration.name,
                    'msrp': this.props.pageStore.default_vehicle_configuration.msrp,
                });
            }
            window.scrollTo(0,0);

            try {
                this.iobserver = new IntersectionObserver(
                    ([entry]) => {
                        if (entry.intersectionRatio === 1.0) {
                            this.store.footer = false;
                        } else if (entry.intersectionRatio === 0.0 ){
                            this.store.footer = true;
                        }
                    },
                    {
                        root: null,
                        threshold: [0, 1.0],
                    }
                );

                if (this.priceActionButtonRef.current) {
                    this.iobserver.observe(this.priceActionButtonRef.current);
                }
            } catch (e) {
                if (e instanceof ReferenceError) {
                    console.debug('IntersectionObserver is not supported by the browser');
                } else {
                    // If IntersectionObserver failed, it's not the end of the
                    // world, no need to error the entire page
                    console.error('IntersectionObserver failed', e);
                }
            }
        }

        componentWillUnmount() {
            if (this.iobserver) {
                this.iobserver.disconnect();
            }
        }

        /**
         * Component that renders meta tags specific to this page.
         */
        MetaTags = () => {
            const {vehicleName} = this.props.pageStore;
            const metaDescription = template(copy.meta_description)({vehicleName}) || "";
            return (
                <React.Fragment>
                    <Helmet>
                        <meta name="description" content={metaDescription}/>
                    </Helmet>
                    <VehicleListingOgTags
                        vehicleName={this.props.pageStore.pageTitle}
                        copy={copy}
                        imageUrl={resolveImageUrl(
                            this.props.pageStore.pageCarousel,
                            this.vehicleConfig(this.props.pageStore),
                            this.props.isVehicleModelPage
                        )}
                    />
                </React.Fragment>
            );
        };

        render () {
            let vehicle_configuration = this.vehicleConfig(this.props.pageStore);

            // get octane id (for ga tracking and ui tests)
            let containerOctaneId;
            if (this.props.isVehicleModelPage) {
                containerOctaneId = 'vehicle-model-page';
            } else if (this.props.isVehicleSubmodelPage) {
                containerOctaneId = 'vehicle-submodel-page';
            }
            return (
                <Container data-oid={containerOctaneId} className="mt-3 mb-5">
                    {/* invisible pixel BRP uses to track customers */}
                    <BrpGaPixel
                        vehicle_type={this.props.pageStore.vehicle_type}
                        vehicle_make={this.props.pageStore.vehicle_make}
                    />

                    <this.MetaTags/>
                    <Row>
                        <PageTitleAndDescription
                            pageTitle={this.props.pageStore.pageTitle}
                            highlights={this.props.pageStore.getPrimaryVehicleObject().spec_highlights}
                            isVehicleSubmodelPage={this.props.isVehicleSubmodelPage}
                        />
                        <PageCarousel
                            pageCarousel={this.props.pageStore.pageCarousel}
                            vehicle_configuration={vehicle_configuration}
                            isVehicleModelPage={this.props.isVehicleModelPage}
                        />
                        {!!this.props.isVehicleModelPage && (
                            <VehicleModelSubmodels
                                vehicleSubmodels={this.props.pageStore.vehicleSubmodels}
                                is_financeable={this.props.pageStore.make_is_financeable}
                            />
                        )}
                        {!!this.props.isVehicleSubmodelPage && (
                            <VehicleSubmodelColorsAndStyles
                                vehicleSubmodelColorsAndStyles={this.props.pageStore.vehicleSubmodelColorsAndStyles}
                                updateSelectedVehicle={this.props.pageStore.updateSelectedVehicle}
                                isVehicleSubmodelPage={this.props.isVehicleSubmodelPage}
                                pageTitle={this.props.pageStore.pageTitle}
                            />
                        )}
                        {!!vehicle_configuration &&
                        !!vehicle_configuration.msrp &&
                        !!vehicle_configuration.slug && (
                        <PriceActionButton
                            pageCarousel={this.props.pageStore.pageCarousel}
                            isVehicleModelPage={this.props.isVehicleModelPage}
                            vehicle_configuration={vehicle_configuration}
                            is_financeable={this.props.pageStore.make_is_financeable}
                            page_slug={this.props.pageStore.getPrimaryVehicleObject().slug}
                            vehicle_make={this.props.pageStore.vehicle_make}
                            ref_prop={this.priceActionButtonRef}
                            is_desktop_footer={this.store.footer}
                        />
                        )}
                        <CustomIntercomButton is_desktop_footer={this.store.footer} />
                    </Row>
                    <Row>
                        {!!vehicle_configuration && !!vehicle_configuration.details && (
                            <SpecDetails
                                details={vehicle_configuration.details}
                                isVehicleSubmodelPage={this.props.isVehicleSubmodelPage}
                            />
                        )}
                    </Row>
                </Container>
            );
        };
    }
);

function PageTitleAndDescription({pageTitle, highlights , isVehicleSubmodelPage}) {
    let pageTitleStyle = "font-weight-bold h3";
    let SpecsHighlightsStyle = "d-none";
    if (isVehicleSubmodelPage) {
        pageTitleStyle = pageTitleStyle + " d-none d-lg-block";
        SpecsHighlightsStyle = "font-weight-bold d-lg-none";
    }
    return (
        <Col xs={{span: 12, order: 2}} lg={{span: 5, order: 0}}>
            <h1 className={pageTitleStyle}>
                {pageTitle}
            </h1>
            <h3 className={SpecsHighlightsStyle}>
                Specs Overview
            </h3>
            <SpecHighlights highlights={highlights}/>
        </Col>
    );
}

PageTitleAndDescription.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    highlights: PropTypes.object.isRequired,
};

function resolveImageUrl(pageCarousel, vehicle_configuration, isVehicleModelPage) {
    let image = null;

    if (isVehicleModelPage) {
        image = pageCarousel.carouselImage.image;
    } else {
        image = vehicle_configuration && vehicle_configuration.default_image;
    }

    return image;
}

export function PageCarousel({pageCarousel, vehicle_configuration, isVehicleModelPage}) {
    let image = resolveImageUrl(
        pageCarousel, vehicle_configuration, isVehicleModelPage
    );

    if (!image) {
        image = copy.no_vehicle_image;
    }

    return (
        <Col xs={{span: 12, order: 0}} lg={{offset: 1, span: 6, order: 1}}>
            <img
                className="img-fluid d-block"
                alt={pageCarousel.carouselImage.alt}
                src={image}
            />
        </Col>
    );
}

PageCarousel.propTypes = {
    pageCarousel: PropTypes.shape({
        carouselImage: PropTypes.shape({
            alt: PropTypes.string.isRequired,
            image: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    vehicle_configuration: PropTypes.shape({
        default_image: PropTypes.string,
    }),
    isVehicleModelPage: PropTypes.bool.isRequired,
};

function VehicleModelSubmodels({vehicleSubmodels, is_financeable}) {
    const {vehicle_make, vehicle_sub_models} = vehicleSubmodels;
    if (vehicle_sub_models.length <= 0) {
        return <Col xs={{span: 12, order: 2}} lg={{span: 5, order: 2}} />;
    }
    return (
        <Col
            xs={{span: 12, order: 2}}
            lg={{span: 5, order: 2}}
            className={styles.submodelsSection}
        >
            <h4 className="font-weight-bold">
                {template(copy.vehicle_sub_models.title)(vehicleSubmodels)}
            </h4>
            <p className={`spark-text-secondary ${styles.submodelSubtitle}`}>
                {copy.vehicle_sub_models.subtitle}
            </p>
            <div>
                {vehicle_sub_models.map(({uuid, slug, name, default_image, default_vehicle_configuration}) => {
                    if (!default_vehicle_configuration) {
                        console.error(`Vehicle submodel with no default configuration. uuid: ${uuid} slug: ${slug}`);
                    }
                    default_image = default_image || '';
                    return (
                        <VehicleSubmodelTile
                            key={uuid}
                            slug={slug}
                            name={name}
                            vehicle_make={vehicle_make}
                            default_image={default_image}
                            default_vehicle_configuration={default_vehicle_configuration}
                            is_financeable={is_financeable}
                        />
                    )
                })}
            </div>
        </Col>
    );
}

VehicleModelSubmodels.propTypes = {
    vehicleSubmodels: PropTypes.shape({
        vehicle_make: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        vehicle_model: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        vehicle_sub_models: PropTypes.arrayOf(
            PropTypes.shape({
                slug: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                default_image: PropTypes.string.isRequired,
                default_vehicle_configuration: PropTypes.object.isRequired,
            }).isRequired
        ).isRequired,
        is_financeable: PropTypes.bool.isRequired,
    }),
};

function VehicleSubmodelColorsAndStyles({vehicleSubmodelColorsAndStyles, updateSelectedVehicle, isVehicleSubmodelPage, pageTitle}) {
    let VehicleSubmodelColorsAndStylesHeading = "font-weight-bold";
    let PageTitleStyle = "d-none";
    if (isVehicleSubmodelPage) {
        VehicleSubmodelColorsAndStylesHeading = "font-weight-bold d-none d-lg-block";
        PageTitleStyle = "font-weight-bold d-lg-none";
    }
    const {vehicle_sub_model, vehicle_configuration} = vehicleSubmodelColorsAndStyles;
    return (
        <Col xs={{span: 12, order: 1}} lg={{span: 5, order: 2}}>
            <h3 className={VehicleSubmodelColorsAndStylesHeading}>
                Select Style and Color
            </h3>
            <h3 className={PageTitleStyle}>
                {pageTitle}
            </h3>
            <TrimAndColorPickerForm
                vehicle_sub_model={vehicle_sub_model}
                vehicle_configuration={vehicle_configuration}
                updateSelectedVehicle={updateSelectedVehicle}
            />
        </Col>
    );
}

VehicleSubmodelColorsAndStyles.propTypes = {
    vehicleSubmodelColorsAndStyles: PropTypes.object.isRequired,
};

export const PriceActionButton = inject('rideOctaneModalStore')(
    class _PriceActionButton extends React.Component {
        static propTypes = {
            vehicle_configuration: PropTypes.shape({
                name: PropTypes.string.isRequired,
                slug: PropTypes.string.isRequired,
                msrp: PropTypes.number.isRequired,
            }).isRequired,
            vehicle_make: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }),
            is_financeable: PropTypes.bool.isRequired,
            page_slug: PropTypes.string.isRequired,
            pageCarousel: PropTypes.object,
            isVehicleModelPage: PropTypes.bool,
            ref_prop: PropTypes.oneOfType([
                PropTypes.func,
                PropTypes.shape({ current: PropTypes.any })
            ]),
            is_desktop_footer: PropTypes.bool.isRequired,
        };

        render() {
            const {vehicle_configuration, is_financeable, vehicle_make, ref_prop, is_desktop_footer} = this.props;
            const openAlertSignupModal = () => this.props.rideOctaneModalStore.openModal({
                ModalContent: AlertSignupModalContent,
                props: {
                    slug: this.props.page_slug,
                    setPreventClose: this.props.rideOctaneModalStore.setPreventClose,
                    closeModal: this.props.rideOctaneModalStore.closeModal,
                },
                dialogClassName: "w-500px",
                fixedToBottom: true,
            });
            let imageUrl = resolveImageUrl(this.props.pageCarousel, this.props.vehicle_configuration, this.props.isVehicleModelPage);

            const applyButtonLink = template(copy.apply_button.href)({vehicle_configuration});
            return (
                <Col lg={{offset: 1, span: 6, order: 3}}>
                    <div className="intersection-point" ref={ref_prop}/>
                    <div
                        className={classnames(
                            styles.priceAndActionButtonsFixed,
                            {[styles.priceAndActionButtonsRelative]: !is_desktop_footer},
                        )}
                    >
                        <Row className="align-items-center">
                            <Col lg="auto" className={classnames(
                                "pr-0",
                                "d-none", // display none mobile-first
                                is_desktop_footer && imageUrl ? "d-lg-block" : "d-none",
                            )}>
                                <img
                                    className={classnames(styles.stickyFooterImage, "img-fluid d-block")}
                                    alt={this.props.pageCarousel.carouselImage.alt}
                                    src={imageUrl}
                                />
                            </Col>

                            <Col className={classnames(
                                styles.stickyFooterTitle,
                                "d-none", // none mobile-first
                                "d-md-block", // always visible on md tablet
                                is_desktop_footer ? "d-lg-block" : "d-lg-none", // only display on lg+ desktop footer
                            )}>
                                <h4 className="text-truncate">
                                    {vehicle_make.name} {vehicle_configuration.name}
                                </h4>
                            </Col>

                            {/* md+ is auto width in desktop footer so the button can be fixed at 325px width
                             and act as the anchor to responsively scale Image and Title */}
                            <Col xs={12} md="auto" lg={is_desktop_footer ? "auto" : 12}>
                                <Row>
                                    <Col>
                                        <small className="font-weight-bold">MSRP</small>
                                        <p className={styles.msrp}>
                                            {formatPrice(vehicle_configuration.msrp)}
                                        </p>
                                    </Col>

                                    <Col xs={8} md="auto" className={styles.instantFinancingOffersButton}>
                                        {is_financeable ?
                                            <LinkContainer to={template(copy.apply_button.href)({vehicle_configuration})}>
                                                <Button data-oid="instant-financing-button" variant="primary" size="lg" block onClick={() => {
                                                    pushOfferClick(copy.apply_button.label, applyButtonLink);
                                                }}>
                                                    <span className={styles.instantFinancingOffersButtonText}> {copy.apply_button.label} </span>
                                                </Button>
                                            </LinkContainer> :
                                            <Button data-oid="notify-available-button" variant="primary" size="lg" block onClick={openAlertSignupModal}>
                                                <span className={styles.instantFinancingOffersButtonText}> {copy.notify_button.label} </span>
                                            </Button>
                                        }
                                        <p className="spark-text-14px d-none d-md-block mt-2 spark-text-secondary text-center">
                                            {is_financeable ? copy.apply_button.subtitle : copy.notify_button.subtitle}
                                        </p>
                                    </Col>
                                </Row>

                                <p className="spark-text-14px d-md-none spark-text-secondary text-center">
                                    {is_financeable ? copy.apply_button.subtitle : copy.notify_button.subtitle}
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            );
        }
    }
);

const SPEC_HIGHLIGHT_PRIORITY = [
    'driveline',
    'fuel type',
    'horsepower',
    'torque',
    'cylinders',
    'displacement',
];

/**
 * Renders 4 highlighted vehicle specifications
 */
export function SpecHighlights({highlights}) {
    if (highlights === undefined || highlights === null) {
        return null;
    }

    const choose4Highlights = () => {
        const fourHighlights = [];

        // filter out null highlights
        Object.keys(highlights).forEach((highlight) => {
            if (!!highlights[highlight]) {
                fourHighlights.push({
                    name: highlight.replace('_', ' '),
                    value: highlights[highlight],
                });
            }
        });

        fourHighlights.sort(
            (highlight) => (SPEC_HIGHLIGHT_PRIORITY.indexOf(highlight.name))
        );

        if (fourHighlights.length < 4) {
            return null; //< if we don't have 4, render nothing
        } else if (fourHighlights.length > 4) {
            return fourHighlights.slice(0, 4);
        } else {
            return fourHighlights;
        }
    };

    const fourHighlights = choose4Highlights();
    if (fourHighlights == null) {
        return null;
    }

    const isLastRow = (specIndex) => (specIndex === 2 || specIndex === 3);

    return (
        <Row>
            {choose4Highlights().map((highlight, i) => (
                <Col key={highlight.name} xs={{span: 12, order: 0}} sm={{span: 6, order: 1}}>
                    <p className="text-capitalize">{highlight.name}</p>
                    <p className={styles.specText}>{highlight.value}</p>
                    {isLastRow(i)? <hr className="d-xs-block d-sm-none"/> : <hr/>}
                </Col>
            ))}
        </Row>
    );
}

SpecHighlights.propTypes = {
    highlights: PropTypes.object.isRequired,
};
