import {decorate, observable, computed, action, reaction, toJS} from 'mobx';


export class VehicleSelectFormStore {
    hooks = null;
    listeners = null;

    apiStore = null;
    partnerStore = null;

    makes = [];
    selectedMake = null;
    missingMake = false;

    models = [];
    selectedModel = null;
    missingModel = false;

    isMakeSelected() {
        return !!this.selectedMake;
    }

    get errorMessage() {
        if (this.missingMake) {
            return "Please select a Make & Model"
        }
        if (this.missingModel) {
            return "Please select a Model";
        }
        return "";
    }

    get submitUrl() {
        if (this.selectedModel) {
            return `/${this.selectedModel.slug}`;
        }
        // if there is no selected model then the form will show an error that
        // the model was not specified
        return '#';
    }

    get shouldDisableMakeSelection() {
        return this.makes.length === 1;
    }

    constructor({apiStore, partnerStore, historyStore, onSubmit, onChangeModel, onChangeMake}) {
        this.apiStore = apiStore;
        this.partnerStore = partnerStore;
        this.historyStore = historyStore;
        this.hooks = {onSubmit, onChangeModel, onChangeMake};
        this.listeners = [
            reaction(
                () => this.selectedMake && this.selectedMake.uuid,
                change => this.hooks.onChangeMake(toJS(change)),
                {name: 'onChangeMake VehicleSelectForm selectedMake'}
            ),
            reaction(
                () => this.selectedModel && this.selectedModel.uuid,
                change => this.hooks.onChangeModel(toJS(change)),
                {name: 'onChangeModel VehicleSelectForm selectedModel'}
            ),
        ];

        this.changeMake = this.changeMake.bind(this);
        this.changeModel = this.changeModel.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    disposeListeners() {
        this.listeners.forEach(dispose => dispose());
        return this;
    }

    /**
     *
     * @returns {*} a Promise resolving to a list of vehicle makes
     * @private
     */
    _getMakes() {
        let endpoint = 'makes';
        const query = {fields: ['uuid', 'name']};

        if (this.historyStore.queryParams.make) {
             Object.assign(query, {
                 vehicle_makes: this.historyStore.queryParams.make
             })
        } else if (this.partnerStore.partnerIdentifier) {
            endpoint = `partners/${this.partnerStore.partnerIdentifier}/${endpoint}`;
        }

        return this.apiStore.fetch(endpoint, {query: query}).then(
            result => result.response.results
        ).catch(() => {
            console.error(`vehicle-select failed to obtain vehicle makes`);
            return [];
        })
    }

    /**
     * Obtains Vehicle Models (for the purpose of this store)
     * @param makeUuid - optional Make UUID by which to filter Vehicle Models
     * @returns {*} a Promise resolving to a list of vehicle models
     * @private
     */
    _getModels(makeUuid) {
        const query = {};
        query.fields = ['uuid', 'slug', 'name', 'vehicle_make'];

        if (makeUuid) {
            query.vehicle_makes = makeUuid;
        }

        const filterOutSlugless = (response) => {
            const vehicleModels = response.results.filter(
                vehicle_model => vehicle_model.slug !== null
            );
            return vehicleModels;
        };

        return this.apiStore.fetch('models', {query: query}).then(
            result => filterOutSlugless(result.response)
        ).catch(() => {
            console.error(`vehicle-select failed to obtain vehicle models for make: {makeUuid}`);
            return [];
        });
    }

    /**
     * Used to initialize vehicle select form content.
     *
     * @returns {Promise<*[]>}
     */
    populateDropdowns() {
        return Promise.all([
            this._getMakes(),
            Promise.resolve([]),  //< No vehicle models until make is selected
        ]).then(
            ([vehicleMakes, vehicleModels]) => this._updateLists({vehicleMakes, vehicleModels})
        ).then(
            () => {
                if(this.makes.length === 1) { return this.changeMake(this.makes[0]); }
            }
        );
    }

    changeMake(selectedMake) {
        if (this.selectedMake && (this.selectedMake.uuid === selectedMake.uuid)) {
            return Promise.resolve(this);
        }

        this._updateSelectedMake(selectedMake);

        // clear out the models which are likely based on the last Make
        this.models = [];

        return this._getModels(this.selectedMake.uuid).then(
            vehicleModels => this._updateLists({vehicleModels})
        );
    }

    changeModel(selectedModel) {
        if (this.selectedModel && (this.selectedModel.uuid === selectedModel.uuid)) {
            return Promise.resolve(this);
        }

        this._updateSelectedModel(selectedModel);
        return this.changeMake(selectedModel.vehicle_make);
    }

    submitForm(e) {
        if (!this.selectedModel) {
            e.preventDefault();
            this._updateErrors();
        } else {
            return this.hooks.onSubmit(e);
        }
    }

    _updateLists({vehicleMakes, vehicleModels}) {
        if (vehicleModels) {
            this.models = vehicleModels;
        }
        if (vehicleMakes) {
            this.makes = vehicleMakes;
        }

        return this;
    }

    _updateSelectedMake(selectedMake) {
        // change make
        this.missingMake = false;
        this.selectedMake = selectedMake;

        // unset model if the make does not match the selected model
        if (this.selectedModel && this.selectedModel.vehicle_make.uuid !== selectedMake.uuid) {
            this.selectedModel = null;
        }



        return this;
    }

    _updateSelectedModel(selectedModel) {
        // change model & reset missing model error
        this.missingModel = false;
        this.selectedModel = selectedModel;

        return this;
    }

    _updateErrors() {
        this.missingModel = !this.selectedModel;
        this.missingMake = !this.selectedMake;
        return this;
    }
}

decorate(VehicleSelectFormStore, {
    makes: observable,
    selectedMake: observable,
    models: observable,
    selectedModel: observable,
    missingModel: observable,
    missingMake: observable,

    submitUrl: computed,
    errorMessage: computed,
    shouldDisableMakeSelection: computed,

    _updateLists: action,
    _updateSelectedMake: action,
    _updateSelectedModel: action,
    _updateErrors: action,
    _updateVehicleUrl: action,
});
