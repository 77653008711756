import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';

/**
 * placeholder: (string, optional) asset to load if src is broken
 * hideBroken: (bool, optional) flag to hide Image if src is broken
 */
export class VehicleImg extends PureComponent {
    constructor (props) {
        super(props)
        this.state = {
            error: false
        }
    }

    render() {
        const { hideBroken, ...props } = this.props

        if (this.state.error) {
            if (hideBroken) {
                return null
            }
            props.src = props.placeholder || "/images/no-vehicle-image.png"
        } else if (!props.src) {
            this.setState({error: true})
        }

        return <img {...props} onError={e => this.setState({error: true})}/>
    }
}

VehicleImg.propTypes = {
    src: PropTypes.string,
    placeholder: PropTypes.string,
    hideBroken: PropTypes.bool,
};
