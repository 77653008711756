import React from 'react';

export function CaptchaDisclosure() {
    return (
        <p className="spark-text-disclosure">
            This site is protected by reCAPTCHA and the Google&nbsp;
            <a href="https://policies.google.com/privacy" target="_blank"
                rel="noopener noreferrer">Privacy Policy</a> and&nbsp;
            <a href="https://policies.google.com/terms" target="_blank"
                rel="noopener noreferrer">Terms of Service</a> apply.
        </p>
    );
}