import {decorate, observable, computed, action} from 'mobx';
import {BaseStore} from "./base-store";

import {
    validateIntegerString,
    validateSingleLine,
    validateName,
    validateEmail,
    validatePhone,
    validateStreet1,
    validateCity,
    validateState,
    validateZipcode,
    validateEmploymentStatus,
    validateResidentialStatus,
    validateDateOfBirth,
    validatePurchaseIntent,
    validatePastMonthAndYear,
    validateCoapplicantIntent,
    validateTradeInIntent,
    validateRelationshipToPrimary,
    validateSSN,
} from "../utils/validation";
import {formatPhoneNumber, formatPriceInput} from "../utils/number-utils";
import {CONTACT_PREFERENCES} from "../enums/contactPreferences";

/**
 * @class UserStore
 * @inheritDoc
 * 
 * Global Store for maintaining user information across the application
 * 
 * @property {object} userInfo
 * @property {object} allowedContacts
 * @property {string} contact_preference
 * @property {object} contactPreferences - object of the user's contact preferences
 */
export class UserStore extends BaseStore {
    static PRIMARY_USER_INFO = [
        "first_name",
        "last_name",
        "date_of_birth",
        "email",
        "street1",
        "street2",
        "city",
        "state",
        "zip_code",
        "residential_status",
        "residential_date",
        "yearly_income",
        "purchase_intent",
        "employment_status",
        "phone_number",
        "trade_in_intent",
        "coapplicant_intent",
        "ssn",
        "partner_consent",
    ]
    static COAPPLICANT_USER_INFO = [
        "coapplicant_first_name",
        "coapplicant_last_name",
        "coapplicant_email",
        "coapplicant_date_of_birth",
        "coapplicant_street1",
        "coapplicant_street2",
        "coapplicant_city",
        "coapplicant_state",
        "coapplicant_zip_code",
        "coapplicant_residential_status",
        "coapplicant_residential_date",
        "coapplicant_yearly_income",
        "coapplicant_employment_status",
        "coapplicant_phone_number",
        "relationship_to_primary",
        "coapplicant_ssn",
    ]
    static USER_INFO = UserStore.PRIMARY_USER_INFO.concat(UserStore.COAPPLICANT_USER_INFO);

    static ALLOWED_CONTACTS = [
        "allow_contact_by_octane",
        "allow_contact_by_dealers",
    ];
    static USER_INFO_VALIDATIONS = {
        first_name: validateName,
        last_name: validateName,
        date_of_birth: validateDateOfBirth,
        email: validateEmail,
        street1: validateStreet1,
        street2: validateSingleLine,
        city: validateCity,
        state: validateState,
        zip_code: validateZipcode,
        residential_status: validateResidentialStatus,
        residential_date: validatePastMonthAndYear,
        yearly_income: val => validateIntegerString(val, 9000),
        purchase_intent: validatePurchaseIntent,
        employment_status: validateEmploymentStatus,
        phone_number: validatePhone,
        trade_in_intent: validateTradeInIntent, // Only used for RV partner experiences
        coapplicant_intent: validateCoapplicantIntent, // Only used for RV partner experiences
        ssn: validateSSN, // Only used for joint application experiences

        coapplicant_first_name: validateName,
        coapplicant_last_name: validateName,
        coapplicant_email: validateEmail,
        coapplicant_date_of_birth: validateDateOfBirth,
        coapplicant_street1: validateStreet1,
        coapplicant_street2: validateSingleLine,
        coapplicant_city: validateCity,
        coapplicant_state: validateState,
        coapplicant_zip_code: validateZipcode,
        coapplicant_residential_status: validateResidentialStatus,
        coapplicant_residential_date: validatePastMonthAndYear,
        coapplicant_yearly_income: val => validateIntegerString(val, 9000),
        coapplicant_employment_status: validateEmploymentStatus,
        coapplicant_phone_number: validatePhone,
        relationship_to_primary: validateRelationshipToPrimary,
        coapplicant_ssn: validateSSN,
    };
    static USER_INFO_FORMATTER = {
        yearly_income: {
            formatted_value: formatPriceInput,
            raw_value: val => val.replace(/\D/g, ''),
        },
        phone_number: {
            formatted_value: formatPhoneNumber,
            raw_value: val => val.replace(/\D/g, '').substring(0, 10),
        },
        coapplicant_yearly_income: {
            formatted_value: formatPriceInput,
            raw_value: val => val.replace(/\D/g, ''),
        },
        coapplicant_phone_number: {
            formatted_value: formatPhoneNumber,
            raw_value: val => val.replace(/\D/g, '').substring(0, 10),
        }
    };

    userInfo = null;
    coapplicantFields = null;
    allowedContacts = null;
    contact_preference = null;
    partner_consent = false;

    constructor() {
        super();
        this._initUserInfo()
            ._initAllowedContacts()
            ._initCoapplicantFields();
    }

    /**
     * Initialize the userInfo object (setting all fields to null)
     */
    _initUserInfo() {
        this.userInfo = {};
        for (const field of this.constructor.USER_INFO) {
            this.userInfo[field] = null;
        }
        return this;
    }

    _initCoapplicantFields() {
        this.coapplicantFields = this.COAPPLICANT_USER_INFO
        return this;
    }

    /**
     * Update a single field in the userInfo object
     *
     * @param {string} field
     * @param {*} value
     */
    updateUserInfoField(field, value) {
        // make sure field is valid
        if (this.isValidUserInfoField(field)) {
            const formatter = this.constructor.USER_INFO_FORMATTER[field];
            // update the field in the user info object
            this.userInfo[field] = formatter ? formatter.raw_value(value || '') : value;
        }
        return this;
    }

    /**
     * Update multiple fields in the userInfo object
     *
     * @param {object} userInfo
     */
    updateUserInfoFields(userInfo) {
        // update each field
        for (const field of Object.keys(userInfo)) {
            this.updateUserInfoField(field, userInfo[field]);
        }
        return this;
    }

    /**
     * Returns userInfo fields passed (ignores any invalid field keys)
     *
     * @param {...string} [fields] - fields that should be returned
     *  userStore.getUserInfo(
     *      'first_name',
     *      'last_name',
     *      ...
     *  )
     *
     * @returns {object}
     */
    getUserInfo(...fields) {
        if (!fields.length) {
            fields = this.constructor.USER_INFO;
        }

        return fields.reduce((userInfo, field) => {
            if (this.isValidUserInfoField(field)) {
                userInfo[field] = this.userInfo[field];
            }
            return userInfo;
        }, {});
    }

    getCoapplicantFields() {
        return this.constructor.COAPPLICANT_USER_INFO;
    }

    /**
     * Returns true if userInfo fields passed are valid (ignores any invalid field keys)
     *
     * @param {...string|object} [fields] - fields that should be checked
     *  AS STRINGS:
     *      userStore.userInfoValuesValid(
     *          'first_name',
     *          'last_name',
     *          ...
     *      )
     *  AS OBJECT:
     *      userStore.userInfoValuesValid(
     *          {field: 'first_name', acceptNull: true},
     *          {field: 'last_name', acceptNull: true},
     *          ...
     *      )
     *
     * @returns {boolean}
     */
    isUserInfoValid(...fields) {
        if (!fields.length) {
            fields = this.constructor.USER_INFO;
        }

        for (const field of fields) {
            let _field = field;
            let _acceptNull = false;
            if (typeof field === 'object') {
                _field = field.field;
                _acceptNull = field.acceptNull;
            }

            // ignore fields that are not valid userInfo keys
            if (!this.isValidUserInfoField(_field)) {
                continue;
            }

            // ignore null fields if null values are accepted
            if (_acceptNull && this.userInfo[_field] === null) {
                continue;
            }

            // run validation function for field and return false if any field is invalid
            if (!this.constructor.USER_INFO_VALIDATIONS[_field](this.userInfo[_field])) {
                return false;
            }
        }
        return true;
    }

    /**
     * Returns true if field is a valid key in the userInfo object
     */
    isValidUserInfoField = field => this.constructor.USER_INFO.includes(field);

    /**
     * Initialize the allowedContacts object (setting all fields to null)
     */
    _initAllowedContacts() {
        this.allowedContacts = {};
        for (const allowedContact of this.constructor.ALLOWED_CONTACTS) {
            this.allowedContacts[allowedContact] = false;
        }
        return this;
    }

    /**
     * Update entry in allowedContacts
     *
     * @param {string} contact
     * @param {boolean} [isAllowed]
     */
    updateAllowedContact(contact, isAllowed = true) {
        // make sure contact is valid
        if (this.isValidAllowedContact(contact)) {
            this.allowedContacts[contact] = isAllowed;
        }
        return this;
    }

    /**
     * Returns true if field is a valid key in the allowedContacts object
     */
    isValidAllowedContact = contact => this.constructor.ALLOWED_CONTACTS.includes(contact);

    /**
     * Object of the user's contact preferences (for which only one key can be true and others are false)
     *  e.g. {
     *      contact_by_phone: <boolean>,
     *      contact_by_text: <boolean>,
     *      contact_by_email: <boolean>,
     *  }
     */
    get contactPreferences() {
        const contactPreferences = {};
        for (const contact_preference of Object.keys(CONTACT_PREFERENCES)) {
            contactPreferences[contact_preference] = (this.contact_preference === contact_preference);
        }
        return contactPreferences;
    }

    /**
     * Sets the user's contact_preference
     *
     * @param {string} contact_preference
     */
    setContactPreference(contact_preference) {
        // make sure contact_preference is valid
        if (this.isValidContactPreference(contact_preference)) {
            // update the contact preference
            this.contact_preference = contact_preference;
        }
        return this;
    }

    /**
     * Unsets the user's contact_preference
     */
    unsetContactPreference() {
        this.contact_preference = null;
        return this;
    }

    /**
     * Returns true if contact_preference is a valid contact_preference
     */
    isValidContactPreference = contact_preference => Object.keys(CONTACT_PREFERENCES).includes(contact_preference);

    setPartnerConsent(partnerConsent) {
        this.partner_consent = partnerConsent;
    }
}

decorate(UserStore, {
    userInfo: observable,
    allowedContacts: observable,
    contact_preference: observable,
    partner_consent: observable,

    contactPreferences: computed,

    updateUserInfoField: action,
    updateUserInfoFields: action,
    updateAllowedContact: action,
    setPartnerConsent: action,
    setContactPreference: action,
    unsetContactPreference: action,
});
