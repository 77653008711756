import {decorate, observable, action} from 'mobx';
import {BaseStore} from "./base-store";


/**
 * @class VehicleStore
 * @inheritDoc
 *
 *  Store for vehicle related data.
 *  The intent of this store is to provide vehicle information to any component regardless of the current Page.
 *
 * @property {object} vehicle_makes
 */
export class VehicleStore extends BaseStore{

    static MAKE_FIELDS = [
        'slug',
        'name',
    ];
    
    vehicle_makes = [];

    preRenderSetup(){
        if(this.rideOctaneStore.isUXServer){
            // 500 pages will not have apiStore and will throw an error on fetch
            if(!this.rideOctaneStore.isPage500){
                this.apiStore.onReady(() => {
                    this.fetchVehicles().then(() => {
                        super.preRenderSetup();
                    });
                })
                return this;
            }
        }
        return super.preRenderSetup();
    }

    fetchVehicles() {
        return Promise.all([
            this._getMakes(),
        ]).then(
            ([vehicleMakes]) => this._updateLists({vehicleMakes})
        );
    }

    _getMakes() {        
        let endpoint = 'makes';
        const query = {fields: ['name', 'slug']};

        const filterOutSlugless = (response) => {
            const vehicleMakes = response.filter(
                vehicle_make => vehicle_make.slug !== null
            );
            return vehicleMakes;
        };

        return this.apiStore.fetch(endpoint, {query: query}).then(
            result => filterOutSlugless(result.response.results)
        ).catch((error) => {
            console.error(error);
            console.error('Failed to fetch vehicle makes');
            return [];
        })
    }

    _updateLists({vehicleMakes}) {
        if (vehicleMakes) {
            this.vehicle_makes = vehicleMakes;
        }
        return this;
    };
}

decorate(VehicleStore, {
    vehicle_makes: observable,
    _updateLists: action,
});