import url from 'url';


export function stripTrailingSlash(uri) {
    if (uri.substr(-1) === '/') {
        return uri.slice(0, -1);
    }
    return uri;
}

export function stripLeadingSlash(uri) {
    if (uri[0] === '/') {
        return uri.slice(1);
    }
    return uri;
}

export function stripLeadingAndTrailingSlash(uri) {
    return stripLeadingSlash(stripTrailingSlash(uri));
}

/**
 * Returns the uri without the protocol and domain
 *
 * @param uri {string}
 * @returns {string}
 */
export function stripQueryParams(uri) {
    const index = uri.indexOf('?');
    if (index >= 0) {
        return uri.slice(0, index);
    }
    return uri;
}

/**
 * Returns the uri without the protocol and domain
 *  e.g. "http://www.rideoctane.com/a/b/c" => "/a/b/c"
 *
 * @param uri {string}
 * @returns {string}
 */
export function stripPortAndDomain(uri) {
    const {path} = url.parse(uri);
    return path || "";
}

/**
 * Returns the slugs in the uri
 *  e.g. "http://www.rideoctane.com/a/b/c" => ['a', 'b', 'c']
 *
 * @param uri {string}
 * @returns {array}
 */
export function getPathSegments(uri) {
    uri = stripQueryParams(uri);
    uri = stripPortAndDomain(uri);
    uri = stripLeadingAndTrailingSlash(uri);
    return uri ? uri.split('/') : [];
}

/**
 * Returns a specified uri segment
 *
 * @param uri {string}
 * @param at {string|number} - "first", "last" or an index number
 * @returns {string|null}
 */
export function getPathSegment(uri, at = "first") {
    const slugs = getPathSegments(uri);

    switch (at) {
        case "first":
            return slugs.shift() || null;
        case "last":
            return slugs.pop() || null;
        default:
            return slugs[at] || null;
    }
}