import React from 'react';
import classnames from 'classnames';
import ReactMarkdown from "react-markdown/with-html";


/**
 * Reusable component for rendering our basic icon + header + body text views.
 *
 * @param {string|object}   [className] - additional class added to wrapping element
 * @param {React.Component} icon - use component from src/app/components/svg/graphics
 * @param {string}          headline
 * @param {string}          bodyText - markdown string
 */
export function LayoutComponent({className, icon, headline, bodyText}) {
    return (
        <div className={classnames("w-315px mw-100 mx-auto text-center", className)}>
            <div className="spark-text-primary w-100px mx-auto mb-3">
                {icon}
            </div>
            <p className="spark-text-24px-bold mb-3">
                {headline}
            </p>
            <ReactMarkdown
                source={bodyText}
                className="spark-text-16px"
                renderers={{
                    paragraph: props => <p className="mb-2">{props.children}</p>,
                }}
            />
        </div>
    );
}
