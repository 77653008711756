import {BaseStore} from "./base-store";
import {decorate, computed, observable, action} from "mobx";


/**
 * @class PartnerStore
 *
 * Global Store for storing Partner related information
 *
 * @property {string} partnerIdentifier - identifier for the given partner, passed through url's query param
 * @property {string} vehicleIdentifier - identifier used by partner which maps to a vehicle configuration in our db
 * @property {number} partnerVehicleMatchId - id used to identify the partner page from which the customer came to our site
 * @property {object} partner - info about the partner (fetched in loadPartner)
 *   {
 *       name: {string},
 *       legal_entity_name: {string},
 *       dealer_routing_strategy: {string},
 *       contact_name: {string},
 *       contact_address: {string},
 *       contact_phone_number: {string},
 *       contact_email_address: {string},
 *       trade_in_url: {string},
 *       partner_url: {string},
 *       privacy_policy_url: {string},
 *       enable_dummy_vehicle: {bool},
 *       enable_dealer_decision: {bool},
 *       enable_dealer_selector: {bool},
 *       logo: {string},
 *       use_theme_for_nav_bg: {bool},
 *       show_nav: {bool},
 *       show_footer: {bool},
 *       show_transparent_offers: {bool},
 *       show_powered_by_octane: {bool},
 *       contact_preference_options: {array},
 *       about_you_header: {string},
 *       show_intercom_on_cmp: {bool},
 *       is_reveo: {bool},
 *       is_brp: {bool},
 *       is_rv: {bool},
 *       enable_coapplicant_dtc: {bool},
 *       show_drivers_license_request: {bool},
 *       enable_multidealership: {bool},
 *       show_about_you_consent_text: {bool},
 *       google_tag_id: {string},
 *       enable_alternate_lenders: {bool},
 *   }
 * @property {object} partnerVehicleMatch - info about the partner (fetched in loadPartnerVehicleMatch)
 *  {
 *      make:       {string},
 *      model:      {string},
 *      year:       {number},
 *      price:      {number},
 *      color:      {string},
 *      condition:  {string},
 *  }
 */
export class PartnerStore extends BaseStore {

    partner = null;
    partnerVehicleMatch = null;
    rawVehicle = null;
    partnerLeadId = null;
    partnerOriginUrl = null;
    productId = null;
    partnerTokenId = null;
    productLocation = null;

    get isReveo() {
        return !!(this.partner && this.partner.is_reveo);
    }

    get isRV() {
        return !!(this.partner && this.partner.is_rv);
    }

    get isEmbeddedPartner() {
        return !!(this.isRV || this.isReveo);
    }

    get hasMultiColumnDisplay() {
        let enableMultiColumn = false; //this var is used for VWO testing and will be removed after tests are complete
        if (typeof window !== "undefined") {
            enableMultiColumn = window.enableMultiColumn;
        }
        return !!(this.isEmbeddedPartner || enableMultiColumn);
    }

    get partnerIdentifier() {
        return this.historyStore.queryParams.partner || null;
    }

    get vehicleIdentifier() {
        if (!this.partnerIdentifier) {
            // ignore vehicle qp if there is no partner identifier
            return null;
        }
        return this.historyStore.queryParams.vehicle || null;
    }

    get partnerVehicleMatchId() {
        if (!this.partnerIdentifier) {
            // ignore partnerWidget qps if there is no partner identifier
            return null;
        }

        const idFromQueryParam = parseInt(this.historyStore.queryParams.partnerVehicleMatch);

        if (!isNaN(idFromQueryParam)) {
            return idFromQueryParam;
        } else if (this.partnerVehicleMatch && this.partnerVehicleMatch.id) {
            return this.partnerVehicleMatch.id;
        }
        return null;
    }

    get isCoapplicantEnabled() {
        return !!(this.partner && this.partner.enable_coapplicant_dtc);
    }

    get isMultiDealershipEnabled() {
        return !!(this.partner && this.partner.enable_multidealership);
    }

    get dealerRoutingStrategy() {
        if (this.partner && this.partner.dealer_routing_strategy){
            return this.partner.dealer_routing_strategy
        }
        return null;
    }

    preRenderSetup() {
         if (this.rideOctaneStore.isUXServer) {
             this.historyStore.onReady(() => {
                 this.apiStore.onReady(() => {
                     Promise.all([
                         this.loadPartner(),
                         this.loadPartnerVehicleMatchFromId(),
                     ]).then(() => {
                         this.isReady = true;
                     });
                 });
                 return this;
             });
             return this;
         }
        return super.preRenderSetup();
    }

    updateStore({partner, partnerVehicleMatch}) {
        this.partner = partner || this.partner;
        this.partnerVehicleMatch = partnerVehicleMatch || this.partnerVehicleMatch;
        return this;
    }

    /**
     * Loads a partner's info from the api server
     *  e.g.
     *   {
     *       name: {string},
     *       legal_entity_name: {string},
     *       dealer_routing_strategy: {string},
     *       contact_name: {string},
     *       contact_address: {string},
     *       contact_phone_number: {string},
     *       contact_email_address: {string},
     *       trade_in_url: {string},
     *       partner_url: {string},
     *       privacy_policy_url: {string},
     *       enable_dummy_vehicle: {bool},
     *       enable_dealer_decision: {bool},
     *       enable_dealer_selector: {bool},
     *       logo: {string},
     *       use_theme_for_nav_bg: {bool},
     *       show_nav: {bool},
     *       show_footer: {bool},
     *       show_transparent_offers: {bool},
     *       show_powered_by_octane: {bool},
     *       contact_preference_options: {array},
     *       about_you_header: {string},
     *       show_intercom_on_cmp: {bool},
     *       is_brp: {bool},
     *       is_reveo: {bool},
     *       is_rv: {bool},
     *       enable_coapplicant_dtc: {bool},
     *       show_drivers_license_request: {bool},
     *       enable_multidealership: {bool},
     *       show_about_you_consent_text: {bool},
     *       google_tag_id: {string},
     *       enable_alternate_lenders: {bool},
     *   }
     */
    loadPartner() {
        // if...we don't need to fetch the partner
        //  - this is not a partner experience
        if (!this.partnerIdentifier) {
            return Promise.resolve(this);
        }

        // partner endpoint
        const endpoint = `/partners/${this.partnerIdentifier}`;

        // fetch the partner
        return this.apiStore.fetch(endpoint)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.error('Failed to fetch partner', {endpoint, status, response});
                    return null;
                }
                return response;
            })
            .catch(error => {
                console.error('Failed to fetch partner', {endpoint, error});
                return null;
            })
            .then(partner => {
                if (!partner) {
                    return this;
                }
                return this.updateStore({partner});
            });
    }

    /**
    * Loads a partnerVehicleMatch's info from the api server
    *  e.g.
    *   {
    *       make:       {string},
    *       model:      {string},
    *       year:       {number},
    *       price:      {number},
    *       color:      {string},
    *       condition:  {string},
    *   }
    */
     loadPartnerVehicleMatchFromId() {
        if (!this.partnerVehicleMatchId) {
            return Promise.resolve(this);
        }

        // partner endpoint
        const endpoint = `/partners/partner_vehicle_match/${this.partnerVehicleMatchId}`;

        // fetch the partnerVehicleMatch
        return this.apiStore.fetch(endpoint)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.error('Failed to fetch partnerVehicleMatch', {endpoint, status, response});
                    return null;
                }
                return response;
            })
            .catch(error => {
                console.error('Failed to fetch partnerVehicleMatch', {endpoint, error});
                return null;
            })
            .then(partnerVehicleMatch => {
                if (!partnerVehicleMatch) {
                    return this;
                }
                return this.updateStore({partnerVehicleMatch});
            });
    }

    /**
    * Loads a partnerVehicleMatch's info from the api server
    *  e.g.
    *   {
    *       make:   {string},
    *       model:  {string},
    *       year:   {number},
    *       price:  {number},
    *       color:  {string},
    *   }
    */
    loadPartnerVehicleMatch(match_params) {
        if (!this.partnerIdentifier) {
            return Promise.resolve(this);
        }

        // partner match endpoint
        const endpoint = `/partners/partner_vehicle_match/match/`;
        const query = {
            make: match_params.make,
            model: match_params.model,
            year: match_params.year,
            price: match_params.price,
            color: match_params.color,
            condition: match_params.condition,
        };

        // fetch the partnerVehicleMatch
        return this.apiStore.fetch(endpoint, {query: query})
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.error('Failed to fetch partnerVehicleMatch', {endpoint, status, response});
                    return null;
                }
                return response;
            })
            .catch(error => {
                console.error('Failed to fetch partnerVehicleMatch', {endpoint, error});
                return null;
            })
            .then(partnerVehicleMatch => {
                if (!partnerVehicleMatch) {
                    return this;
                }
                return this.updateStore({partnerVehicleMatch});
            });
    }

    postPartnerVehicleMatch() {
        if (!this.rawVehicle) {
            return Promise.resolve(this);
        }

        // partner match endpoint
        const endpoint = `/partners/partner_vehicle_match/`;
        const options = {
            method: 'POST',
            data: this.rawVehicle,
        };

        // fetch the partnerVehicleMatch
        return this.apiStore.fetch(endpoint, options)
            .then(({status, response}) => {
                if (status !== 200) {
                    console.error('Failed to post partnerVehicleMatch', {endpoint, status});
                    return null;
                }
                return response;
            })
            .catch(error => {
                console.error('Failed to post partnerVehicleMatch', {endpoint, error});
                return null;
            })
            .then(partnerVehicleMatch => {
                if (!partnerVehicleMatch) {
                    return this;
                }
                return this.updateStore({partnerVehicleMatch});
            });
    }

    async getDealerSelectorEnabled(partnerId) {
        if (this.partner) {
            return this.partner.enable_dealer_selector;
        }
        const endpoint = `/partners/${partnerId}`;

        try {
            // fetch the partner
            const {response: partner} = await this.apiStore.fetch(endpoint);
            return partner.enable_dealer_selector;
        } catch (e) {
            console.error('Failed to fetch partner information', {
                error: e,
                endpoint,
                partnerId,
            });
        }
    }
}

decorate(PartnerStore, {
    partner: observable,
    partnerVehicleMatch: observable,
    partnerTokenId: observable,
    partnerVehicleMatchId: computed,
    isReveo: computed,
    isRV: computed,
    isEmbeddedPartner: computed,
    hasMultiColumnDisplay: computed,
    isCoapplicantEnabled: computed,
    isMultiDealershipEnabled: computed,

    getDealerSelectorEnabled: action,
});
