import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {formatPrice} from "../../utils/number-utils";
import {Link} from 'react-router-dom';


export function VehicleTile({name, slug, starting_price, primary_image, alt}) {
    const className = classnames(
        "border rounded",
        "h-100 p-2",
        "d-flex flex-column justify-content-between"
    );

    const tile = (
        <div className={className}>
            <div className="mb-3">
                <img
                    className="img-fluid d-block mx-auto mb-2"
                    src={primary_image}
                    alt={alt || name}
                />
                <h6 className="px-3">{name}</h6>
            </div>
            <div className="spark-bg-light mx-n2 mb-n2 px-3 py-2">
                <small className="d-block text-black-50">
                    starting at
                </small>
                <strong>
                    {formatPrice(starting_price, false)}
                </strong>
            </div>
        </div>
    );

    if (slug) {
        return (
            <Link to={slug} className="text-decoration-none text-dark">
                {tile}
            </Link>
        );
    }

    return tile;
}

VehicleTile.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string,
    starting_price: PropTypes.number.isRequired,
    primary_image: PropTypes.string.isRequired,
    alt: PropTypes.string,
};
