import React from 'react';
import {Helmet} from "react-helmet";


export const OG_TITLE = 'title';
export const OG_IMAGE = 'image';
export const OG_DESC = 'description';
export const OG_URL = 'url';


export const OgPropertyNames = [
    OG_TITLE, OG_IMAGE, OG_DESC, OG_URL,
];

export const OgMetaTag = ({propertyName, content}) => (
    <Helmet><meta property={`og:${propertyName}`} content={content} /></Helmet>
);


/**
 * Validates all of the fields in ogMetaData. 
 * If any fields are invalid a warning is logged to the console with those details.
 * Returns an array with the names of the valid fields from ogMetaData.
 */
export function validateOgMetaData ({ogMetaData}) {
    const skippedTagsWithReasons = {};
    // filter out invalid fields, and add any invalid fields into skippedTagsWithReasons with their reason for being invalid
    const validFields = Object.keys(ogMetaData).filter(propertyName => {
        // if propertyName is not a valid open graph field name, mark as invalid
        if (!OgPropertyNames.includes(propertyName)) {
            skippedTagsWithReasons[propertyName] = 'unknown property name';
            return false;
        }

        // if field's value is "falsy", then treat the field as invalid
        if (!ogMetaData[propertyName]) {
            skippedTagsWithReasons[propertyName] = `invalid value: "${ogMetaData[propertyName]}"`;
            return false;
        }
        return true;
    });

    // construct log a console.warn message if skippedTagsWithReasons has any invalid fields
    if (Object.keys(skippedTagsWithReasons).length > 0) {
        let warningMessage = `Skipping OgMetaTags: ${Object.keys(skippedTagsWithReasons)}\n`;
        warningMessage = warningMessage + 
            Object.keys(skippedTagsWithReasons).map(
                field =>`tag: "${field}", reason for skipping: ${skippedTagsWithReasons[field]}`
            ).join('\n') + '\n\n';
        console.warn(warningMessage, 'ogMetaData:', ogMetaData);
    }

    return validFields;
};


export const OpenGraphMetaTags = ({ogMetaData}) => {
    const validFields = validateOgMetaData({ogMetaData});
    return (
        <React.Fragment>
            {validFields.map((propertyName, key) => (
                <OgMetaTag key={key} propertyName={propertyName} content={ogMetaData[propertyName]} />
            ))}
        </React.Fragment>
    )
};
