import {observe, decorate, observable, computed, action, reaction} from 'mobx';
import {getPathSegments} from "../utils/url-utils";

import {BaseStore} from "./base-store";


/**
 * @class SlugsStore
 *
 * Global store which
 *  - parses the current url for the slug
 *  - fetches data for page based on slug
 *  - Retains the metadata associated with the current page's slug
 *
 * @property {string|null} slug
 * @property {object|null} slugData - data for slug (as returned from the api server)
 *  {
 *      vehicle_make:           {uuid, slug, name} || null,
 *      vehicle_type:           {uuid, slug, name} || null,
 *      vehicle_model:          {uuid, slug, name} || null,
 *      vehicle_sub_model:      {uuid, slug, name} || null,
 *      vehicle_trim:           {uuid, slug, name} || null,
 *      vehicle_configuration:  {uuid, slug, name} || null,
 *  }
 */
export class SlugsStore extends BaseStore {
    // slugs that should be ignored
    static SLUG_BLACK_LIST = [
        'favicon.ico',
        'how-it-works',
        'faq',
        'apply',
        'o',
        'paperwork',
    ];

    static isSlugBlacklisted(slug) {
        return this.SLUG_BLACK_LIST.includes(slug);
    }

    // state
    slug = null;
    pathSegments = [];
    slugData = null;

    get isUpdated() {
        const [slug = null, ...pathSegments] = getPathSegments(this.historyStore.pathname);
        return this.slug === slug && this.pathSegmentsMatch(pathSegments);
    }

    /**
     * Called on both the server and the client after setInitialState and is responsible
     * for checking the initial pathname and fetching the page's slugData if available.
     *
     * @returns {SlugsStore}
     */
    preRenderSetup() {
        // wait for the history store to setup and then check the current location
        this.historyStore.onReady(() => {
            this.onUrlChange().then(() => (this.isReady = true));
        });
        return this;
    }

    /**
     * Called only on the client after the page has rendered
     *
     * @returns {SlugsStore}
     */
    postRenderSetup() {
        return this.onReady(() => {
            // listen for changes to pathname in the history store
            observe(this.historyStore, 'pathname', () => this.onUrlChange());
        });
    }

    /**
     * Adds method to be invoked each time the slug changes
     *
     * @param {function} callback
     * @param {boolean} [callOnce]
     * @returns {function} dispose - function which removes the callback from the list of handlers and returns
     *                               true if it was removed and false if it was not (e.g. it was removed already)
     */
    onChange(callback, {callOnce = false} = {}) {
        const dispose = reaction(
            () => this.slug,
            change => {
                callback(change);
                if (callOnce) dispose();
            },
            {name: 'onChange slugsStore'}
        );
        return dispose;
    }

    /**
     * Adds method to be invoked each time a pathSegment changes for a given slug
     *
     * @param {function} callback
     * @param {boolean} [callOnce]
     * @param {boolean} [disposeOnChangeSlug] - should the listener be disposed when the slug changes
     * @returns {function} dispose - function which removes the callback from the list of handlers and returns
     *                               true if it was removed and false if it was not (e.g. it was removed already)
     */
    onChangePathSegments(callback, {callOnce = false, disposeOnChangeSlug = true} = {}) {
        let dispose;
        if (disposeOnChangeSlug) {
            // if the slug changes then don't invoke callback and dispose the reaction
            this.onChange(() => dispose(), {callOnce: true});
        }

        return dispose = reaction(
            () => this.pathSegments,
            change => {
                callback(change);
                if (callOnce) dispose();
            },
            {name: 'onChange slug pathSegments'}
        );
    }

    /**
     * Checks this.pathSegments and returns true if they match
     *
     * @param {array} pathSegments
     * @param {object} [options]
     * @param {boolean} [options.exact]
     */
    pathSegmentsMatch(pathSegments, options = {}) {
        const exact = (options.exact !== false);

        if (exact && (pathSegments.length !== this.pathSegments.length)) {
            return false;
        }
        if (pathSegments.length > this.pathSegments.length) {
            return false;
        }

        for (let i = 0; i < pathSegments.length; i++) {
            if (pathSegments[i] !== this.pathSegments[i]) {
                return false;
            }
        }

        return (
            (pathSegments.length === this.pathSegments.length) ||
            (!exact && (pathSegments.length <= this.pathSegments.length))
        );
    }

    /**
     * Invoked whenever the url changes.
     * Handles fetching data about the current location's pathname
     *
     * @returns Promise
     */
    onUrlChange() {
        const [slug = null, ...pathSegments] = getPathSegments(this.historyStore.pathname);

        // location has not changed
        if (this.slug === slug) {
            return Promise.resolve(this.updatePathSegments({pathSegments}));
        }

        // location has no slug or the slug is blacklisted
        if (!slug || SlugsStore.isSlugBlacklisted(slug)) {
            return Promise.resolve(this.updateStore({
                slug, pathSegments: (slug && pathSegments) || [], slugData: null,
            }));
        }

        // get the slugData for the given slug
        return this.fetchSlugData(slug)
            .then(slugData => this.updateStore({slug, pathSegments, slugData}));
    }

    /**
     * Api call to fetch data for the given slug
     *
     * @param {string|null} slug
     * @returns Promise
     */
    fetchSlugData(slug) {
        return this.apiStore.fetch(`slugs/${slug}`)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.warn(`Failed to fetch slug ${slug} (status=${status})`);
                    return null;
                }
                return response;
            })
            .catch(e => {
                console.warn(`Error occurred trying to fetch slug ${slug}`, e);
                return null;
            });
    }

    /**
     * Updates store observables
     *
     * @param {string|null} slug
     * @param {string|null} slugData
     * @param {array|null}  pathSegments
     * @returns {SlugsStore}
     */
    updateStore({slug, pathSegments, slugData}) {
        this.slug = slug;
        this.slugData = slugData;

        return this.updatePathSegments({pathSegments});
    }

    /**
     * Updates only the store's pathSegments observable
     *
     * @param {array}  pathSegments
     * @returns {SlugsStore}
     */
    updatePathSegments({pathSegments}) {
        if (!this.pathSegmentsMatch(pathSegments)) {
            this.pathSegments = pathSegments;
        }
        return this;
    }
}

decorate(SlugsStore, {
    slug: observable,
    pathSegments: observable,
    slugData: observable,

    isUpdated: computed,

    updateStore: action,
    updatePathSegments: action,
});
