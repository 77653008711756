import {BaseStore} from "./base-store";
import {decorate, computed, observable} from "mobx";


/**
 * @class DealershipStore
 *
 * Global Store for storing dealership information
 *
 * @property {string} dealershipId - identifier for the dealership, passed in as a query param
 * @property {string} externalDealershipId - identifier for a dealership external to Octane
 * @property {object} dealership - info about the dealership (retrieved in fetchDealership)
 *  {
 *      name: {string},
 *      dealership_identifier: {string},
 *      legal_entity_name: {string},
 *      contact_name: {string},
 *      contact_address: {string},
 *      contact_phone_number: {string},
 *      contact_email_address: {string},
 *      trade_in_url: {string},
 *      finance_url: {string},
 *      privacy_policy_url: {string},
 *  }
 */
export class DealershipStore extends BaseStore {

    dealership = null;

    get dealershipId() {
        return this.historyStore.queryParams.dealership || null;
    }

    /** 
     * Get the external_dealership_id if it exists in the query params.
     * This is an identifier for a dealership outside of Octane
     */
    get externalDealershipId() {
        return this.historyStore.queryParams.external_dealership_id || null;
    }

    preRenderSetup() {
        // if we are in the ux server, once the history and api stores are ready, fetch the dealership & then set dealership store as ready
        if (this.rideOctaneStore.isUXServer) {
            this.historyStore.onReady(() => {
                this.apiStore.onReady(() => {
                    this.fetchDealership().then(() => {
                        this.isReady = true;
                    });
                });
                return this;
            });
            return this;
        }
        return super.preRenderSetup();
    }

    updateStore({dealership}) {
        this.dealership = dealership || this.dealership;
        if (this.dealership && this.dealership.dealership_identifier !== this.historyStore.queryParams.dealership) {
            const newLocation = this.historyStore.currentLocation();
            const params = new URLSearchParams(newLocation.search);
            params.set('dealership', this.dealership.dealership_identifier);
            newLocation.search = params.toString();
            this.historyStore.replaceLocation(newLocation);
        }
        return this;
    }

    /**
     * If we have a dealership identifier set, fetch it's info & update the store with that response data.
     * i.e.
     *  {
     *      name: {string},
     *      dealership_identifier: {string},
     *      legal_entity_name: {string},
     *      contact_name: {string},
     *      contact_address: {string},
     *      contact_phone_number: {string},
     *      contact_email_address: {string},
     *      trade_in_url: {string},
     *      finance_url: {string},
     *      privacy_policy_url: {string},
     *  }
     */
    fetchDealership() {
        // Skip if we don't actually have a dealership identifier specified in the URL
        if (!this.dealershipId) {
            return Promise.resolve(this);
        }

        // dealership endpoint
        const endpoint = `/dealer/dealership/${this.dealershipId}`;

        // fetch the dealership
        return this.apiStore.fetch(endpoint)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.error('Failed to fetch dealership', {endpoint, status, response});
                    return null;
                }
                return response;
            })
            .catch(error => {
                console.error('Failed to fetch dealership', {endpoint, error});
                return null;
            })
            .then(dealership => {
                if (!dealership) {
                    return this;
                }
                return this.updateStore({dealership});
            });
    }

    fetchDealerGroupRouting(partnerIdentifier, location, url) {
        const endpoint = `/partners/${partnerIdentifier}/route-to-dealership/`;

        return this.apiStore.fetch(endpoint, {
            query: {
                location: location,
                url:url
            }
        }).then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.error('Failed to fetch dealership', {endpoint, status, response});
                    return null;
                }
                return response;
        }).catch(error => {
                console.error('Failed to fetch dealership', {endpoint, error});
                return null;
        }).then(dealership => {
                if (!dealership) {
                    return this;
                }
                return this.updateStore({dealership})
        });
    }

    /**
     * Looks up a given URL as being a trusted dealership/partner
     * @param {string|null} url
     * @return {Promise<boolean>}
     */
    isTrustedUrl(url) {
        return this.apiStore.fetch('/check_domain/', {
            query: {domain: url}
        }).then(({status, response}) => {
            if (status === 200) {
                return true;
            }
            if (status !== 406) {
                // 406 is expected for untrusted urls, otherwise there is an error
                console.error('Failed to check_domain', {url, status, response});
            }
            return false;
        }).catch(error => {
            console.error('Failed to check_domain', {url, error});
            return false;
        });
    }
}

decorate(DealershipStore, {
    dealership: observable,
    dealershipId: computed,
    externalDealershipId: computed,
});
