import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import throttle from 'lodash.throttle';

import styles from './fixed-bottom-bar.module.scss';


export class FixedBottomBar extends React.Component {
    componentDidMount() {
        this._wrapper = ReactDOM.findDOMNode(this);
        this._container = this._wrapper.getElementsByClassName(styles.container)[0];
        this._updateWrapperPosition();
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    _updateWrapperPosition() {
        const scrollPos = window.scrollY || window.pageYOffset;
        const wrapperRect = this._wrapper.getBoundingClientRect();
        const containerRect = this._container.getBoundingClientRect();
        this._wrapper.style = `height: ${containerRect.height}px`;
        this._wrapperTop = Math.max(0, scrollPos + wrapperRect.top);
        return this;
    }

    onResize = throttle(
        () => {
            this._updateWrapperPosition();
        },
        500, {leading: false, trailing: true}
    );

    render() {
        return (
            <div className={classnames(styles.wrapper, this.props.wrapperClass)}>
                <div className={classnames(styles.container, this.props.containerClass, "spark-bg-white spark-shadow-footer")}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

FixedBottomBar.propTypes = {
    wrapperClass: PropTypes.string,
    containerClass: PropTypes.string,
    children: PropTypes.node.isRequired,
};
