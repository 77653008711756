import React from "react";

export const emailSent = (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M34 27C34 26.4477 34.4477 26 35 26H57C57.5523 26 58 26.4477 58 27C58 27.5523 57.5523 28 57 28H35C34.4477 28 34 27.5523 34 27Z"/>
        <path
            d="M35 32C34.4477 32 34 32.4477 34 33C34 33.5523 34.4477 34 35 34H57C57.5523 34 58 33.5523 58 33C58 32.4477 57.5523 32 57 32H35Z"/>
        <path
            d="M35 38C34.4477 38 34 38.4477 34 39C34 39.5523 34.4477 40 35 40H57C57.5523 40 58 39.5523 58 39C58 38.4477 57.5523 38 57 38H35Z"/>
        <path
            d="M35 44C34.4477 44 34 44.4477 34 45C34 45.5523 34.4477 46 35 46H57C57.5523 46 58 45.5523 58 45C58 44.4477 57.5523 44 57 44H35Z"/>
        <path
            d="M68.9815 68.6447L73.8375 73.4998H58.7515C58.1995 73.4998 57.7515 73.9478 57.7515 74.4998C57.7515 75.0518 58.1995 75.4997 58.7515 75.4997H73.8375L68.9815 80.3548C68.5915 80.7458 68.5915 81.3787 68.9815 81.7697C69.3725 82.1597 70.0055 82.1597 70.3965 81.7697L76.9585 75.2067C77.0545 75.1118 77.1265 75.0018 77.1745 74.8838L77.1765 74.8807C77.2245 74.7637 77.2515 74.6348 77.2515 74.4998C77.2515 74.3648 77.2245 74.2358 77.1765 74.1188L77.1745 74.1157C77.1495 74.0547 77.1175 73.9948 77.0795 73.9388L77.0695 73.9238L77.0315 73.8738L77.0085 73.8458L76.9825 73.8178L76.9585 73.7928L70.3965 67.2298C70.0055 66.8398 69.3725 66.8398 68.9815 67.2298C68.5915 67.6208 68.5915 68.2537 68.9815 68.6447Z"/>
        <path
            fillRule="evenodd"
            d="M79 37.0034V60.0085C79.551 60.4458 80.0792 60.9162 80.5815 61.4185C84.0509 64.8879 86 69.5935 86 74.5C86 79.4065 84.0509 84.1121 80.5815 87.5815C77.1121 91.0509 72.4065 93 67.5 93C62.5935 93 57.8879 91.0509 54.4185 87.5815C51.7857 84.9487 50.0285 81.6041 49.334 78H17C14.7909 78 13 76.2091 13 74V37C13 36.7005 13.1338 36.4212 13.3574 36.2338L24 27.1775V18C24 16.3431 25.3431 15 27 15H38.3107L39.5759 13.9233C41.5306 12.049 43.962 11 46.5 11C49.041 11 51.4753 12.0516 53.4312 13.9301L54.6405 15L65 15C66.6569 15 68 16.3431 68 18V26.8184L78.6566 36.2457L79 37.0034ZM17 76H49.0609C49.0204 75.5029 49 75.0025 49 74.5C49 69.5935 50.9491 64.8879 54.4185 61.4185C55.3122 60.5249 56.2878 59.7321 57.3278 59.0475L52.0903 54.4142C52.0796 54.4048 52.0691 54.3951 52.0588 54.3852C50.4319 52.8168 48.4757 52 46.5 52C44.5243 52 42.568 52.8168 40.9411 54.3852C40.9263 54.3995 40.9109 54.4134 40.8952 54.4268L15.543 76H16.9944L17 76ZM55.7231 54.9577L59.1516 57.9907C61.7163 56.6939 64.5724 56 67.5 56C70.8739 56 74.1527 56.9216 77 58.6254V39L55.7231 54.9577ZM68 29.4887L76.4185 36.9361L68 43.25V29.4887ZM66 18V44.75L54.1937 53.6047L53.4311 52.9301C51.4753 51.0516 49.041 50 46.5 50C43.962 50 41.5306 51.049 39.5759 52.9233L38.3213 53.991L26 44.75V18C26 17.4477 26.4477 17 27 17H65C65.5523 17 66 17.4477 66 18ZM36.7597 55.3198L15 73.836V39L36.7597 55.3198ZM15.601 36.9507L24 43.25V29.8036L15.601 36.9507ZM51.6352 15C50.0978 13.6833 48.3071 13 46.5 13C44.6929 13 42.9022 13.6833 41.3648 15L51.6352 15ZM67.5 58C63.1239 58 58.9271 59.7384 55.8327 62.8327C52.7384 65.9271 51 70.1239 51 74.5C51 78.8761 52.7384 83.0729 55.8327 86.1673C58.9271 89.2616 63.1239 91 67.5 91C71.8761 91 76.0729 89.2616 79.1673 86.1673C82.2616 83.0729 84 78.8761 84 74.5C84 70.1239 82.2616 65.9271 79.1673 62.8327C76.0729 59.7384 71.8761 58 67.5 58Z"/>
    </svg>
);
