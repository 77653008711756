import React from 'react';

export const uploadFailed = (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28.9941 26.9994H43.9941C44.5461 26.9994 44.9941 26.5514 44.9941 25.9994C44.9941 25.4474 44.5461 24.9994 43.9941 24.9994H28.9941C28.4421 24.9994 27.9941 25.4474 27.9941 25.9994C27.9941 26.5514 28.4421 26.9994 28.9941 26.9994Z"/>
        <path
            d="M68.9941 41.9994H28.9941C28.4421 41.9994 27.9941 41.5514 27.9941 40.9994C27.9941 40.4474 28.4421 39.9994 28.9941 39.9994H68.9941C69.5461 39.9994 69.9941 40.4474 69.9941 40.9994C69.9941 41.5514 69.5461 41.9994 68.9941 41.9994Z"/>
        <path
            d="M28.9941 49.9994H68.9941C69.5461 49.9994 69.9941 49.5514 69.9941 48.9994C69.9941 48.4474 69.5461 47.9994 68.9941 47.9994H28.9941C28.4421 47.9994 27.9941 48.4474 27.9941 48.9994C27.9941 49.5514 28.4421 49.9994 28.9941 49.9994Z"/>
        <path
            d="M68.9941 57.9994H28.9941C28.4421 57.9994 27.9941 57.5514 27.9941 56.9994C27.9941 56.4474 28.4421 55.9994 28.9941 55.9994H68.9941C69.5461 55.9994 69.9941 56.4474 69.9941 56.9994C69.9941 57.5514 69.5461 57.9994 68.9941 57.9994Z"/>
        <path
            fillRule="evenodd"
            d="M27.9941 64.9994C27.9941 64.4474 28.4421 63.9994 28.9941 63.9994H44.9941C45.5461 63.9994 45.9941 64.4474 45.9941 64.9994V74.9994C45.9941 75.5514 45.5461 75.9994 44.9941 75.9994H28.9941C28.4421 75.9994 27.9941 75.5514 27.9941 74.9994V64.9994ZM29.9941 73.9994V65.9994H43.9941V73.9994H29.9941Z"/>
        <path
            fillRule="evenodd"
            d="M24.5271 84.9994H62.0201C64.4931 91.9874 71.1631 96.9994 78.9941 96.9994C88.9281 96.9994 96.9941 88.9334 96.9941 78.9994C96.9941 69.3774 89.4291 61.5094 79.9271 61.0234V34.2764C79.9271 32.8094 79.3451 31.4034 78.3081 30.3654L64.5611 16.6184C63.5241 15.5824 62.1171 14.9994 60.6511 14.9994H24.5271C23.0601 14.9994 21.6521 15.5824 20.6151 16.6204C19.5771 17.6574 18.9941 19.0654 18.9941 20.5324V79.4664C18.9941 80.9334 19.5771 82.3414 20.6151 83.3784C21.6521 84.4164 23.0601 84.9994 24.5271 84.9994ZM94.9941 78.9994C94.9941 74.9451 93.4831 71.2411 90.994 68.4203L80.4142 79L90.9934 89.5792C93.4829 86.7583 94.9941 83.054 94.9941 78.9994ZM89.5795 90.9937L79 80.4142L68.415 90.9992C71.2358 93.4884 74.9398 94.9994 78.9941 94.9994C83.0514 94.9994 86.7579 93.4862 89.5795 90.9937ZM77.5858 79L67.0004 89.5854C64.5076 86.7637 62.9941 83.057 62.9941 78.9994C62.9941 74.9421 64.5074 71.2356 66.9998 68.4141L77.5858 79ZM79 77.5858L68.4144 67.0002C71.2353 64.5107 74.9395 62.9994 78.9941 62.9994C83.0518 62.9994 86.7585 64.5129 89.5802 67.0057L79 77.5858ZM77.9271 34.9994V61.0304C68.4891 61.5824 60.9941 69.4224 60.9941 78.9994C60.9941 80.3744 61.1481 81.7134 61.4411 82.9994H24.5271C23.5901 82.9994 22.6921 82.6274 22.0291 81.9644C21.3661 81.3014 20.9941 80.4034 20.9941 79.4664V20.5324C20.9941 19.5954 21.3661 18.6974 22.0291 18.0344C22.6921 17.3714 23.5901 16.9994 24.5271 16.9994H59.4941V30.3994C59.4941 31.6194 59.9791 32.7894 60.8411 33.6524C61.7041 34.5144 62.8741 34.9994 64.0941 34.9994H77.9271ZM61.4941 30.3994V17.1014C62.1151 17.2544 62.6881 17.5744 63.1471 18.0334L76.8931 31.7794C77.2441 32.1304 77.5121 32.5464 77.6881 32.9994H64.0941C63.4041 32.9994 62.7431 32.7254 62.2561 32.2374C61.7681 31.7504 61.4941 31.0894 61.4941 30.3994Z"/>
    </svg>
);
