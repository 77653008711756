import React from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';

export function SpecDetails({details, isVehicleSubmodelPage}) {
    let specDetailsClasses = "font-weight-bold mb-4";
    if(isVehicleSubmodelPage){
        specDetailsClasses = "font-weight-bold mb-4 d-none d-lg-block"
    }
    return (
        <React.Fragment>
            <Col xs={12}>
                <h4 className={specDetailsClasses}>Specs Overview</h4>
            </Col>
            <AllSpecs details={details}/>
        </React.Fragment>
    );
}

SpecDetails.propTypes = {
    details: PropTypes.object.isRequired,
};

export function AllSpecs({details}) {
    return (
        <Col xs={{span: 12, order: 1}} lg={{span: 5, order: 0}}>
            <div>
                {Object.keys(details).map(key => {
                    if (!shouldRenderSpecCategory(key)) {
                        return null;
                    }
                    const innerValues = Object.keys(details[key]).map(innerKey => {
                        if (!innerKey) {
                            return null;
                        } else if (!shouldRenderSpec(key, innerKey)) {
                            return null; 
                        }

                        return (
                            <Row key={innerKey}>
                                <Col>{innerKey}</Col>
                                <Col>{details[key][innerKey]}</Col>
                            </Row>
                        );
                    }).filter((innerValue) => (innerValue !== null));

                    if (innerValues.length > 0) {
                        return (
                            <div key={key}>
                                <p className="font-weight-bold">{key}</p>
                                <div className="spark-text-secondary">{innerValues}</div>
                                <hr/>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </div>
        </Col>
    );
}

AllSpecs.propTypes = {
    details: PropTypes.object.isRequired,
};

/**
 * Returns true if we should render the given spec category, false otherwise
 * @param categoryName
 * @returns {boolean}
 */
function shouldRenderSpecCategory(categoryName) {
    return !!SPEC_WHITELIST[categoryName];
}

/**
 * Returns true if we should render the given specification, false otherwise
 * @param categoryName
 * @param specName
 * @returns {boolean}
 */
function shouldRenderSpec(categoryName, specName) {
   return SPEC_WHITELIST[categoryName].indexOf(specName) !== -1;
}

/**
 * Object that defines a whitelist of specifications we should render.
 */
const SPEC_WHITELIST = {
    'Paint': [
        'Metallic', 'Primary Color', 'Secondary Color',],
    'Tires': [
        'Tire Brand', 'Tread Type', 'Rear Tire (Full Spec)', 'Front Tire (Full Spec)',],
    'Brakes': [
        'Rear Brake Type', 'Front Brake Type',],
    'Engine': [
        'Valves', 'Cooling', 'Starter', 'Bore (mm)', 'Cylinders', 'Fuel Type', 'Engine Type', 'Stroke (mm)', 'Engine Stroke', 'Compression Ratio', 'Displacement (cc)', 'Fuel Requirements', 'Valve Configuration', 'Valve Per Cylinder', 'Supercharged', 'Turbocharged', 'Horsepower (bhp)', 'Torque (Ft Lbs)', 'Engine Brand Name',],
    'Weight': [
        'Dry Weight (kg)', 'Dry Weight (lbs)', 'Wet Weight (kg)', 'Wet Weight (lbs)', 'Towing Capacity (kgs)', 'Towing Capacity (lbs)',],
    'Wheels': [
        'Wheels Composition', 'Rear Tire Diameter (in)', 'Front Tire Diameter (in)',],
    'Steering': [
        'Steering Type', 'Steering Control',],
    'Cargo Bed': [
        'Cargo Bed Tilt', 'Bed Capacity (kgs)', 'Bed Capacity (lbs)', 'Integrate tie-down hooks', 'Bed Width (in)', 'Bed Height (in)', 'Bed Length (in)',],
    'Cupholder': [
        'Number of Cupholders',],
    'Driveline': [
        'Driveline Type', 'Rear solid axle', 'Number of Driveline Modes',],
    'Capacities': [
        'Fuel Capacity (l)', 'Fuel Capacity (gal)', 'Fuel Capacity Reserve (l)', 'Fuel Capacity Reserve (gal)', 'Storage Capacity (gal)',],
    'Dimensions': [
        'Width (in)', 'Height (in)', 'Length (in)', 'Wheelbase (in)', 'Turning Radius (ft)', 'Ground Clearance (in)',],
    'Rear Hitch': [
        'Size (in)', 'Rear Hitch Type',],
    'Skid Plate': [
        'Bash Plate (Rear)', 'Swing Arm Skid Plate', 'Drive Shaft Skid Plate', 'Bash Plate (Front)', 'Full Length Skid Plate', 'A-Arm Skid Plate (Rear)', 'A-Arm Skid Plate (Front)',],
    'Carburetion': [
        'Carburetion Type',],
    'Construction': [
        'Frame', 'Body Material',],
    'Headlight(s)': [
        'Type',],
    'Transmission': [
        'Reverse', 'Hi / Low Range', 'Number of Speeds', 'Transmission Type', 'Primary Drive (Rear Wheel)', 'Primary Drive (Front Wheel)',],
    'Rear Suspension': [
        'Air Adjustable', 'Adjustment Type', 'Rear Travel (in)', 'Rear Stablizer Bar', 'Rear Suspension Type', 'Rear Suspension Brand Name', 'Rear Adjustable Shock / Spring Pre-Load',],
    'Front Suspension': [
        'Front Travel (in)', 'Front Suspension Type', 'Front Suspension Brand Name',],
    'Differential Lock': [
        'Differential Lock Location',],
    'Seat Specifications': [
        'Number of rows', 'Number of seats', 'seat height (in)',],
    'Engine Immobilizer': [
        'Engine Immobilizer brand',],
    'Limited Slip Differential': [
        'Limited Slip Differential Location',],
};
