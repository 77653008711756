import {decorate, observable, computed, action} from 'mobx';

import {BaseVehicleListingPageStore} from "./base/base-vehicle-listing-page-store";
import {PageTitles} from "../../pages/octane-title";
import {UUIDMap} from "../../utils/uuid-map";


/**
 * @class ModelPageStore
 * @inheritDoc
 *
 * Page store for model pages
 *
 * @property {object} vehicle_make
 * @property {object} vehicle_type
 * @property {object} vehicle_model
 *
 * @property {object} default_vehicle_sub_model
 * @property {object} vehicles_sub_models       {map: <object>, uuids: <array>}
 *
 * @property {object} default_vehicle_configuration
 * @property {object} vehicle_configurations    {map: <object>, uuids: <array>}
 *
 * @property {object} vehicleSubmodels      props passed to VehicleModelSubmodels component
 */
export class ModelPageStore extends BaseVehicleListingPageStore {
    vehicle_model = null;
    vehicle_sub_models = null;
    make_is_financeable = null;
    default_vehicle_sub_model = null;
    default_vehicle_configuration = null;

    static MAKE_FIELDS = [
        'is_financeable',
    ];

    static MODEL_FIELDS = [
        'uuid',
        'slug',
        'name',
        'default_image',
        'default_vehicle_sub_model',
        'default_vehicle_configuration',
        'spec_highlights',
        'live_years',
    ];

    static SUBMODELS_FIELDS = [
        'uuid',
        'slug',
        'name',
        'default_image',
        'default_vehicle_configuration',
    ];

    static CONFIGURATIONS_FIELDS = [
        'name',
        'uuid',
        'slug',
        'msrp',
        'default_image',
    ];

    static CONFIGURATIONS_DETAILS_FIELD = [
        'details',
    ];

    getPrimaryVehicleObject() {
        return this.vehicle_model;
    }

    get vehicle_make() {
        return this.listingsStore.slugsStore.slugData.vehicle_make;
    }

    get vehicle_type() {
        return this.listingsStore.slugsStore.slugData.vehicle_type;
    }

    get logSummary() {
        return `Vehicle Model uuid: ${this.vehicle_model.uuid} slug: ${this.vehicle_model.slug}`
    }

    fetchInitialStoreData() {
        const {apiStore, slugsStore} = this.listingsStore;
        const makeUuid = slugsStore.slugData.vehicle_make.uuid;
        const uuid = slugsStore.slugData.vehicle_model.uuid;

        // get the year passed in the slug
        const vehicleYear = slugsStore.pathSegments.length > 0 ? parseInt(slugsStore.pathSegments[0]) : null;

        if (isNaN(vehicleYear)) {
            return Promise.all([{status:404}]);
        }

        const makeOptions = {
            query: {
                fields: ModelPageStore.MAKE_FIELDS,
            },
        };
        const modelOptions = {
            query: {
                fields: ModelPageStore.MODEL_FIELDS,
                vehicle_year: (vehicleYear == null)? 'default' : vehicleYear,
            },
        };
        const submodelsOptions = {
            query: {
                vehicle_models: [uuid],
                fields: ModelPageStore.SUBMODELS_FIELDS,
            },
        };

        // FETCH MAKE
        const makePromise = apiStore.fetch(`makes/${makeUuid}`, makeOptions)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.warn(`Failed to fetch make with uuid ${uuid} (status=${status})`);
                    return {status: 404};
                }
                return {status: 200, data: {vehicle_make: response}};
            })
            .catch(e => {
                console.warn(`Error occurred trying to fetch make with uuid=${uuid}`, e);
                return {status: 500};
            });

        // FETCH MODEL
        const modelPromise = apiStore.fetch(`models/${uuid}`, modelOptions)
            .then(({status, response}) => {
                return {status, response};
            })
            .catch(e => {
                console.warn(`Error occurred trying to fetch model with uuid=${uuid}`, e);
                return {status: 500};
            });

        // FETCH SUBMODELS
        const submodelsPromise = (data) => {
            return apiStore.fetch('submodels', submodelsOptions)
            .then(({status, response}) => {
                return {status, response, ...data};
            })
            .catch(e => {
                console.warn(`Error occurred trying to fetch submodels for model with uuid=${uuid} `, e);
                return {status: 500};
            });
        }

        // FETCH CONFIGURATIONS
        const configurationsPromise = (vehicle_configuration_uuids, data) => {
            const configurationsOptions = {
                query: {
                    vehicle_configurations: vehicle_configuration_uuids,
                    fields: ModelPageStore.CONFIGURATIONS_FIELDS,
                },
            };
            return apiStore.fetch('configurations', configurationsOptions)
            .then(({status, response}) => {
                return {status, response, vehicle_configuration_uuids, ...data};
            })
            .catch(e => {
                console.warn(`Error occurred trying to fetch configurations for model. Configuration uuids: ${vehicle_configuration_uuids}`, e);
                return {status: 500};
            });
        };

        // FETCH DEFAULT CONFIGURATION DETAILS
        const detailsPromise = (vehicle_configuration_uuid, data) => {
            const configurationDetailOptions = {
                query: {
                    fields: ModelPageStore.CONFIGURATIONS_DETAILS_FIELD,
                },
            };
            return apiStore.fetch(`configurations/${vehicle_configuration_uuid}`, configurationDetailOptions)
            .then(({status, response}) => {
                return {status, response, ...data};
            })
            .catch(e => {
                console.warn(`Error occurred trying to fetch details for configuration. Configuration uuid: ${vehicle_configuration_uuid}`, e);
                return {status: 500};
            });
        };

        const chainedPromise = modelPromise.then(({status, response}) => {
            if (status !== 200 || !response) {
                console.warn(`Failed to fetch model with uuid ${uuid} (status=${status})`);
                return {status: 404};
            }

            const vehicle_model = response;
            const default_vehicle_sub_model_uuid = !!vehicle_model ? vehicle_model.default_vehicle_sub_model : null;
            const default_vehicle_configuration_uuid_from_model = !!vehicle_model ? vehicle_model.default_vehicle_configuration : null;
            return submodelsPromise({vehicle_model, default_vehicle_sub_model_uuid, default_vehicle_configuration_uuid_from_model})
        }).then(({status, response, vehicle_model, default_vehicle_sub_model_uuid, default_vehicle_configuration_uuid_from_model}) => {
            if (status !== 200 || !response || !response.results) {
                console.warn(`Failed to fetch submodels for model with uuid=${uuid} (status=${status})`);
                return {status: 404};
            }

            const vehicle_sub_models = response.results;
            const default_vehicle_sub_model = vehicle_sub_models.find( ({ uuid }) => uuid === default_vehicle_sub_model_uuid );
            const vehicle_configuration_uuids = vehicle_sub_models.map((vehicle_sub_model) => vehicle_sub_model.default_vehicle_configuration);
            const default_vehicle_configuration_uuid = !!default_vehicle_sub_model ? default_vehicle_sub_model.default_vehicle_configuration : default_vehicle_configuration_uuid_from_model;
            return configurationsPromise(vehicle_configuration_uuids,{vehicle_model, default_vehicle_configuration_uuid, vehicle_sub_models})
        }).then(({status, response, vehicle_model, default_vehicle_configuration_uuid, vehicle_sub_models, vehicle_configuration_uuids}) => {
            if (status !== 200 || !response || !response.results) {
                console.warn(`Failed to fetch configurations for model (status=${status}). Configuration uuids: ${vehicle_configuration_uuids}`);
                return {status: 404};
            }

            const vehicle_configurations = response.results;
            return detailsPromise(default_vehicle_configuration_uuid, {vehicle_model,  vehicle_sub_models, default_vehicle_configuration_uuid})
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.warn(`Failed to fetch details for configuration (status=${status}). Configuration uuid: ${default_vehicle_configuration_uuid}`);
                    return {status: 404};
                }
                return {status: 200, data: {vehicle_model, vehicle_sub_models, vehicle_configurations, default_vehicle_configuration_uuid, default_vehicle_configuration_details: response.details}};
            })
        })

        return Promise.all([makePromise, chainedPromise]);
    }

    parseInitialRawData({vehicle_make, vehicle_model, vehicle_sub_models, vehicle_configurations, default_vehicle_configuration_uuid, default_vehicle_configuration_details}) {
        const configs = new UUIDMap({dataArray: vehicle_configurations});
        vehicle_sub_models.forEach(entry => {
            // set default submodel
            if (entry.uuid === vehicle_model.default_vehicle_sub_model) {
                this.default_vehicle_sub_model = entry;
            }

            // set default config with details
            entry.default_vehicle_configuration_obj = entry.default_vehicle_configuration ? configs.getObject(entry.default_vehicle_configuration) : null;
            if (entry.default_vehicle_configuration === default_vehicle_configuration_uuid) { // default config of model, get details
                entry.default_vehicle_configuration_obj.details = default_vehicle_configuration_details;
                this.default_vehicle_configuration = entry.default_vehicle_configuration_obj;
            }
        });

        this.make_is_financeable = vehicle_make.is_financeable;
        this.vehicle_model = vehicle_model;
        this.vehicle_sub_models = new UUIDMap({dataArray: vehicle_sub_models});

        // set initial carousel image to the model's default image
        this.carouselImage = {
            alt: this._vehicleName(),
            image: this._vehicleImage(),
        };

        return this;
    }

    _vehicleName() {
        return [
            this.vehicle_make.name,
            this.vehicle_model.name,
        ].join(' ');
    }

    _vehicleImage() {
        return (!!this.default_vehicle_configuration && !!this.default_vehicle_configuration.default_image) ?
                this.default_vehicle_configuration.default_image : this.vehicle_model.default_image;
    }

    _pageTitle() {
        return this._vehicleName();
    }

    _pageTitleWords() {
        const vehicleMakeName = !!this.vehicle_make && this.vehicle_make.name ? this.vehicle_make.name : '';
        const vehicleModelName = !!this.vehicle_model && this.vehicle_model.name ? this.vehicle_model.name : '';
        if (!!vehicleMakeName && !!vehicleModelName) {
            const vehicleTitle = `${vehicleMakeName} ${vehicleModelName} ${PageTitles.MODEL_AND_SUBMODEL_TITLE}`;
            return [vehicleTitle, PageTitles.OCTANE_TITLE];
        }
        return [];
    }

    _breadcrumbs() {
        return [
            {name: "Vehicles"},
            {name: this.vehicle_type.name + 's'},
            {name: this.vehicle_make.name},
            {name: this.vehicle_model.name},
        ];
    }

    _carouselImages() {
        // todo: eventually there should be a list of other images as well
        return [{...this.carouselImage}];
    }

    /**
     * Computed observable which builds props passed to VehicleModelSubmodels
     */
    get vehicleSubmodels() {
        return {
            vehicle_make: {
                name: this.vehicle_make.name,
            },
            vehicle_model: {
                name: this.vehicle_model.name,
            },
            vehicle_sub_models: this.vehicle_sub_models.getObjects().map(vehicle_sub_model => {
                const default_vehicle_configuration_uuid = !!vehicle_sub_model && !!vehicle_sub_model.default_vehicle_configuration
                    ? vehicle_sub_model.default_vehicle_configuration : null;
                const default_vehicle_configuration = !!default_vehicle_configuration_uuid ? vehicle_sub_model.default_vehicle_configuration_obj : null;

                return {
                    uuid: vehicle_sub_model.uuid,
                    slug: vehicle_sub_model.slug,
                    name: vehicle_sub_model.name,
                    default_image: !!default_vehicle_configuration ? default_vehicle_configuration.default_image : vehicle_sub_model.default_image,
                    default_vehicle_configuration: !!default_vehicle_configuration ? {
                        uuid: default_vehicle_configuration.uuid,
                        slug: default_vehicle_configuration.slug,
                        msrp: default_vehicle_configuration.msrp,
                    } : null,
                };
            }).filter(vehicle_sub_model => {
                // hide submodels with no slug because they will not have a url,
                // so they cannot be selected
                if(!vehicle_sub_model.slug) {
                    console.error(`Vehicle submodel with no slug. uuid: ${vehicle_sub_model.uuid} name: ${vehicle_sub_model.name}`);
                }
                return !!(vehicle_sub_model.slug)
            }),
        };
    }
}

decorate(ModelPageStore, {
    vehicle_model: observable,
    vehicle_sub_models: observable,
    default_vehicle_sub_model: observable,
    default_vehicle_configuration: observable,

    vehicle_make: computed,
    vehicle_type: computed,
    vehicleSubmodels: computed,

    parseInitialRawData: action,
});
