import React from 'react';
import template from 'lodash.template';
import {inject, observer} from 'mobx-react';
import {withRouter} from "react-router";

import {renderUrl} from "../../pages/url-renderer";

import {
    OG_DESC, OG_IMAGE, OG_TITLE, OG_URL, OpenGraphMetaTags,
} from "../../components/open-graph";

/**
 * Renders the <meta property="og:..." ... /> tags for a vehicle listings page
 */
export const VehicleListingOgTags = inject('rideOctaneStore')(observer(withRouter(
    ({rideOctaneStore, vehicleName, copy, imageUrl, ...props}) => {
        const desc = template(copy.meta_description)({vehicleName}) || "";
        const ogMetaData = {};
        const url = renderUrl(
            [
                rideOctaneStore.settings.BASE_UX_URL,
                props.location.pathname
            ].join('/')
        );

        ogMetaData[OG_TITLE] = vehicleName;
        ogMetaData[OG_DESC] = desc;
        ogMetaData[OG_IMAGE] = imageUrl;
        ogMetaData[OG_URL] = url;

        return (
            <OpenGraphMetaTags ogMetaData={ogMetaData} />
        );
    }
)));
