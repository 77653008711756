import React, { useRef } from 'react';
import classnames from 'classnames';

import {Form, FormControl, Row, Col} from 'react-bootstrap';
import PropTypes from "prop-types";
import styles from './input.module.scss';
import { InputToolTip } from './tool-tips';

/**
 * Reusable component for rendering our inputs
 * @param {string}                 value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. If string, contains the error message
 * @param {function}               onChange - onChange event handler. When autoValidateAndFormat=true, returns object with value and error properties
 * @param {function}               onBlur - onBlur event handler. When autoValidateAndFormat=true, returns object with value and error properties
 * @param {boolean}                autoValidateAndFormat - whether or not the input should apply automatic validation and formatting
 * @param {boolean}                required - check if the input is required. If required=true, events returns error=true when no value is given
 * @param {function}               validator - validator to check if the input is valid. Then the input doesn't pass validation, events returns error=true
 * @param {function}               formatter - function to format the input value
 * @param {function}               unformatter - function to unformat the input value
 */
export const Input = ({
    value,
    error,
    onChange,
    onBlur,
    autoValidateAndFormat = false,
    validator,
    required = false,
    formatter,
    unformatter,
    ...props
}) => {
    const hasHadFirstBlur = useRef(false);

    const isEmpty = (value) => ["", null, undefined].includes(value);
    const hasValidationError = (value) => !isEmpty(value) && !!validator && !validator(value);
    const hasRequiredError = (value) => isEmpty(value) && required;
    const hasError = (value) => autoValidateAndFormat &&  (hasRequiredError(value) || hasValidationError(value));

    const format = (value) => autoValidateAndFormat && formatter ? formatter(value) : value;
    const unformat = (value) => autoValidateAndFormat && unformatter ? unformatter(value) : value;

    const validationEventWrapper = (event, originalEventHandler) => {
        if(event && event.type === 'blur' && !hasHadFirstBlur.current) {
            hasHadFirstBlur.current = true;
        }

        if (!originalEventHandler) return;

        /* When autoValidateAndFormat is false, defaults to old behavior */
        if(!autoValidateAndFormat){
            originalEventHandler(event);
        } else {
            const unformatted = unformat(event.target.value);
            originalEventHandler({
                value: unformatted,
                error: hasHadFirstBlur.current && hasError(unformatted),
            })
        }
    }

    const className = classnames(props.className);

    const inputProps = {
        type: props.type,
        disabled: props.disabled,
        placeholder: props.placeholder,
        inputMode: props.inputMode,
        onChange: (event) => validationEventWrapper(event, onChange),
        onBlur:  (event) => validationEventWrapper(event, onBlur),
        onClick: props.onClick,
        onKeyUp: props.onKeyUp,
    }

    const formControlProps = {
        isInvalid: !!error,
        autoCapitalize: props.autocapitalize,
    };

    // value and defaultValue are mutually exclusive
    if (![null, void 0].includes(value)) {
        inputProps.value = format(value);
    } else if (![null, void 0].includes(props.defaultValue)) {
        inputProps.defaultValue = format(props.defaultValue);
    }

    const InputLabel = ({labelClassName = ""}) =>
        props.label ? (
            <Form.Label className={labelClassName}>{props.label}</Form.Label>
        ) : null;

    const InfoIcon = () =>
        props.toolTipText && props.toolTipMsg ? (
            <InputToolTip tooltipText={props.toolTipText} tooltipMsg={props.toolTipMsg} />
        ) : null;

    const ErrorMessage = () =>
        typeof error !== "boolean" ? (
            <FormControl.Feedback type="invalid">
                {error}
            </FormControl.Feedback>
        ) : null;
    ;

    /* If this is a checkbox, return a custom styled checkbox input */
    if (props.type === "checkbox") {
        return (
            <Form.Group controlId={props.controlId} className={classnames("d-flex align-items-center", className)}>
                <div className="d-inline-block">
                    <input
                        {...inputProps}
                        className={classnames("border", styles.checkbox)}
                        checked={props.checked}
                        id={props.controlId}
                    />

                    {error && <ErrorMessage />}
                </div>

                <InputLabel labelClassName="ml-2 align-top" />
            </Form.Group>
        );
    }

    /* Default return for inputs that aren't checkboxes */
    return (
        <Form.Group controlId={props.controlId} className={className}>
                {/* Label */}
                <Row>
                    <Col>
                        <InputLabel />
                    </Col>
                    
                    <Col>
                        <InfoIcon />
                    </Col>
                    
                </Row>

                {/* Input */}
                <Form.Control {...formControlProps} {...inputProps} />

                <ErrorMessage />
        </Form.Group>
    );
}

Input.propTypes = {
    autoValidateAndFormat: PropTypes.bool,
    required: PropTypes.bool,
    validator: PropTypes.func,
    formatter: PropTypes.func,
    unformatter: PropTypes.func,

    className: PropTypes.string,
    controlId: PropTypes.string, // Sets id on <FormControl> and htmlFor on <FormGroup.Label>. Used for checkbox input id.
    type: PropTypes.oneOf(["text", "number", "tel", "checkbox", "password"]),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    inputMode: PropTypes.string,
    autocapitalize: PropTypes.string,
    checked: PropTypes.bool,

    // handlers
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onKeyUp: PropTypes.func,
};
