import React from "react";
import PropTypes from "prop-types";

import { Select } from "octane-spark";
import copy from "../copy.json";

export const YearsForUsed = ({
    value,
    fromYear,
    numberYears,
    disabled,
    onChange,
}) => {
    const yearsUsed = [...Array(numberYears).keys()];

    return (
        <Select
            id="select-years-for-used"
            label={copy.fields.yearForUsed.label}
            placeholder={copy.fields.yearForUsed.placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
        >
            {yearsUsed.map((index) => (
                <Select.Option
                    key={fromYear - index}
                    value={(fromYear - index).toString()}
                >
                    {fromYear - index}
                </Select.Option>
            ))}
        </Select>
    );
};

YearsForUsed.propTypes = {
    value: PropTypes.string,
    fromYear: PropTypes.number.isRequired,
    numberYears: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};
