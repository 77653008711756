import {decorate, observable, action, observe, toJS} from 'mobx';


/**
 * @class BaseStore
 *
 * Base class for all global stores in the RideOctaneStore
 *
 * @property {boolean} isReady - true when store has loaded initial data
 * @property {RideOctaneStore} rideOctaneStore
 *
 * @property {ApiStore} apiStore
 * @property {HistoryStore} historyStore
 * @property {SlugsStore} slugsStore
 * @property {UserStore} userStore
 * @property {PartnerStore} partnerStore
 * @property {DealershipStore} dealershipStore
 * @property {UXCopyStore} uxCopyStore
 * @property {WaffleStore} waffleStore
 * @property {DealerSelectorStore} dealerSelectorStore
 */
export class BaseStore {
    isReady = false;
    rideOctaneStore = null;

    /**
     * Override to customize setting the initial state
     */
    setInitialState({rideOctaneStore, ...initialState}) {
        this.isReady = false;
        this.rideOctaneStore = rideOctaneStore;

        return Object.assign(this, initialState);
    }

    /**
     * Override to setup store prior to rendering
     */
    preRenderSetup() {
        this.isReady = true;
        return this;
    }

    /**
     * Override to remove additional properties from dehydrated state
     */
    dehydrateState() {
        const state = toJS(this);

        delete state.isReady;
        delete state.rideOctaneStore;

        // don't dehydrate functions
        for (const key of Object.keys(state)) {
            if (typeof state[key] === 'function') {
                delete state[key];
            }
        }

        return state;
    }

    /**
     * Override to setup store after rendering
     */
    postRenderSetup() {
        return this;
    }

    onReady(callback) {
        if (this.isReady) {
            callback();
        } else {
            const dispose = observe(this, 'isReady', () => {
                dispose();
                this.onReady(callback);
            });
        }
        return this;
    }

    /**
     * Helper for accessing global api store
     *
     * @returns {ApiStore}
     */
    get apiStore() {
        return this.rideOctaneStore.stores.apiStore;
    }

    /**
     * Helper for accessing global partner store
     *
     * @returns {PartnerStore}
     */
    get partnerStore() {
        return this.rideOctaneStore.stores.partnerStore;
    }

    /**
     * Helper for accessing global history store
     *
     * @returns {HistoryStore}
     */
    get historyStore() {
        return this.rideOctaneStore.stores.historyStore;
    }

    /**
     * Helper for accessing global slugs store
     *
     * @returns {SlugsStore}
     */
    get slugsStore() {
        return this.rideOctaneStore.stores.slugsStore;
    }

    /**
     * Helper for accessing global user store
     *
     * @returns {UserStore}
     */
    get userStore() {
        return this.rideOctaneStore.stores.userStore;
    }

    /**
     * Helper for accessing global dealership store
     *
     * @returns {DealershipStore}
     */
    get dealershipStore() {
        return this.rideOctaneStore.stores.dealershipStore;
    }

    /**
     * Helper for accessing global ux copy store
     *
     * @returns {UXCopyStore}
     */
    get uxCopyStore() {
        return this.rideOctaneStore.stores.uxCopyStore;
    }

    /**
     * Helper for accessing global waffle store
     *
     * @returns {WaffleStore}
     */
    get waffleStore() {
        return this.rideOctaneStore.stores.waffleStore;
    }

    /**
     * Helper for accessing global dealerSelector store
     *
     * @returns {DealerSelectorStore}
     */
    get dealerSelectorStore() {
        return this.rideOctaneStore.stores.dealerSelectorStore;
    }
}

decorate(BaseStore, {
    isReady: observable,

    setInitialState: action,
    preRenderSetup: action,
});
