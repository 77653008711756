import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {observer, inject} from 'mobx-react'
import {observable, runInAction} from 'mobx';



export const BrpGaPixel = inject('apiStore', 'partnerStore')(
    observer(
        class _BrpGaPixel extends React.Component {

            static propTypes = {
                vehicle_make: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                }),
                vehicle_type: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                }),
                vehicle_configuration: PropTypes.shape({
                    slug: PropTypes.string.isRequired,
                }),
            };

            // mapping of vehicle_type names to brand string
            static VEHICLE_TYPE_BRANDS = {
                "MOT": "on-road",
                "ATV": "off-road",
                "UTV": "off-road",
                "SNO": "ski-doo",
                "WAT": "sea-doo",
            };

            // mapping of vehicle_make names to brand string
            static VEHICLE_MAKE_BRANDS = {
                "Ski-Doo": "ski-doo",
                "Sea-Doo": "sea-doo",
            };

            data = observable({
                vehicleTypeName: null,
                vehicleMakeName: null,
            });

            /**
             * Ensures the pixel is only placed on BRP themed pages
             */
            get isDisabled () {
                return !(this.props.partnerStore.partner && this.props.partnerStore.partner.is_brp);
            }

            /**
             * computes the brand based on vehicle type and make)
             *
             *  Note: We need to check both vehicle type and vehicle make because
             *      a) vehicle make for both on-road & off-road is Can-Am
             *      b) vehicle type for non Can-Am vehicles is not curated
             */
            get brand() {
                let brand = null;
                const {vehicleTypeName, vehicleMakeName} = this.data;

                // always use vehicle type if it is available
                if (vehicleTypeName) {
                    brand = this.constructor.VEHICLE_TYPE_BRANDS[vehicleTypeName] || null;
                }

                // otherwise try to use vehicle make to determine
                if (!brand && vehicleMakeName) {
                    brand = this.constructor.VEHICLE_MAKE_BRANDS[vehicleMakeName] || null;
                }

                return brand;
            }

            constructor(props, context) {
                super(props, context);

                if (!this.isDisabled) {
                    // update saved data observable
                    //  (note: we need to wrap this in an action so only a single render is triggered)
                    runInAction(() => {
                        this.data.vehicleTypeName = get(this.props, 'vehicle_type.name') || null;
                        this.data.vehicleMakeName = get(this.props, 'vehicle_make.name') || null;
                    });
                }
            }

            componentDidMount() {
                if (!this.isDisabled && !this.brand) {
                    this.fetchAndUpdateTypeAndMake();
                }
            }

            componentDidUpdate() {
                if (!this.isDisabled && !this.brand) {
                    this.fetchAndUpdateTypeAndMake();
                }
            }

            /**
             * Fetches the vehicle type / make for a vehicle configuration and updates local data object
             *
             *  (for pages that don't have type / make readily available, we can use the configuration's slug to
             *  fetch the associated type / make)
             */
            fetchAndUpdateTypeAndMake() {
                const vehicleConfigurationSlug = get(this.props, 'vehicle_configuration.slug');

                if (vehicleConfigurationSlug) {
                    this.props.apiStore.fetch(`slugs/${vehicleConfigurationSlug}`, {
                        query: {
                            fields: ['vehicle_make', 'vehicle_type'],
                        }
                    }).then(({status, response}) => {
                        if (status !== 200) {
                            console.error(
                                `Failed to fetch vehicle with slug ${vehicleConfigurationSlug} (status=${status})`
                            );
                            return
                        }

                        // update saved data observable
                        //  (note: we need to wrap this in an action so only a single render is triggered)
                        runInAction(() => {
                            this.data.vehicleTypeName = get(response, 'vehicle_type.name') || null;
                            this.data.vehicleMakeName = get(response, 'vehicle_make.name') || null;
                        });
                    }).catch(err => {
                        console.error(
                            `Failed to fetch vehicle with slug ${vehicleConfigurationSlug}`, err
                        );
                    });
                }

                return this;
            }

            render() {
                if (this.isDisabled || !this.brand) {
                    return null;
                }

                return (
                    <div
                        id="brp-de"
                        data-brand={this.brand}
                    />
                );
            }
        }
    )
);
