import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import template from 'lodash.template';
import {formatPrice} from "../../../utils/number-utils";
import {VehicleImg} from "../../vehicle-img";

import {Row, Col, Button} from 'react-bootstrap';
import {RideOctaneLinkContainer as LinkContainer} from '../../../pages/url-renderer';

import copy from './copy.json';
import styles from './vehicle-sub-model-tile.module.scss';
import {pushOfferClick} from "../../../utils/gtm-utils";


export function VehicleSubmodelTile(props) {
    return (
        // Wrapped in a LinkContainer because we want the whole tile to
        // link to the same page as the 'Select Style' button
        <LinkContainer to={template(copy.select_button.href)(props)} className={classnames(styles.tile)}>
            <div className={classnames(styles.tileWrap, "mb-4")}>
                <div className={classnames("shadow-sm border rounded position-relative")}>
                    {/* Image */}
                    <div className={classnames(styles.imageWrap, "pl-4")}>
                        <VehicleImg hideBroken className="img-fluid d-block" src={props.default_image} alt={template(copy.title)(props)}/>
                    </div>

                    <div className={classnames(styles.contentWrap)}>
                        <div className="mt-2 pl-4 pr-4 pb-4">
                            {/* Title */}
                            <p className="font-weight-bold">
                                {template(copy.title)(props)}
                            </p>

                            {/* Price */}
                            {!!props.default_vehicle_configuration &&
                             !!props.default_vehicle_configuration.msrp && (
                                <p className="mb-3">
                                    {template(copy.msrp, {imports: {formatPrice}})(props)}
                                </p>
                            )}

                            {/* TODO: Color Swatches */}

                            {/* Action Buttons */}
                            {!!props.default_vehicle_configuration && (
                            <Row>
                                {shouldRenderOfferButton(props) && (
                                    <Col>
                                        <LinkContainer to={template(copy.offer_button.href)(props)}>
                                            <Button data-oid="get-finance-button" className="text-nowrap" variant="primary" block onClick={() => {
                                                pushOfferClick(copy.offer_button.label, template(copy.offer_button.href)(props));
                                            }}>
                                                {copy.offer_button.label}
                                            </Button>
                                        </LinkContainer>
                                    </Col>
                                )}
                                <Col>
                                    <LinkContainer to={template(copy.select_button.href)(props)}>
                                        <Button data-oid="select-style-button" className="text-nowrap" variant="outline-secondary" block>
                                            {copy.select_button.label}
                                        </Button>
                                    </LinkContainer>
                                </Col>
                            </Row>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </LinkContainer>
    );
}

function shouldRenderOfferButton(props) {
    return (
        !!props.default_vehicle_configuration.slug &&
        !!props.default_vehicle_configuration.msrp &&
        !!props.is_financeable
    )
}

VehicleSubmodelTile.propTypes = {
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    default_image: PropTypes.string.isRequired,
    vehicle_make: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    default_vehicle_configuration: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        msrp: PropTypes.number.isRequired,
    }).isRequired,
    is_financeable: PropTypes.bool.isRequired,
};
