import React from "react";

import {isInIFrame} from "../../utils/iframe-utils";
import {CTAFooter} from "./index";

export const ReturnHomeCta = () => {
    return isInIFrame() ? null : (
        <CTAFooter
            isSticky
            primary={{
                href: "/",
                label: "Return Home",
                oid: "cta-return-home",
            }}
        />
    );
};
