import React from "react";

export const noVehicle = (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            d="M64 58.5C64 65.9558 57.9558 72 50.5 72C43.0442 72 37 65.9558 37 58.5C37 51.0442 43.0442 45 50.5 45C57.9558 45 64 51.0442 64 58.5ZM62 58.5C62 64.8513 56.8513 70 50.5 70C47.7902 70 45.2992 69.0627 43.3337 67.4947L59.4947 51.3337C61.0627 53.2992 62 55.7902 62 58.5ZM58.1164 49.8835L41.8835 66.1164C40.0892 64.088 39 61.4212 39 58.5C39 52.1487 44.1487 47 50.5 47C53.4212 47 56.088 48.0892 58.1164 49.8835Z"/>
        <path
            fillRule="evenodd"
            d="M26.5 48C28.433 48 30 46.433 30 44.5C30 42.567 28.433 41 26.5 41C24.567 41 23 42.567 23 44.5C23 46.433 24.567 48 26.5 48ZM26.5 46C27.3284 46 28 45.3284 28 44.5C28 43.6716 27.3284 43 26.5 43C25.6716 43 25 43.6716 25 44.5C25 45.3284 25.6716 46 26.5 46Z"/>
        <path
            fillRule="evenodd"
            d="M78 44.5C78 46.433 76.433 48 74.5 48C72.567 48 71 46.433 71 44.5C71 42.567 72.567 41 74.5 41C76.433 41 78 42.567 78 44.5ZM76 44.5C76 45.3284 75.3284 46 74.5 46C73.6716 46 73 45.3284 73 44.5C73 43.6716 73.6716 43 74.5 43C75.3284 43 76 43.6716 76 44.5Z"/>
        <path
            fillRule="evenodd"
            d="M74.5 76C76.433 76 78 74.433 78 72.5C78 70.567 76.433 69 74.5 69C72.567 69 71 70.567 71 72.5C71 74.433 72.567 76 74.5 76ZM74.5 74C75.3284 74 76 73.3284 76 72.5C76 71.6716 75.3284 71 74.5 71C73.6716 71 73 71.6716 73 72.5C73 73.3284 73.6716 74 74.5 74Z"/>
        <path
            fillRule="evenodd"
            d="M30 72.5C30 74.433 28.433 76 26.5 76C24.567 76 23 74.433 23 72.5C23 70.567 24.567 69 26.5 69C28.433 69 30 70.567 30 72.5ZM28 72.5C28 73.3284 27.3284 74 26.5 74C25.6716 74 25 73.3284 25 72.5C25 71.6716 25.6716 71 26.5 71C27.3284 71 28 71.6716 28 72.5Z"/>
        <path
            fillRule="evenodd"
            d="M36.9999 36L46.9998 26.0002C46.372 25.1645 46 24.1257 46 23C46 20.2386 48.2386 18 51 18C53.7614 18 56 20.2386 56 23C56 24.1257 55.628 25.1645 55.0002 26.0002L65.0001 36H78C80.7614 36 83 38.2386 83 41V76C83 78.7614 80.7614 81 78 81H23C20.2386 81 18 78.7614 18 76V41C18 38.2386 20.2386 36 23 36H36.9999ZM51 26C52.6569 26 54 24.6569 54 23C54 21.3431 52.6569 20 51 20C49.3431 20 48 21.3431 48 23C48 24.6569 49.3431 26 51 26ZM51 28C51.9114 28 52.7659 27.7561 53.5017 27.3301L62.1716 36H39.8284L48.4983 27.3301C49.2341 27.7561 50.0886 28 51 28ZM23 38H78C79.6569 38 81 39.3431 81 41V76C81 77.6569 79.6569 79 78 79H23C21.3431 79 20 77.6569 20 76V41C20 39.3431 21.3431 38 23 38Z"/>
    </svg>
);
