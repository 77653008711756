import React from "react";

export const pageNotFound = (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="11" y="24" width="78" height="52" rx="2" stroke="currentColor" strokeWidth="2"/>
        <path
            fill="currentColor"
            d="M40.6666 42.7311C41.2189 42.7311 41.6666 43.1789 41.6666 43.7311V47.8129C41.6666 48.3652 41.2189 48.8129 40.6666 48.8129C40.1143 48.8129 39.6666 48.3652 39.6666 47.8129V43.7311C39.6666 43.1789 40.1143 42.7311 40.6666 42.7311Z"/>
        <path
            fill="currentColor"
            d="M60.3334 43.7311C60.3334 43.1789 59.8857 42.7311 59.3334 42.7311C58.7811 42.7311 58.3334 43.1789 58.3334 43.7311V47.8129C58.3334 48.3652 58.7811 48.8129 59.3334 48.8129C59.8857 48.8129 60.3334 48.3652 60.3334 47.8129V43.7311Z"/>
        <path
            fill="currentColor"
            d="M58.6597 65.0623C59.0679 65.4343 59.7004 65.4049 60.0724 64.9967C60.4444 64.5885 60.415 63.956 60.0068 63.584C57.2705 61.0905 53.7019 59.7084 49.9999 59.7084C46.2979 59.7084 42.7293 61.0905 39.993 63.584C39.5848 63.956 39.5554 64.5885 39.9274 64.9967C40.2994 65.4049 40.9319 65.4343 41.3401 65.0623C43.7081 62.9045 46.7963 61.7084 49.9999 61.7084C53.2035 61.7084 56.2918 62.9045 58.6597 65.0623Z"/>
        <line x1="11" y1="32" x2="89" y2="32" stroke="currentColor" strokeWidth="2"/>
        <line x1="30" y1="28" x2="70" y2="28" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
        <path
            fill="currentColor"
            d="M85.3166 26.6828C85.0724 26.4389 84.6769 26.4391 84.4329 26.6831L83.9999 27.116L83.5669 26.6831C83.3229 26.4391 82.9273 26.439 82.6832 26.683C82.439 26.927 82.439 27.3228 82.683 27.567L83.116 27.9999L82.683 28.433C82.4389 28.6771 82.439 29.0729 82.6832 29.3169C82.9273 29.5609 83.323 29.5607 83.567 29.3167L83.9999 28.8839L84.4329 29.3169C84.5907 29.4748 84.8208 29.5365 85.0365 29.4787C85.2522 29.421 85.4207 29.2526 85.4785 29.0369C85.5364 28.8212 85.4747 28.5911 85.3169 28.4332L84.8839 28L85.3172 27.5667C85.5612 27.3225 85.5609 26.9267 85.3166 26.6828Z"/>
        <circle fill="currentColor" cx="15" cy="28" r="1"/>
        <circle fill="currentColor" cx="18" cy="28" r="1"/>
        <circle fill="currentColor" cx="21" cy="28" r="1"/>
    </svg>
);
