import forIn from 'lodash.forin';
import {decorate, observable, computed, action, toJS} from 'mobx';

import {ListingsStore} from "../listings-store";


/**
 * @class BasePageStore
 *
 * Base class for all page stores in ListingsStore
 *
 * @property {object} rawData
 * @property {ListingsStore} listingsStore
 */
export class BasePageStore {
    rawData = null;
    listingsStore = null;

    get hasError() {
        return !this.rawData || this.rawData.hasError;
    }

    constructor(listingsStore, initialState) {
        if (!listingsStore || !(listingsStore instanceof ListingsStore)) {
            throw Error('PageStore constructor requires reference to a ListingsStore');
        }
        Object.assign(this, initialState);
        this.listingsStore = listingsStore;
    }

    initializeStore() {
        if (this.rawData) {
            return Promise.resolve(this.parseInitialRawData(this.rawData));
        }
        return this.fetchInitialStoreData().then(responses => {
            // initialize hasError
            let hasError = false;
            this.rawData = responses.reduce((rawData, response = {}) => {
                hasError = hasError || (response.status !== 200);
                return Object.assign(rawData, response.data, {hasError});
            }, {});

            // check for errors
            if (!this.rawData.hasError) {
                return this.parseInitialRawData(this.rawData);
            }

            return this;
        });
    }

    fetchInitialStoreData() {
        return Promise.resolve([]);
    }

    parseInitialRawData(rawData) {
        forIn(rawData, (val, key) => {
            if (typeof val === 'object') {
                val = {...val};
            }
            this[key] = val;
        });
        return this;
    }

    /**
     * Override to remove additional properties from dehydrated state
     */
    dehydrateState() {
        return {rawData: toJS(this.rawData)};
    }
}

decorate(BasePageStore, {
    rawData: observable,
    hasError: computed,

    parseInitialRawData: action,
});
