import React from "react";
import { inject, observer } from "mobx-react";
import { theme, ThemeProvider, GlobalStyles } from "octane-spark";

import { FancySpinner } from "../../components/spinners/fancy-spinner";
import { VehicleSelfSelection } from "./vehicle-self-selection";
import { withRideOctaneTitle, PageTitles } from "../../pages/octane-title";
import { isInIFrame } from "../../utils/iframe-utils";

export const VehicleSelfSelectionContainer = inject('partnerStore', 'themeStore', 'vehicleSelfSelectionStore', 'dealerSelectorStore', 'historyStore')(
    observer(
        class _VehicleSelfSelection extends React.Component {

            constructor(props, context) {
                super(props, context);

                this.store = this.props.vehicleSelfSelectionStore;

                // If flex param is passed, setup flex
                if (props.match.params.flexSlug) {
                    const makePreselected = this.props.historyStore.queryParams?.make;
                    this.store.setupFlex(props.match.params.flexSlug, makePreselected);
                }
            }

            render() {
                let themeSpark = theme
                const appTheme = this.props.themeStore.theme

                if (appTheme) {
                    const mainColor = appTheme.color;
                    // override rideOctaneBlue color - used as main color on Spark
                    themeSpark.colors.brand.rideOctaneBlue = mainColor
                }

                // to trigger render when store properties are updated
                const watcher = { ...this.props.vehicleSelfSelectionStore };
                const isFooterSticky = this.props.partnerStore.isRV && isInIFrame();

                // If it is a flex experience, awaits flex data to be loaded
                if(this.store.isFlexExperience && !this.store.flexData)
                    return (<FancySpinner className="d-block mx-auto my-5"/>)

                return (
                    <>
                        {/* set Global styles from Spark Web Kit */}
                        <GlobalStyles />

                        <ThemeProvider theme={themeSpark}>
                            {withRideOctaneTitle(
                                <VehicleSelfSelection 
                                    store={this.props.vehicleSelfSelectionStore} 
                                    isFooterSticky={isFooterSticky}
                                />,
                                [PageTitles.VEHICLE_SELF_SELECTION]
                            )}
                        </ThemeProvider>
                    </>
                );
            }
        }
    )
)
