
export class UUIDMap {
    uuids = [];
    map = {};

    constructor({dataArray = []}) {
        dataArray.forEach(dataObject => {
            if (!!dataObject && !!dataObject.uuid) {
                this.uuids.push(dataObject.uuid);
                this.map[dataObject.uuid] = dataObject;
            }
        });
    };

    /**
     * Returns object that corresponds to the provided uuid.
     * Returns null if the uuid is invalid
     * @param uuid
     */
    getObject(uuid) {
        if (!uuid || this.uuids.indexOf(uuid) === -1) {
            console.error(`In UUIDMap.getObject. Called with invalid uuid: ${uuid}. For reference, this.uuids:`, this.uuids);
            return null;
        }
        return this.map[uuid];
    }

    /**
     * Returns an array of all objects in the map.
     * The order of this.uuids is preserved.
     * @returns {array}
     */
    getObjects() {
        return this.uuids
            .filter(uuid => !!uuid)
            .map(uuid => this.map[uuid])
            .filter(objectForUUID => !!objectForUUID) // objectForUUID shouldn't ever be null, this is just to be cautious
    }

}
