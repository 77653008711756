import React from "react";
import {FixedLogoTopBar} from "../fixed-logo-top-bar";
import {Svg} from "../svg";
import {RideOctaneLink} from "../../pages/url-renderer";


export const OctaneLogoOnlyNav = () => {
    const logo = (
        <RideOctaneLink to="/">
            <Svg className="spark-text-white" svg="octane-logo" />
        </RideOctaneLink>
    );

    return <FixedLogoTopBar logo={logo} useTheme />;
};
