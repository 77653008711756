import React from "react";
import classnames from 'classnames';
import {FixedTopBar} from "../fixed-top-bar";
import styles from "./fixed-text-top-bar.module.scss";
import PropTypes from "prop-types";


/**
 * Reusable component for rendering our basic fixed header with a centered text
 *
 * @param {string|object}           [className] - additional class(es) added to header element
 * @param {string|React.Component}  text - a string path for an image or a component containing the text,
 *                                         if there isn't a text, no nav is rendered
 * @param {boolean}                 useTheme - Toggle to use the theme primary bg color, otherwise defaults to background color
 */
export const FixedTextTopBar = ({className = "", text, useTheme}) => {
    const headerClasses = classnames(
        className,
        styles.header,
        "spark-shadow-element",
        {
            "spark-bg-primary": useTheme,
            "spark-bg-white": !useTheme, /* Default background class */
        },
    );

    return text ? (
        <FixedTopBar>
            <header className={headerClasses}>
                <div className={styles.textContainer}>
                    <div className={"spark-text-upper spark-text-karla spark-text-20px font-weight-bold"}>
                        {text}
                    </div>
                </div>
            </header>
        </FixedTopBar>
    ) : null;
};

FixedTextTopBar.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    useTheme: PropTypes.bool,
};
