import React from 'react';

export const bringToDealer = (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M41 58C41 57.4477 41.4477 57 42 57H79.3333C79.8856 57 80.3333 57.4477 80.3333 58C80.3333 58.5523 79.8856 59 79.3333 59H42C41.4477 59 41 58.5523 41 58Z"/>
        <path
            d="M42 67.6667C41.4477 67.6667 41 68.1144 41 68.6667C41 69.219 41.4477 69.6667 42 69.6667H58C58.5523 69.6667 59 69.219 59 68.6667C59 68.1144 58.5523 67.6667 58 67.6667H42Z"/>
        <path
            d="M41 79.3333C41 78.781 41.4477 78.3333 42 78.3333H58C58.5523 78.3333 59 78.781 59 79.3333C59 79.8856 58.5523 80.3333 58 80.3333H42C41.4477 80.3333 41 79.8856 41 79.3333Z"/>
        <path
            fillRule="evenodd"
            d="M80 74C80 78.4183 76.4183 82 72 82C67.5817 82 64 78.4183 64 74C64 69.5817 67.5817 66 72 66C76.4183 66 80 69.5817 80 74ZM78 74C78 77.3137 75.3137 80 72 80C68.6863 80 66 77.3137 66 74C66 70.6863 68.6863 68 72 68C75.3137 68 78 70.6863 78 74Z"/>
        <path
            fillRule="evenodd"
            d="M10.855 10.855C12.0427 9.66726 13.6536 9 15.3333 9H31.3333C33.013 9 34.6239 9.66726 35.8117 10.855C36.9994 12.0427 37.6667 13.6536 37.6667 15.3333V19.6667H68.6667C70.3464 19.6667 71.9573 20.3339 73.145 21.5217C74.3327 22.7094 75 24.3203 75 26V30.3333H79.3333C81.013 30.3333 82.6239 31.0006 83.8116 32.1883C84.9994 33.376 85.6666 34.9869 85.6666 36.6666V41H90C90.5523 41 91 41.4477 91 42V90C91 90.5523 90.5523 91 90 91H31.3334C30.7811 91 30.3334 90.5523 30.3334 90V80.3333H20.6666C20.1143 80.3333 19.6666 79.8856 19.6666 79.3333V74.2889H10C9.44772 74.2889 9 73.8412 9 73.2889V15.3333C9 13.6536 9.66726 12.0427 10.855 10.855ZM30.3334 78.3333V42C30.3334 41.4477 30.7811 41 31.3334 41H83.6666V36.6666C83.6666 35.5174 83.2101 34.4152 82.3974 33.6025C81.5848 32.7899 80.4826 32.3333 79.3333 32.3333H74.0065L74 32.3333L73.9935 32.3333H26C24.8507 32.3333 23.7485 32.7899 22.9358 33.6025C22.1232 34.4152 21.6666 35.5174 21.6666 36.6666V78.3333H30.3334ZM73 26V30.3333H26C24.3203 30.3333 22.7093 31.0006 21.5216 32.1883C20.3339 33.376 19.6666 34.9869 19.6666 36.6666V72.2889H11V15.3333C11 14.1841 11.4565 13.0819 12.2692 12.2692C13.0819 11.4565 14.1841 11 15.3333 11H31.3333C32.4826 11 33.5848 11.4565 34.3975 12.2692C35.2101 13.0819 35.6667 14.1841 35.6667 15.3333V20.6667C35.6667 21.219 36.1144 21.6667 36.6667 21.6667H68.6667C69.8159 21.6667 70.9181 22.1232 71.7308 22.9359C72.5435 23.7485 73 24.8507 73 26ZM32.3334 89V43H89V89H32.3334Z"/>
    </svg>
);
