import React from 'react';
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import {inject} from "mobx-react";

import {INTERCOM_CHAT} from "../../enums/intercomChat";

export const Intercom = inject('rideOctaneStore')(
    class _Intercom extends React.Component {
        static propTypes = {
            showIntercom: PropTypes.bool,
            launchIntercom: PropTypes.string,
            rideOctaneStore: PropTypes.shape({
                settings: PropTypes.shape({
                    INTERCOM_APP_ID: PropTypes.string.isRequired,
                }),
            }).isRequired,
        };

        static defaultProps = {
            showIntercom: true
        };

        componentDidMount() {
            // loads page with intercom open
            if (this.props.launchIntercom && this.props.launchIntercom !== 'false') {
                window.Intercom('showNewMessage', INTERCOM_CHAT[this.props.launchIntercom] || null);
            }
        }

        componentDidUpdate() {
            window.Intercom('update', {hide_default_launcher: !this.props.showIntercom, custom_launcher_selector: '#customIntercom'});
        }

        render() {
            const {INTERCOM_APP_ID} = this.props.rideOctaneStore.settings;
            return (
                <Helmet>
                    <script type="application/javascript">
                        {`window.intercomSettings = ${JSON.stringify({
                            app_id: INTERCOM_APP_ID,
                            hide_default_launcher: !this.props.showIntercom,
                            custom_launcher_selector: '#customIntercom'
                        })}`}
                    </script>

                    <script type="application/javascript">
                        {`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
                    </script>
                </Helmet>
            );
        }
    }
)
