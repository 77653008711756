import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {formatPrice} from "../../utils/number-utils";
import styles from './vehicle-configuration-preview.module.scss';


export function VehicleConfigurationPreview(props) {
    let name = props.name;
    if (props.vehicle_make) {
        name = [props.vehicle_make.name, name].join(' ');
    }

    const showColor = !!props.color && !props.hideColor;
    const showImage = !props.hideImg && !!props.default_image;

    // wrapper class
    const wrapperClass = classnames(
        "d-flex",
        styles.wrapper, {
            [styles.noVehicleImage]: !showImage,
        },
    );

    // image wrapper class
    const imgWrapperClass = classnames({
        "w-125px": props.minimized,
        "w-200px": !props.minimized,
        "d-none d-sm-block": props.hideImgOnMobile,
    });

    // your vehicle label class
    const yourVehicleLabelClass = classnames(
        'spark-text-primary',
        'spark-text-spaced',
        'spark-text-14px-bold',
        styles.yourVehicleLabel,
    );

    // vehicle name class
    const vehicleNameClass = classnames(
        "font-weight-bold", {
            "h6": !props.minimized,
        },
    );

    return (
        <div className={wrapperClass}>
            {/* Image */}
            {showImage && (
                <div className={imgWrapperClass}>
                    <img className="img-fluid d-block" src={props.default_image} alt={name}/>
                </div>
            )}

            <div>
                {/* "YOUR VEHICLE" label */}
                <p className={yourVehicleLabelClass}>
                    YOUR VEHICLE
                </p>

                {/* Name */}
                <p className={vehicleNameClass}>
                    {name}
                </p>

                {/* MSRP & Configured MSRP */}
                <VehicleConfigurationPriceDescription {...props}/>

                {/* Color Swatch & Color Name */}
                {showColor && (
                    <div className="d-flex align-items-center">
                        <img className="rounded-circle" src={props.color.color_url} alt={props.color.name}/>
                        <p className="ml-1 text-capitalize">{props.color.name}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

VehicleConfigurationPreview.propTypes = {
    hideImg: PropTypes.bool,
    hideColor: PropTypes.bool,
    minimized: PropTypes.bool,
    hideImgOnMobile: PropTypes.bool,
    hideConfiguredMSRP: PropTypes.bool,
    name: PropTypes.string.isRequired,
    msrp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    accessoriesAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    default_image: PropTypes.string,
    color: PropTypes.shape({
        name: PropTypes.string.isRequired,
        color_url: PropTypes.string.isRequired,
    }),
    vehicle_make: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
};

export function VehicleConfigurationPriceDescription(props) {
    const msrp = Number(props.msrp);
    const accessoriesAmount = !!props.accessoriesAmount ? Number(props.accessoriesAmount) : 0.0;

    // vehicle mrsp class
    const vehicleMsrpClass = classnames({
        "h6": !props.minimized && props.hideConfiguredMSRP,
    });

    if (props.hideConfiguredMSRP) {
        return (
            <p className={vehicleMsrpClass}>{formatPrice(msrp)}</p>
        );
    }

    return (
        <p className={vehicleMsrpClass}>
            MSRP: {formatPrice(msrp)} | Configured: {formatPrice(msrp + accessoriesAmount)}
        </p>
    );
}

VehicleConfigurationPriceDescription.propTypes = {
    hideConfiguredMSRP: PropTypes.bool,
    msrp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    accessoriesAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
