import {decorate, computed, observable} from 'mobx';
import {BasePageStore} from "./base-page-store";


/**
 * @class BaseVehicleListingPageStore
 * @inheritDoc
 *
 * Base class for vehicle listing detail pages (e.g. model page / sub model page)
 *
 * @property {string} vehicleName
 * @property {string} pageTitle
 * @property {array} pageTitleWords
 * @property {array} breadcrumbs
 *
 * @property {object} carouselImage
 * @property {array} carouselImages
 * @property {object} pageCarousel
 */
export class BaseVehicleListingPageStore extends BasePageStore {
    carouselImage = null;

    get vehicleName() {
        return this._vehicleName();
    }

    _vehicleName() {
        throw Error('Child class must implement _vehicleName');
    }

    get pageTitle() {
        return this._pageTitle();
    }

    _pageTitle() {
        throw Error('Child class must implement _pageTitle');
    }

    get pageTitleWords() {
        return this._pageTitleWords();
    }

    _pageTitleWords() {
        throw Error('Child class must implement _pageTitleWords');
    }

    get breadcrumbs() {
        return this._breadcrumbs();
    }

    _breadcrumbs() {
        throw Error('Child class must implement _breadcrumbs');
    }

    get carouselImages() {
        return this._carouselImages();
    }

    _carouselImages() {
        throw Error('Child class must implement _carouselImages');
    }

    get pageCarousel() {
        return {
            carouselImage: this.carouselImage,
            carouselImages: this.carouselImages,
        };
    }

    /**
     * abstract method that returns a vehicle-related model
     */
    getPrimaryVehicleObject() {
        throw 'not implemented';
    }

}


decorate(BaseVehicleListingPageStore, {
    carouselImage: observable,

    vehicleName: computed,
    pageTitle: computed,
    pageTitleWords: computed,
    breadcrumbs: computed,
    pageCarousel: computed,
    carouselImages: computed,
});
