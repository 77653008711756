import React from 'react';
import './backdrop.css';

const Backdrop = props => (
    /*This component is a backdrop that gets created when accessing the sidedrawer in a the mobile view.
      Clicking anywhere on this backdrop will close the sidedrawer.*/
    <div className="backdrop d-block d-md-none" onClick={props.click}/>
);


export default Backdrop;