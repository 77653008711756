/**
 * Returns a list after sorting by ascending msrp.
 * @param {array} unsortedObjects - array objects to sort.
 * @returns {array}
 */

import {UUIDMap} from "./uuid-map";

export function sortByAscendingMSRP(unsortedObjects = []) {
    return unsortedObjects.slice().sort((a, b) => a.msrp - b.msrp);
}

/**
 * Returns sorted trims (by ascending msrp) based on:
 *  - The data from the previously sorted vehicle configurations
 *  - The additional trim related info (if provided).
 * @param {array} sortedVehicleConfigurations
 * @param {array} additionalTrimsData
 * @returns {array} trims
 */
export function getSortedTrims({sortedVehicleConfigurations = [], additionalTrimsData = []}) {
    let trimsDict = {}; // each key is the relevant trim's uuid
    // this is just an arrow function to check if the model is already in trimsDict
    const trimAlreadyIncluded = trimUUID => Object.keys(trimsDict).indexOf(trimUUID) !== -1;

    // include trims data from vehicle configurations
    if (!!sortedVehicleConfigurations) {
        sortedVehicleConfigurations.forEach(vehicleConfiguration => {
            if (!!vehicleConfiguration.vehicle_trim && !!vehicleConfiguration.vehicle_trim.uuid) {
                // if trim is not in trimsDict, include it.
                if (!trimAlreadyIncluded(vehicleConfiguration.vehicle_trim.uuid)) {
                    trimsDict[vehicleConfiguration.vehicle_trim.uuid] = {
                        ...vehicleConfiguration.vehicle_trim,
                        default_vehicle_configuration: vehicleConfiguration.uuid,
                        msrp: vehicleConfiguration.msrp,
                        colors: []
                    };
                }

                // include color for trim
                trimsDict[vehicleConfiguration.vehicle_trim.uuid].colors.push({
                    uuid: vehicleConfiguration.uuid,
                    slug: vehicleConfiguration.slug,
                    name: vehicleConfiguration.color.name,
                    color_url: vehicleConfiguration.color.color_url,
                    trim: {uuid: vehicleConfiguration.vehicle_trim.uuid},
                    msrp: vehicleConfiguration.msrp,
                });
            }
        });
    }

    // include additional trims data
    if (!!additionalTrimsData) {
        additionalTrimsData.forEach(additionalDataForTrim => {
            if (!!additionalDataForTrim.uuid && !!additionalDataForTrim.vehicle_sub_model) {
                const existingTrimDict = trimsDict[additionalDataForTrim.uuid] || {};
                trimsDict[additionalDataForTrim.uuid] = {
                    ...additionalDataForTrim,
                    ...existingTrimDict,
                };
            }
        });
    }

    // pull the trims out of the dictionary, sort them based on msrp and return them.
    const unsortedTrims = Object.values(trimsDict);
    return sortByAscendingMSRP(unsortedTrims);
}

/**
 * Returns sorted submodels (by ascending msrp) based on:
 *  - The data from the previously sorted trims
 *  - The additional submodel related info (if provided).
 * @param sortedTrims {array}
 * @param additionalSubmodelsData {array}
 * @returns {array} submodels
 */
export function getSortedSubmodels({sortedTrims = [], additionalSubmodelsData = []}) {
    let submodelsDict = {}; // each key is the relevant submodel's uuid
    // this is just an arrow function to check if the model is already in submodelsDict
    const submodelAlreadyIncluded = submodelUUID => Object.keys(submodelsDict).indexOf(submodelUUID) !== -1;

    // include submodel data from trims
    if (!!sortedTrims) {
        sortedTrims.forEach(trim => {
            if (!!trim.vehicle_sub_model && !!trim.vehicle_sub_model.uuid && !submodelAlreadyIncluded(trim.vehicle_sub_model.uuid)) {
                // if submodel is not in submodels, include it.
                submodelsDict[trim.vehicle_sub_model.uuid] = {
                    ...trim.vehicle_sub_model,
                    default_vehicle_trim: trim.uuid,
                    msrp: trim.msrp,
                };
            }
        });
    }

    // include additional submodels data
    if (!!additionalSubmodelsData) {
        additionalSubmodelsData.forEach(additionalDataForSubmodel => {
            if (!!additionalDataForSubmodel.uuid && !!additionalDataForSubmodel.vehicle_model) {
                const existingSubmodelDict = submodelsDict[additionalDataForSubmodel.uuid] || {};
                submodelsDict[additionalDataForSubmodel.uuid] = {
                    ...additionalDataForSubmodel,
                    ...existingSubmodelDict,
                };
            }
        });
    }

    // pull the submodels out of the dictionary, sort them based on msrp and return them.
    const unsortedSubmodels = Object.values(submodelsDict);
    return sortByAscendingMSRP(unsortedSubmodels);
}

/**
 * Returns sorted vehicle data (by ascending msrp)
 * @param vehicleConfigurations {array}
 * @param vehicleTrims {array}
 * @param vehicleSubmodels {array}
 * @param vehicleModels {array}
 * @returns {object}
 * {
 *     vehicleConfigurations: [{configuration_1}, {configuration_2}, ...],
 *     vehicleTrims: [{trim_1}, {trim_2}, ...],
 *     vehicleSubmodels: [{submodel_1}, {submodel_2}, ...],
 *     vehicleModels: [{model_1}, {model_2}, ...],
 * }
 */
export function getSortedVehicleData({
      vehicleConfigurations,
      vehicleTrims = null,
      vehicleSubmodels = null,
    }) {
    if (!vehicleConfigurations) {
        console.warn('getSortedVehicleData: vehicleConfigurations is null');
        return {};
    }

    let taxonomyData = {};

    // include configurations after sorting them by msrp
    const sortedVehicleConfigurations = sortByAscendingMSRP(vehicleConfigurations);
    taxonomyData.vehicleConfigurations = new UUIDMap({dataArray: sortedVehicleConfigurations});

    // include sorted trims
    const sortedTrims = getSortedTrims({sortedVehicleConfigurations, additionalTrimsData: vehicleTrims});
    taxonomyData.vehicleTrims = new UUIDMap({dataArray: sortedTrims});

    // include sorted submodels
    const sortedSubmodels = getSortedSubmodels({sortedTrims, additionalSubmodelsData: vehicleSubmodels});
    taxonomyData.vehicleSubmodels = new UUIDMap({dataArray: sortedSubmodels});

    return taxonomyData;
}
