import React from 'react';

export const documents = (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M42 57C41.4477 57 41 57.4477 41 58C41 58.5523 41.4477 59 42 59H79.3333C79.8856 59 80.3333 58.5523 80.3333 58C80.3333 57.4477 79.8856 57 79.3333 57H42Z"/>
        <path
            d="M41 68.6667C41 68.1144 41.4477 67.6667 42 67.6667H58C58.5523 67.6667 59 68.1144 59 68.6667C59 69.219 58.5523 69.6667 58 69.6667H42C41.4477 69.6667 41 69.219 41 68.6667Z"/>
        <path
            d="M41 79.3333C41 78.781 41.4477 78.3333 42 78.3333H58C58.5523 78.3333 59 78.781 59 79.3333C59 79.8856 58.5523 80.3333 58 80.3333H42C41.4477 80.3333 41 79.8856 41 79.3333Z"/>
        <path
            fillRule="evenodd"
            d="M10.855 10.855C12.0427 9.66726 13.6536 9 15.3333 9H31.3333C33.013 9 34.6239 9.66726 35.8117 10.855C36.9994 12.0427 37.6667 13.6536 37.6667 15.3333V19.6667H68.6667C70.3464 19.6667 71.9573 20.3339 73.145 21.5217C74.3327 22.7094 75 24.3203 75 26V30.3333H79.3333C81.013 30.3333 82.6239 31.0006 83.8116 32.1883C84.9994 33.376 85.6666 34.9869 85.6666 36.6666V41H90C90.5523 41 91 41.4477 91 42V65.5834C94.6825 68.8793 97 73.669 97 79C97 88.9411 88.9411 97 79 97C73.669 97 68.8793 94.6825 65.5834 91H31.3334C30.7811 91 30.3334 90.5523 30.3334 90V80.3333H20.6666C20.1143 80.3333 19.6666 79.8856 19.6666 79.3333V74.2889H10C9.44772 74.2889 9 73.8412 9 73.2889V15.3333C9 13.6536 9.66726 12.0427 10.855 10.855ZM64.0311 89C62.1165 86.1398 61 82.7003 61 79C61 69.0589 69.0589 61 79 61C82.7003 61 86.1398 62.1166 89 64.0311V43H32.3334V89H64.0311ZM30.3334 78.3333V42C30.3334 41.4477 30.7811 41 31.3334 41H83.6666V36.6666C83.6666 35.5174 83.2101 34.4152 82.3974 33.6025C81.5848 32.7899 80.4826 32.3333 79.3333 32.3333H74.0065L74 32.3333L73.9935 32.3333H26C24.8507 32.3333 23.7485 32.7899 22.9358 33.6025C22.1232 34.4152 21.6666 35.5174 21.6666 36.6666V78.3333H30.3334ZM73 26V30.3333H26C24.3203 30.3333 22.7093 31.0006 21.5216 32.1883C20.3339 33.376 19.6666 34.9869 19.6666 36.6666V72.2889H11V15.3333C11 14.1841 11.4565 13.0819 12.2692 12.2692C13.0819 11.4565 14.1841 11 15.3333 11H31.3333C32.4826 11 33.5848 11.4565 34.3975 12.2692C35.2101 13.0819 35.6667 14.1841 35.6667 15.3333V20.6667C35.6667 21.219 36.1144 21.6667 36.6667 21.6667H68.6667C69.8159 21.6667 70.9181 22.1232 71.7308 22.9359C72.5435 23.7485 73 24.8507 73 26ZM95 79C95 87.8366 87.8366 95 79 95C70.1634 95 63 87.8366 63 79C63 70.1634 70.1634 63 79 63C87.8366 63 95 70.1634 95 79Z"/>
        <path
            d="M81.624 79.312C82.648 79.6 83.424 80.096 83.952 80.8C84.48 81.488 84.744 82.36 84.744 83.416C84.744 84.888 84.224 86.048 83.184 86.896C82.16 87.744 80.768 88.168 79.008 88.168C77.968 88.168 76.968 88 76.008 87.664C75.064 87.328 74.264 86.864 73.608 86.272C73.288 86.016 73.128 85.696 73.128 85.312C73.128 85.024 73.208 84.784 73.368 84.592C73.528 84.384 73.72 84.28 73.944 84.28C74.184 84.28 74.456 84.384 74.76 84.592C75.448 85.104 76.12 85.496 76.776 85.768C77.448 86.024 78.168 86.152 78.936 86.152C81.208 86.152 82.344 85.184 82.344 83.248C82.344 82.304 82.04 81.6 81.432 81.136C80.84 80.656 79.936 80.416 78.72 80.416H77.4C77.096 80.416 76.856 80.32 76.68 80.128C76.504 79.92 76.416 79.68 76.416 79.408C76.416 79.152 76.504 78.928 76.68 78.736C76.856 78.528 77.096 78.424 77.4 78.424H78.336C79.504 78.424 80.392 78.184 81 77.704C81.624 77.208 81.936 76.488 81.936 75.544C81.936 74.712 81.68 74.064 81.168 73.6C80.672 73.136 79.968 72.904 79.056 72.904C77.712 72.904 76.368 73.424 75.024 74.464C74.72 74.672 74.448 74.776 74.208 74.776C73.984 74.776 73.792 74.68 73.632 74.488C73.472 74.28 73.392 74.032 73.392 73.744C73.392 73.36 73.552 73.04 73.872 72.784C74.528 72.192 75.32 71.728 76.248 71.392C77.192 71.056 78.16 70.888 79.152 70.888C80.736 70.888 81.992 71.28 82.92 72.064C83.864 72.848 84.336 73.912 84.336 75.256C84.336 76.216 84.096 77.056 83.616 77.776C83.152 78.48 82.488 78.992 81.624 79.312Z"/>
    </svg>
);
