import {observer, inject} from 'mobx-react';


export const ReturnToServer = inject('rideOctaneStore')(
    observer(
        function _ReturnToServer({rideOctaneStore, staticContext}) {
            if (rideOctaneStore.isUXServer) {
                staticContext.goNext = true;
            } else {
                window.document.location.reload();
            }
            return null;
        }
    )
);
