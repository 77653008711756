import React from 'react';
import classnames from 'classnames';
import styles from './side-drawer.module.scss';
import {RideOctaneLink as Link} from "../../pages/url-renderer";
import copy from "./copy";
import {Svg} from "../svg";

const SideDrawer = props => {
    const drawerClasses = classnames(
        styles.sideDrawer,
        {[styles.open]: props.show},
        "p-4 position-fixed d-block d-md-none",
    );
    const navLinkClass = "h4 py-4 border-bottom font-weight-bold d-block";

    const expandDropdown = e => {
        e.preventDefault();
        e.currentTarget.classList.toggle('pb-4');
        e.currentTarget.classList.toggle('border-bottom');
        e.currentTarget.classList.toggle('spark-text-secondary');
        e.currentTarget.nextElementSibling.classList.toggle('d-none');
        if (e.currentTarget.lastElementChild.textContent === '+') {
            e.currentTarget.lastElementChild.textContent = "﹣";
        } else {
            e.currentTarget.lastElementChild.textContent = "+";
        }
    };
    
    const vehicle_make_names = props.vehicle_makes;
    const optionItems = vehicle_make_names.map((make) =>
    <li key={make.name} className="border-bottom">
        <Link to={"/".concat(make.slug)} title={make.name} className="py-3 d-block spark-text-secondary" onClick={props.closeDrawer}>
            {make.name}
        </Link>
    </li>
    );

    return (
        <nav className={drawerClasses}>
            <div className="d-flex justify-content-between align-items-center">
                <Link to={copy.logo.url} onClick={props.closeDrawer}>
                     <Svg className="spark-text-primary" svg="octane-logo" style={{height: 33}}/>
                </Link>
                <div className={styles.closeX} onClick={props.closeDrawer}/>
            </div>
            <ul className="list-unstyled font-weight-bold">
                <li>
                    <Link to={copy.home.url} className={navLinkClass} onClick={props.closeDrawer}>
                        {copy.home.label}
                    </Link>
                </li>
                <li>
                    <Link to="#" className={navLinkClass} onClick={expandDropdown}>
                        {copy.vehicles.label}
                        <span className="ml-auto mr-3 float-right">+</span>
                    </Link>
                    <ul className="list-unstyled font-weight-normal d-none">
                        {optionItems}
                    </ul>
                </li>
                <li>
                    <Link to={copy.check_your_rate.url} className={navLinkClass} onClick={props.closeDrawer}>
                        {copy.check_your_rate.label}
                    </Link>
                </li>
                <li>
                    <a href="#" className="pt-4 pb-4 spark-text-secondary border-bottom d-flex" onClick={expandDropdown}>
                        {copy.about_octane.label}
                        <span className="ml-auto mr-3">+</span>
                    </a>
                    <ul className="list-unstyled font-weight-normal d-none">
                        <li className="border-bottom">
                            <a href={copy.about_octane.dropdown_1.url} className="py-3 d-block spark-text-secondary">
                                {copy.about_octane.dropdown_1.label}
                            </a>
                        </li>
                        <li className="border-bottom">
                            <a href={copy.about_octane.dropdown_2.url} className="py-3 d-block spark-text-secondary">
                                {copy.about_octane.dropdown_2.label}
                            </a>
                        </li>
                        <li className="border-bottom">
                            <a href={copy.about_octane.dropdown_3.url} className="py-3 d-block spark-text-secondary">
                                {copy.about_octane.dropdown_3.label}
                            </a>
                        </li>
                        <li className="border-bottom">
                            <a href={copy.about_octane.dropdown_4.url} className="py-3 d-block spark-text-secondary">
                                {copy.about_octane.dropdown_4.label}
                            </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#" className="pt-4 pb-4 spark-text-secondary border-bottom d-flex" onClick={expandDropdown}>
                        {copy.for_dealers.label}
                        <span className="ml-auto mr-3">+</span>
                    </a>
                    <ul className="list-unstyled font-weight-normal d-none">
                        <li className="border-bottom">
                            <a href={copy.for_dealers.dropdown_1.url} className="py-3 d-block spark-text-secondary">
                                {copy.for_dealers.dropdown_1.label}
                            </a>
                        </li>
                        <li className="border-bottom">
                            <a href={copy.for_dealers.dropdown_2.url} className="py-3 d-block spark-text-secondary">
                                {copy.for_dealers.dropdown_2.label}
                            </a>
                        </li>
                        <li className="border-bottom">
                            <a href={copy.for_dealers.dropdown_3.url} className="py-3 d-block spark-text-secondary" target="_blank" >
                                {copy.for_dealers.dropdown_3.label}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
};

export default SideDrawer;