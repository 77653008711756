
/**
 * id: Used by the ListDropdown component as the default for when we pick the form and sync to the API server.
 * name: Used by the ListDropdown component as the value to display to the user.
 */
export const employmentStatuses = [
    {id: 'employed', name: 'Full-Time Employed'},
    {id: 'self', name: 'Self-Employed'},
    {id: '1099', name: 'Contractor / 1099'},
    {id: 'military', name: 'Military'},
    {id: 'student', name: 'Student'},
    {id: 'retired', name: 'Retired'},
    {id: 'unemployed', name: 'Unemployed'},
    {id: 'other', name: 'Other'}
];
