import React from 'react';
import {Link} from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';

function ensureAppendedSlash(urlStr) {
    const lastIndex = urlStr.length - 1;
    if (urlStr[lastIndex] === '/') {
        return urlStr;
    } else {
        return urlStr + '/';
    }
}

/**
 * Replaces '//' with '/' under the following scenarios:
 * - '//' is not the very beginning of urlStr
 * - '//' is not immediately following a ':'
 */
function removeDuplicateSlashes(urlStr) {
    return urlStr.replace(/([^:])\/\//g, '$1/');
}

/**
 * Returns provided URLs rendered according to our preferences.
 */
export const renderUrl = function(urlStr) {
    if (urlStr === undefined || urlStr === null) {
        return null;
    } else if (urlStr.length === 0) {
        return '';
    } else if (urlStr.indexOf('#') > -1) {
        return removeDuplicateSlashes(urlStr);
    } else {
        return removeDuplicateSlashes(ensureAppendedSlash(urlStr));
    }
};

export const RideOctaneLink = ({to, ...props}) => {
    let isExternal = false;
    const url = renderUrl(to);

    if (url.startsWith('/o')) {
        // This goes to our CMS site, which is separate/external
        isExternal = true;
    } else if (url.startsWith('http')) {
        isExternal = true;
    }

    if (isExternal) {
        //eslint-disable-next-line jsx-a11y/anchor-has-content
        return <a href={url} {...props} />
    }
    return <Link to={renderUrl(to)} {...props} />;
};

export const RideOctaneLinkContainer = ({to, ...props}) => {
    return <LinkContainer to={renderUrl(to)} {...props} />;
};
