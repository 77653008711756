import React from "react";
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';


export function VehicleCategoryTile({title, href, src, alt}) {
    const tile = (
        <div className="border rounded h-100">
            <div className="pt-2 px-2 mb-2">
                <img
                    className="img-fluid d-block mx-auto"
                    src={src}
                    alt={alt || title}
                />
            </div>
            <h6 className="px-3 mb-4">
                {title}
            </h6>
        </div>
    );

    if (href) {
        return (
            <Link to={href} className="text-decoration-none text-dark">
                {tile}
            </Link>
        );
    }

    return tile;
}

VehicleCategoryTile.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    href: PropTypes.string,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
};
