import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import {Row, Col} from 'react-bootstrap';


export class BaseVehicleGrid extends React.PureComponent {
    static propTypes = {
        numItems: PropTypes.number.isRequired,
        numCols: PropTypes.shape({
            xs: PropTypes.number.isRequired,
            md: PropTypes.number.isRequired,
            lg: PropTypes.number.isRequired,
        }).isRequired,
        maxRows: PropTypes.shape({
            xs: PropTypes.number.isRequired,
            md: PropTypes.number.isRequired,
            lg: PropTypes.number.isRequired,
        }).isRequired,
    };

    constructor(props, context) {
        super(props, context);

        this.colSpans = this._calcColSpans();
        this.classNames = this._calcClassNames();
    }

    componentWillReceiveProps(props) {
        if ((props.numItems !== this.props.numItems) ||
            (props.numCols !== this.props.numCols) ||
            (props.maxRows !== this.props.maxRows)) {

            this.colSpans = this._calcColSpans(props);
            this.classNames = this._calcClassNames(props);
        }
    }

    _calcColSpans(props) {
        const {numCols} = props || this.props;
        return {
            xs: numCols.xs ? (12 / numCols.xs) : void 0,
            md: numCols.md ? (12 / numCols.md) : void 0,
            lg: numCols.lg ? (12 / numCols.lg) : void 0,
        };
    }

    _calcClassNames(props) {
        const classNames = [];
        const {numItems, numCols, maxRows} = props || this.props;

        const maxIndices = {
            xs: (numCols.xs * Math.min(maxRows.xs, Math.floor(numItems / numCols.xs))) - 1,
            md: (numCols.md * Math.min(maxRows.md, Math.floor(numItems / numCols.md))) - 1,
            lg: (numCols.lg * Math.min(maxRows.lg, Math.floor(numItems / numCols.lg))) - 1,
        };

        for (let i = 0; i < numItems; i++) {
            classNames.push(classnames({
                "d-none": i > maxIndices.xs,
                "d-block": i <= maxIndices.xs,
                "mb-0": i > maxIndices.xs - numCols.xs,
                "mb-3": i <= maxIndices.xs - numCols.xs,
                "d-md-none": i > maxIndices.md,
                "d-md-block": i <= maxIndices.md,
                "mb-md-0": i > maxIndices.md - numCols.md,
                "mb-md-3": i <= maxIndices.md - numCols.md,
                "d-lg-none": i > maxIndices.lg,
                "d-lg-block": i <= maxIndices.lg,
                "mb-lg-0": i > maxIndices.lg - numCols.lg,
                "mb-lg-3": i <= maxIndices.lg - numCols.lg,
            }));
        }

        return classNames;
    }

    render() {
        return (
            <Row>
                {React.Children.map(this.props.children, (child, i) => (
                    <Col key={child.key} {...this.colSpans} className={this.classNames[i]}>
                        {child}
                    </Col>
                ))}
            </Row>
        )
    }
}
