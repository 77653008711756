import React from "react";

import {LayoutComponent} from "../layout-component";
import {ReturnHomeCta} from "../cta-footer";
import {errorOnOurEnd, pageNotFound} from "../svg/graphics";

import copy from "./copy.json";


export function PageNotFound() {
    let {title, text} = copy.page_not_found;

    return (
        <div className="mt-5">
            {/* Layout with svg icon, header and body text */}
            <LayoutComponent
                icon={pageNotFound}
                headline={title}
                bodyText={text}
            />
            <ReturnHomeCta />
        </div>
    );
}


export function ServerError({origin = "unknown", errorMessage}) {
    let {title, text} = copy.server_error;

    // Sends error message to datadog
    console.error(`ServerErrorView has been rendered. Origin: ${origin}.`,
        errorMessage ? ` Error: ${errorMessage}` : "");

    return (
        <div className="mt-5">
            {/* Layout with svg icon, header and body text */}
            <LayoutComponent
                icon={errorOnOurEnd}
                headline={title}
                bodyText={text}
            />
            <ReturnHomeCta />
        </div>
    );
}
