import debounce from 'lodash.debounce';

export function isInIFrame() {
    // check that there is a `window` object (this will only be true in the browser, server will not have a window)
    if (typeof window !== 'undefined') {
        try {
            // top window refers to the outer most frame, so if it's an iframe then they will not be equal
            return window.self !== window.top;
        } catch (e) {
            return false;
        }
    }
    return false;
}

export function postMessageHeight(targetWindow, height) {
    targetWindow.postMessage({olIframeHeight: height}, '*');
}

export function postMessageScrollIntoView(targetWindow) {
    targetWindow.postMessage({action: 'scrollIntoView'}, '*');
}

/**
 * Class which tracks the height of a given target element passed into the init function,
 * and executes a postMessage on any height changes for that target element.
 */
export class HeightObserver {
    currentScrollHeight = 0;
    currentContent = null;
    mutationObserver = null;
    postMessageHeightDebounced = debounce(
        () => {
            if (typeof window !== 'undefined' &&
                this.currentContent && this.currentScrollHeight !== this.currentContent.scrollHeight)
            {
                this.currentScrollHeight = this.currentContent.scrollHeight;
                postMessageHeight(window.top, this.currentScrollHeight);
            }
        },
        500, {leading: true, trailing: true} // send the initial height as well as the last one
    );

    init(currentContent) {
        if (typeof window !== 'undefined' &&
            !this.currentContent && currentContent && typeof currentContent === 'object')
        {
            this.currentContent = currentContent;
            this.mutationObserver = new MutationObserver(this.postMessageHeightDebounced);
            this.mutationObserver.observe(this.currentContent, { attributes: true, childList: true, subtree: true });
            window.addEventListener('resize', this.postMessageHeightDebounced);
        }
    }

    uninit() {
        this.currentScrollHeight = 0;
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.postMessageHeightDebounced);
        }
        if (this.mutationObserver) {
            this.mutationObserver.disconnect();
            this.mutationObserver = null;
        }
    }
}
