import React from 'react';
import {Button} from 'react-bootstrap';
import {inject, observer} from "mobx-react";
import PropTypes from "prop-types";
import copy from "./copy.json";
import {AlertSignupModalStore} from "../../../stores/listings-store/alert-signup-modal-store";
import {Input} from "../../../components/input";
import {Captcha, CaptchaDisclosure} from "../../../components/captcha";

/**
 *
 * Alert Signup Modal Content opened by global RideOctaneModal
 *
 * slug: the slug to submit to the POST
 * setPreventClose: the modal dialog's setPreventClose method
 * closeModal: the modal dialog's closeModal method
 */
export const AlertSignupModalContent = inject('apiStore')(observer(
    class _AlertSignupModalContent extends React.Component {
        static propTypes = {
            apiStore: PropTypes.shape({
                fetch: PropTypes.func.isRequired,
            }).isRequired,
            slug: PropTypes.string.isRequired,
            setPreventClose: PropTypes.func.isRequired,
            closeModal: PropTypes.func.isRequired,
        };

        store = new AlertSignupModalStore(this.props.apiStore, this.props.slug);
        contentRef = React.createRef();
        buttonRef = React.createRef();
        captchaRef = React.createRef();
        captchaContainerRef = React.createRef();

        // ensures the modal stays at least the height of the initial state throughout states
        minContentHeight = 0;
        minButtonHeight = 0;
        minCaptchaContainerHeight = 0;

        // ensures subsequent states have the same height as the default state
        setHeights() {
            if (!!this.contentRef.current) { // check ref to initial content exists to preserve height
                this.minContentHeight = this.contentRef.current.clientHeight;
            }
            if (!!this.buttonRef.current) { // check ref to initial button exists to preserve height
                this.minButtonHeight = this.buttonRef.current.clientHeight;
            }
            if (!!this.captchaContainerRef.current) { // check ref to captcha container exists to preserve height
                this.minCaptchaContainerHeight = this.captchaContainerRef.current.clientHeight;
            }
        }

        toggleDisclosure(e) {
            e.preventDefault();
            this.setHeights();
            this.store.toggleDisclosure();
        }

        submit({captchaResponse}) {
            this.setHeights();
            this.store.updateCaptchaResponse(captchaResponse);
            return this.store.submitRequest();
        }

        // proceeds to captcha if the specified email address is valid.
        onSubmitButtonClicked() {
            if (this.store.isFieldValid) {
                return this.captchaRef.current.execute();
            }
        }

        render() {
            const {SUBMIT_STATES} = this.store.constructor;
            let content;
            let button;

            switch(this.store.submitState) {
                case SUBMIT_STATES.SUBMITTING:
                    this.props.setPreventClose(true);
                    content = SubmittingContent(this.minContentHeight, this.store.email);
                    button = SubmittingButton(this.minButtonHeight);
                    break;
                case SUBMIT_STATES.SUCCESS:
                    this.props.setPreventClose(false);
                    content = SuccessContent(this.minContentHeight);
                    button = CloseButton(this.minButtonHeight, this.props.closeModal);
                    break;
                case SUBMIT_STATES.DUPLICATE:
                    this.props.setPreventClose(false);
                    content = DuplicateContent(this.minContentHeight);
                    button = CloseButton(this.minButtonHeight, this.props.closeModal);
                    break;
                case SUBMIT_STATES.FAILURE:
                    this.props.setPreventClose(false);
                    content = FailureContent(this.minContentHeight);
                    button = CloseButton(this.minButtonHeight, this.props.closeModal);
                    break;
                case SUBMIT_STATES.DISCLOSURE:
                    content = DisclosureContent(this.minContentHeight);
                    button = DisclosureButton(this.minButtonHeight, () => this.store.toggleDisclosure());
                    break;
                default:
                    content = DefaultContent(this.contentRef, this.store);
                    button = DefaultButton(this.buttonRef, () => this.onSubmitButtonClicked());
            }

            const captchaContainer = CaptchaContainer({
                captchaRef: this.captchaRef,
                containerRef: this.captchaContainerRef,
                minContainerHeight: this.minCaptchaContainerHeight,
                onSubmit: (captchaResponse) => this.submit({captchaResponse}),
                toggleDisclosure: (e) => this.toggleDisclosure(e),
                isDefaultState: this.store.submitState !== SUBMIT_STATES.DEFAULT,
            });

            return (
                <div className="mx-4">
                    {content}
                    {button}
                    {captchaContainer}
                </div>
            );
        }
    }
));

function DefaultContent(contentRef, store) {
    return (
        <div ref={contentRef}>
            <h5 className="spark-text-primary text-center font-weight-bold">{copy.default.title}</h5>
            <p className="text-center">{copy.default.body}</p>
            <Input
                className = "mt-4"
                label="email"
                controlId="email"
                autocapitalize="none"
                onBlur={e => store.updateEmail(e.target.value)}
                error={store.isFieldInvalid}
            />
        </div>
    );
}

function DefaultButton(buttonRef, submit) {
    return (
        <div ref={buttonRef}>
            <Button variant="primary" size="lg" block onClick={submit}>
                <p className="lead">{copy.default.button_label}</p>
            </Button>
        </div>
    );
}

function CaptchaContainer({captchaRef, containerRef, minContainerHeight, onSubmit, isDefaultState, toggleDisclosure}) {
    return (
        <div
            ref={containerRef}
            style={{minHeight: minContainerHeight}}
            className="mt-2"
        >
            {!isDefaultState && (
                <React.Fragment>
                    <Captcha
                        captchaRef={captchaRef}
                        onClickContinue={onSubmit}
                        hideDisclosure
                    />
                    <p className="small spark-text-secondary text-center">
                        {copy.default.disclosure_short} <a href="#" onClick={e => toggleDisclosure(e)}>{copy.default.disclosure_link_label}</a>
                    </p>
                </React.Fragment>
            )}
        </div>
    );
}

function SubmittingContent(minContentHeight, email) {
    return (
        <div style={{minHeight: minContentHeight}} >
            <h5 className="spark-text-primary text-center font-weight-bold">{copy.default.title}</h5>
            <p className="text-center">{copy.default.body}</p>
            <Input
                className = "mt-4"
                label="email"
                controlId="email"
                value={email}
                disabled
            />
        </div>
    )
}

function SubmittingButton(minButtonHeight) {
    return (
        <Button variant="primary" size="lg" block style={{minHeight: minButtonHeight}} disabled>
            <span role="status" aria-hidden="true" className="spinner-border spinner-border-sm"/>
        </Button>
    );
}

function SuccessContent(minContentHeight) {
    return (
        <div className="text-center mb-3" style={{minHeight: minContentHeight}}>
            <img src={copy.success.icon.src} alt={copy.success.icon.alt}/>
            <h5 className="mt-4 spark-text-success font-weight-bold">{copy.success.title}</h5>
            <p>{copy.success.body}</p>
        </div>
    );
}

function DuplicateContent(minContentHeight) {
    return (
        <div className="text-center mb-3" style={{minHeight: minContentHeight}}>
            <img src={copy.success.icon.src} alt={copy.success.icon.alt}/>
            <h5 className="mt-4 spark-text-success font-weight-bold">{copy.duplicate.title}</h5>
            <p>{copy.duplicate.body}</p>
        </div>
    );
}

function FailureContent(minContentHeight) {
    return (
        <div className="text-center mb-3" style={{minHeight: minContentHeight}}>
            <img src={copy.failure.icon.src} alt={copy.failure.icon.alt}/>
            <h5 className="mt-4 spark-text-secondary font-weight-bold">{copy.failure.title}</h5>
            <p>{copy.failure.body}</p>
        </div>
    );
}

function DisclosureContent(minContentHeight) {
    return (
        <div className="text-center text-wrap mb-3" style={{minHeight: minContentHeight}} >
            <h5 className="spark-text-primary font-weight-bold">{copy.disclosure.title}</h5>
            <p className="small spark-text-secondary">{copy.disclosure.body}</p>
            <CaptchaDisclosure/>
        </div>
    );
}

function DisclosureButton(minButtonHeight, toggleDisclosure) {
    return (
        <div style={{minHeight: minButtonHeight}}>
            <Button variant="primary" size="lg" block onClick={toggleDisclosure}>
                <p className="lead">{copy.disclosure.button_label}</p>
            </Button>
        </div>
    );
}

function CloseButton(minButtonHeight, closeModal) {
    return (
        <div style={{minHeight: minButtonHeight}}>
            <Button variant="link" block size="lg" onClick={closeModal}>
                <p className="mb-0 h6">{copy.close_button_label}</p>
            </Button>
        </div>
    );
}