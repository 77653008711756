import React from 'react';

export const income = (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M33.634 22.8C33.634 22.248 33.186 21.8 32.634 21.8C32.082 21.8 31.634 22.248 31.634 22.8V23.401C30.991 23.533 30.401 23.793 29.913 24.158C29.181 24.708 28.635 25.543 28.635 26.55C28.635 27.556 29.181 28.392 29.913 28.942C30.645 29.491 31.61 29.801 32.634 29.801C33.267 29.801 33.802 29.995 34.156 30.26C34.51 30.525 34.635 30.815 34.635 31.05C34.635 31.286 34.51 31.576 34.156 31.841C33.802 32.106 33.267 32.3 32.634 32.3C32.6275 32.3 32.6207 32.3002 32.614 32.3005L32.6072 32.3007L32.594 32.301C32.235 32.316 31.878 32.243 31.553 32.09C31.228 31.937 30.945 31.708 30.728 31.422C30.393 30.983 29.766 30.897 29.326 31.232C28.887 31.566 28.801 32.193 29.136 32.633C29.548 33.175 30.085 33.61 30.701 33.9C31 34.041 31.313 34.145 31.634 34.213V34.8C31.634 35.352 32.082 35.8 32.634 35.8C33.186 35.8 33.634 35.352 33.634 34.8V34.199C34.277 34.066 34.868 33.807 35.356 33.441C36.088 32.892 36.635 32.057 36.635 31.05C36.635 30.043 36.088 29.208 35.355 28.66C34.623 28.111 33.658 27.801 32.634 27.801C32.001 27.801 31.467 27.608 31.114 27.342C30.761 27.077 30.635 26.787 30.635 26.55C30.635 26.313 30.76 26.023 31.113 25.758C31.465 25.494 31.997 25.301 32.627 25.3L32.684 25.298C33.039 25.285 33.393 25.358 33.715 25.51C34.04 25.663 34.323 25.892 34.541 26.177C34.875 26.617 35.502 26.703 35.942 26.368C36.382 26.034 36.467 25.407 36.133 24.967C35.721 24.425 35.184 23.99 34.567 23.7C34.269 23.559 33.955 23.455 33.634 23.387V22.8Z"/>
        <path
            d="M38.603 44.737C39.003 44.958 39.468 45.068 39.998 45.068C40.633 45.068 41.19 44.9 41.67 44.565C41.755 44.505 41.815 44.44 41.85 44.37C41.89 44.295 41.91 44.205 41.91 44.1C41.91 43.945 41.865 43.815 41.775 43.71C41.69 43.6 41.585 43.545 41.46 43.545C41.39 43.545 41.33 43.555 41.28 43.575C41.23 43.595 41.153 43.633 41.048 43.688C40.828 43.788 40.648 43.862 40.508 43.913C40.368 43.958 40.223 43.98 40.073 43.98C39.628 43.98 39.295 43.847 39.075 43.583C38.86 43.313 38.753 42.903 38.753 42.353C38.753 41.808 38.86 41.403 39.075 41.138C39.295 40.868 39.628 40.733 40.073 40.733C40.238 40.733 40.398 40.755 40.553 40.8C40.708 40.845 40.873 40.92 41.048 41.025C41.128 41.075 41.198 41.112 41.258 41.138C41.318 41.158 41.385 41.167 41.46 41.167C41.585 41.167 41.69 41.115 41.775 41.01C41.865 40.9 41.91 40.768 41.91 40.612C41.91 40.503 41.89 40.413 41.85 40.343C41.815 40.273 41.755 40.208 41.67 40.148C41.19 39.813 40.633 39.645 39.998 39.645C39.468 39.645 39.003 39.755 38.603 39.975C38.208 40.195 37.9 40.51 37.68 40.92C37.465 41.33 37.358 41.808 37.358 42.353C37.358 42.903 37.465 43.383 37.68 43.792C37.9 44.203 38.208 44.518 38.603 44.737Z"/>
        <path
            fillRule="evenodd"
            d="M43.561 44.737C43.961 44.958 44.431 45.068 44.971 45.068C45.506 45.068 45.971 44.958 46.366 44.737C46.766 44.513 47.074 44.198 47.289 43.792C47.504 43.383 47.611 42.903 47.611 42.353C47.611 41.803 47.504 41.325 47.289 40.92C47.079 40.51 46.774 40.195 46.374 39.975C45.978 39.755 45.511 39.645 44.971 39.645C44.431 39.645 43.961 39.755 43.561 39.975C43.166 40.195 42.861 40.51 42.646 40.92C42.431 41.325 42.324 41.803 42.324 42.353C42.324 42.903 42.431 43.383 42.646 43.792C42.861 44.198 43.166 44.513 43.561 44.737ZM45.908 43.59C45.684 43.87 45.371 44.01 44.971 44.01C44.571 44.01 44.259 43.87 44.033 43.59C43.809 43.31 43.696 42.898 43.696 42.353C43.696 41.808 43.809 41.398 44.033 41.123C44.259 40.843 44.571 40.703 44.971 40.703C45.366 40.703 45.676 40.843 45.901 41.123C46.131 41.403 46.246 41.813 46.246 42.353C46.246 42.893 46.134 43.305 45.908 43.59Z"/>
        <path
            d="M30.163 45.045C29.953 45.045 29.785 44.985 29.66 44.865C29.54 44.74 29.48 44.573 29.48 44.362V40.35C29.48 40.145 29.54 39.983 29.66 39.862C29.785 39.737 29.953 39.675 30.163 39.675C30.378 39.675 30.548 39.737 30.673 39.862C30.798 39.983 30.86 40.145 30.86 40.35V44.362C30.86 44.573 30.798 44.74 30.673 44.865C30.548 44.985 30.378 45.045 30.163 45.045Z"/>
        <path
            d="M36.346 39.862C36.226 39.737 36.071 39.675 35.881 39.675C35.691 39.675 35.536 39.737 35.416 39.862C35.301 39.983 35.244 40.145 35.244 40.35V42.735L32.964 39.9C32.844 39.75 32.676 39.675 32.461 39.675C32.271 39.675 32.116 39.737 31.996 39.862C31.876 39.987 31.816 40.15 31.816 40.35V44.362C31.816 44.568 31.876 44.733 31.996 44.858C32.116 44.983 32.271 45.045 32.461 45.045C32.656 45.045 32.811 44.983 32.926 44.858C33.041 44.733 33.099 44.568 33.099 44.362V41.978L35.379 44.82C35.494 44.97 35.664 45.045 35.889 45.045C36.079 45.045 36.234 44.983 36.354 44.858C36.474 44.733 36.534 44.568 36.534 44.362V40.35C36.534 40.15 36.471 39.987 36.346 39.862Z"/>
        <path
            d="M53.123 39.675C53.313 39.675 53.468 39.737 53.588 39.862C53.708 39.987 53.768 40.15 53.768 40.35V44.43C53.768 44.62 53.713 44.77 53.603 44.88C53.493 44.99 53.348 45.045 53.168 45.045C52.993 45.045 52.848 44.99 52.733 44.88C52.623 44.77 52.568 44.62 52.568 44.43V42.225L51.713 43.8C51.628 43.96 51.538 44.075 51.443 44.145C51.348 44.21 51.235 44.243 51.105 44.243C50.975 44.243 50.863 44.21 50.768 44.145C50.673 44.075 50.583 43.96 50.498 43.8L49.65 42.27V44.43C49.65 44.615 49.593 44.765 49.478 44.88C49.368 44.99 49.223 45.045 49.043 45.045C48.868 45.045 48.723 44.99 48.608 44.88C48.498 44.77 48.443 44.62 48.443 44.43V40.35C48.443 40.15 48.503 39.987 48.623 39.862C48.748 39.737 48.905 39.675 49.095 39.675C49.23 39.675 49.353 39.715 49.463 39.795C49.573 39.87 49.665 39.98 49.74 40.125L51.12 42.72L52.493 40.125C52.648 39.825 52.858 39.675 53.123 39.675Z"/>
        <path
            d="M54.906 44.835C55.016 44.945 55.174 45 55.379 45H57.974C58.189 45 58.351 44.955 58.461 44.865C58.571 44.775 58.626 44.645 58.626 44.475C58.626 44.115 58.409 43.935 57.974 43.935H56.084V42.84H57.824C58.039 42.84 58.201 42.795 58.311 42.705C58.421 42.615 58.476 42.485 58.476 42.315C58.476 41.96 58.259 41.783 57.824 41.783H56.084V40.778H57.974C58.409 40.778 58.626 40.597 58.626 40.237C58.626 40.068 58.571 39.938 58.461 39.847C58.351 39.758 58.189 39.713 57.974 39.713H55.379C55.174 39.713 55.016 39.768 54.906 39.878C54.796 39.987 54.741 40.145 54.741 40.35V44.362C54.741 44.568 54.796 44.725 54.906 44.835Z"/>
        <path
            d="M57 34H40C39.448 34 39 33.552 39 33C39 32.448 39.448 32 40 32H57C57.552 32 58 32.448 58 33C58 33.552 57.552 34 57 34Z"/>
        <path
            d="M79 70C79.5523 70 80 70.4477 80 71V78H88C88.5523 78 89 78.4477 89 79C89 79.5523 88.5523 80 88 80H80V87C80 87.5523 79.5523 88 79 88C78.4477 88 78 87.5523 78 87V80H70C69.4477 80 69 79.5523 69 79C69 78.4477 69.4477 78 70 78H78V71C78 70.4477 78.4477 70 79 70Z"/>
        <path
            fillRule="evenodd"
            d="M62.0242 85H24.533C23.066 85 21.658 84.417 20.621 83.379C19.583 82.342 19 80.934 19 79.467V20.533C19 19.066 19.583 17.658 20.621 16.621C21.658 15.583 23.066 15 24.533 15H60.657C62.123 15 63.53 15.583 64.567 16.619L78.314 30.366C79.351 31.404 79.933 32.81 79.933 34.277V61.0238C89.4405 61.5092 97 69.3718 97 79C97 88.9411 88.9411 97 79 97C71.1627 97 64.4953 91.9911 62.0242 85ZM76.9796 61.1121L76.9686 61.1134C74.794 61.3576 72.7366 61.9893 70.867 62.9378V56.8C70.867 56.248 70.419 55.8 69.867 55.8H29.067C28.514 55.8 28.067 56.248 28.067 56.8V74.933C28.067 75.486 28.514 75.933 29.067 75.933H61.2602C61.0891 76.9297 61 77.9544 61 79C61 80.3748 61.1541 81.7136 61.446 83H24.533C23.596 83 22.698 82.628 22.035 81.965C21.372 81.302 21 80.404 21 79.467V20.533C21 19.596 21.372 18.698 22.035 18.035C22.698 17.372 23.596 17 24.533 17H59.5V30.4C59.5 31.62 59.985 32.79 60.847 33.653C61.71 34.515 62.88 35 64.1 35H77.933V61.031C77.6129 61.0497 77.295 61.0768 76.9796 61.1121ZM68.867 57.8V64.121C68.5196 64.358 68.181 64.6069 67.8517 64.867H57.267V57.8H68.867ZM57.267 66.867H65.7036C63.8816 68.8626 62.5028 71.2699 61.723 73.933H57.267V66.867ZM41.667 66.867V73.933H30.067V66.867H41.667ZM43.667 73.933V66.867H55.267V73.933H43.667ZM30.067 64.867V57.8H41.667V64.867H30.067ZM43.667 64.867V57.8H55.267V64.867H43.667ZM61.5 30.4V17.102C62.121 17.255 62.694 17.575 63.153 18.034L76.899 31.78C77.25 32.131 77.518 32.547 77.694 33H64.1C63.41 33 62.749 32.726 62.262 32.238C61.774 31.751 61.5 31.09 61.5 30.4ZM79 95C87.8366 95 95 87.8366 95 79C95 70.1634 87.8366 63 79 63C70.1634 63 63 70.1634 63 79C63 87.8366 70.1634 95 79 95Z"/>
    </svg>
);
