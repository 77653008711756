import { decorate, observable, computed, action } from 'mobx';
import { BaseStore } from '../../../stores/base-store';

import { SWITCHES } from '../../../enums/waffle';
import get from 'lodash.get';

export class DealerSelectorStore extends BaseStore {
    miles = '20'; // default range of miles selected
    zipCode = null;

    dealerSelected = null;
    dealersOptions = null;
    // loading indicator of load Dealers
    isLoading = false;

    get canContinue() {
        return !!this.dealerSelected;
    }

    get isZipCodeValid() {
        return this.zipCode && /^\d{5}(-\d{4})?$/.test(this.zipCode);
    }

    updateDealerSelected(dealer) {
        if (dealer) this.dealerSelected = dealer;
    }

    updateSearchValues({ miles, zipCode }) {
        if (miles) this.miles = miles;
        if (zipCode) this.zipCode = zipCode;
    }

    async searchDealers() {
        this.isLoading = true;
        // remove dealer selected and options
        this.dealerSelected = null;
        this.dealerOptions = null;
        let vehicleType;
        if (this.historyStore.queryParams.rawVehicleData === '1') {
            vehicleType = this.historyStore.queryParams.rawType;
        }
        let endpoint;
        try {
            endpoint = `/dealer/by-radius/?zipcode=${this.zipCode}&radius=${this.miles}`;
            if (vehicleType) {
               endpoint = `${endpoint}&type=${vehicleType}`;
            }
            const request = await this.apiStore.fetch(endpoint);
            this.dealersOptions = this.mapDealersInfo(request.response);
        } catch (error) {
            console.error(error, '[Dealer Selector] trying to search dealers', {
                endpoint,
                miles: this.miles,
                zipCode: this.zipCode,
            });
        } finally {
            this.isLoading = false;
        }
    }

    mapDealersInfo(dealers) {
        return dealers.map((dealer) => ({
            id: dealer.dealership_identifier,
            name: dealer.dealership_name,
            phone: dealer.contact_phone_number,
            address: dealer.contact_address,
            zipCode: dealer.zipcode,
        }));
    }

    async isDealerSelectorEnabled(partnerId = null) {
        const hasDealershipAssociated = !!this.dealershipStore.dealershipId || !!this.dealershipStore.externalDealershipId;
        const isFlagEnabled = this.waffleStore.isSwitchActive(
            SWITCHES.ENABLE_DEALER_SELECTOR
        );

        if (!isFlagEnabled) return false;

        // Partner Experience
        // get info from Partner Configuration already loaded on store
        if (this.partnerStore.partner && !partnerId) {
            return get(this.partnerStore, "partner.enable_dealer_selector", false) && !hasDealershipAssociated;
        }

        // get info from Partner Configuration requesting to API
        if (partnerId) {
            const isEnabledForPartner = await this.partnerStore.getDealerSelectorEnabled(partnerId);
            return isEnabledForPartner && !hasDealershipAssociated
        }

        // Marketplace experience
        if (!partnerId) return !hasDealershipAssociated;

        return false;
    }
}

decorate(DealerSelectorStore, {
    miles: observable,
    zipCode: observable,
    dealerSelected: observable,
    dealersOptions: observable,
    isLoading: observable,

    canContinue: computed,
    isZipCodeValid: computed,

    updateDealerSelected: action,
    updateSearchValues: action,
    searchDealers: action,
    isDealerSelectorEnabled: action,
});
