import React from "react";
import classnames from 'classnames';
import {FixedTopBar} from "../fixed-top-bar";
import styles from "./fixed-logo-top-bar.module.scss";
import PropTypes from "prop-types";


/**
 * Reusable component for rendering our basic fixed header with a centered logo
 *
 *
 * @param {string|object}           [className] - additional class(es) added to header element
 * @param {string|React.Component}  logo - a string path for an image or a component containing the logo,
 *                                         if there isn't a logo, no nav is rendered
 * @param {string}                  alt - optional alt text for logo images
 * @param {boolean}                 useTheme - Toggle to use the theme primary bg color, otherwise defaults to background color
 */
export const FixedLogoTopBar = ({className = "", logo, alt, useTheme}) => {
    const headerClasses = classnames(
        className,
        styles.header,
        "spark-shadow-element",
        {
            "spark-bg-primary": useTheme,
            "spark-bg-white": !useTheme, /* Default background class */
        },
    );

    const Logo = () => {
        if (typeof logo === "string") {
            return <img src={logo} alt={alt || "Logo"} />;
        }
        if (typeof logo === "function") {
            const LogoComponent = logo;
            return <LogoComponent />;
        }
        return logo;
    };

    return logo ? (
        <FixedTopBar>
            <header className={headerClasses}>
                <div className={styles.logoContainer}>
                    <Logo />
                </div>
            </header>
        </FixedTopBar>
    ) : null;
};

FixedLogoTopBar.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
    alt: PropTypes.string,
    useTheme: PropTypes.bool,
};
