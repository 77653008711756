import React from "react";
import PropTypes from "prop-types";
import { Tab } from "octane-spark";

import copy from "../copy.json";

export const YearsForNew = ({ value, disabled, onChange }) => {
    return (
        <div>
            <Tab
                id="tab-years-for-new"
                initialValue={value}
                label={copy.fields.yearForNew.label}
                disabled={disabled}
                items={[
                    { value: "2025", label: "2025" },
                    { value: "2024", label: "2024" },
                    { value: "2023", label: "2023" },
                    { value: "2022", label: "2022" },
                ]}
                onChange={onChange}
            />
        </div>
    );
};

YearsForNew.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};
