/**
 * Get the item in the array that has a matching value.
 *
 * Ex:
 *  - If it's an array of strings, return the matched string.
 *  - If it's an array of objects, find the id value for that object
 *    and return the object that matches.
 *
 * @param {Array<object | string | number>} items
 * @param {string} value
 * @returns matched item [optional]
 */
export const getItemMatchingValue = (items, value) => {
    return items.find(
        (item) =>
            /* To ensure that we match numbers to string numbers,
                always cast `item` to a string if it's a number for 
                comparison to `value`. */
            castNumberToString(item) === value ||
            castNumberToString(getIdValue(item)) === value,
    );
};

/**
 * Casts numbers to strings
 *
 * @param {string | number | object} value
 * @returns value
 */
export const castNumberToString = (value) =>
    typeof value === "number" ? `${value}` : value;

/**
 * Gets the identifying value for an item
 * by either getting the value of the first key used to store
 * values that it can find or by returning the item itself
 *
 * @param {object | string | number} item
 * @returns id value string | number [optional]
 */
export const getIdValue = (item) =>
    getValueOfFirstTruthyKey(item, [
        "key",
        "uuid",
        "id",
        "slug",
        "label",
        "name",
    ]) || item;

/**
 * Gets the display value/label for an item
 * by either getting the value of the first key used to store
 * display values/labels that it can find or
 * by returning the item itself
 *
 * @param {object | string | number} item
 * @returns display value/label string | number [optional]
 */
export const getDisplayValue = (item) =>
    getValueOfFirstTruthyKey(item, ["label", "name", "slug", "key", "id"]) ||
    item;

/**
 * Gets the value of the first truthy key found.
 *
 * @param {object | string | number} itemToSearch
 * @param {Array} keys
 * @returns value string | number [optional]
 */
export const getValueOfFirstTruthyKey = (itemToSearch, keys) =>
    itemToSearch && itemToSearch[keys.find((key) => itemToSearch[key])];
