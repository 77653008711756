import React from 'react';
import styles from './custom-intercom-button.module.scss';
import {Svg} from "../svg"
import PropTypes from 'prop-types';
import classnames from 'classnames';

export class CustomIntercomButton extends React.Component {
    static propTypes = {
        is_desktop_footer: PropTypes.bool,
    };

    render() {
      const {is_desktop_footer} = this.props;
      return (
          <div>
              <button id="customIntercom" className={classnames(
                  styles.intercomElementStyle,
                  {[styles.intercomElementStickyFooterStyle]: is_desktop_footer},
              )}>
                  <Svg className={styles.intercomSvgStyle} svg="custom-intercom-icon"/>
              </button>
          </div>
      );
    }
}
