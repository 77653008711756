import { isMobile } from 'react-device-detect';
import {isWindowDefined, findMatchInArray} from "./general-utils";

export class OlGtmData {
    setup = false;
    offer_click = 0;
    product_views = 0;
    offer_approval = 0;
    offer_submission = 0;
}

/**
 * Holds the listener for the navigation-related events, as they're needed on all pages
 */
export class OlGtmDataHelpers {
    static initOlGtmData() {
        if (isWindowDefined()) {
            window.olGtmData = window.olGtmData || new OlGtmData();
            if (!window.olGtmData.setup) {
                window.olGtmData.setup = true;
                OlGtmDataHelpers._addListeners();
            }
        }
    }

    /**
     * Pushes any 'navigation' style push
     * Includes cross_domain_click, social_click, navigation
     *
     * @param {string} urlString - the url we are navigating to
     * @param {string} linkText - the text the url shows
     */
    static _linkClickIntermediary(urlString, linkText) {
        // Check string's destination
        const hostName = window.location.hostname;
        const octaneSites = ['roadrunnerfinancial.com', 'octanelending.com'];
        const socialSites = ['medium.com'];
        let octaneMatch = findMatchInArray(urlString, octaneSites);
        let socialMatch = findMatchInArray(urlString, socialSites);
        let linkObj = _getLinkClickObject(urlString, linkText);

        if (urlString.includes(hostName)) linkObj.event = 'navigation';

        else if (octaneMatch) linkObj.event = 'cross_domain_click';

        else if (socialMatch){
            linkObj.event = 'social_click';
            linkObj.social_network = socialMatch;
        }

        // Event is assigned, can push
        if (linkObj.event) _dataLayerPush(linkObj);
    }

    static _addListeners(){
        document.addEventListener('click', e => {
            const origin = e.target.closest('a');
            if (origin) {
                OlGtmDataHelpers._linkClickIntermediary(origin.href, e.target.innerText);
            }
        });
    }
}

/**
 * Pushes the specified object to the dataLayer, but assures it exists first
 *
 * @param {object} contents - the object to push to the dataLayer
 */
function _dataLayerPush(contents) {
    // Check for dataLayer existence, create if not
    if (isWindowDefined()) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            ...contents,
            'time_stamp': _getFormattedTimestamp()
        });
    }
}

/**
 * Gets a GTM value from the dataLayer
 *
 * @param {string} variableName - the variable name to get from GTM
 *
 * @returns {integer | null} - the value of the specified variable, only expecting integers
 */
function _getDataLayerValue(variableName) {
    let returnValue = null;
    if (isWindowDefined() && window.hasOwnProperty('google_tag_manager')) {
        const tagManagerId = window.rideOctaneStore.settings.GOOGLE_TAG_MANAGER_ID;
        returnValue = window['google_tag_manager'][tagManagerId].dataLayer.get(variableName);
    }
    return returnValue;
}

/**
 * Gets the value from either GTM or olGtmData
 * @param {string} variableName - the name of the variable to get
 * @returns {integer} - the requested value, incremented
 */
function _incrementAndGetGtmValue(variableName){
    // Attempt to get from GTM
    let value = _getDataLayerValue(variableName);
    if (typeof value === 'number') return ++value;

    // If failed to get from GTM, use olGtmData
    if (isWindowDefined()){
        // Create if not yet created and iterate the value before returning
        window.olGtmData = window.olGtmData || new OlGtmData();
        return ++window.olGtmData[variableName];
    }
}

/**
 * Prepares a timestamp in the following format: M/D/YYYY H:mm:ss TZ
 *
 * @returns {string} - the formatted timestamp
 */
function _getFormattedTimestamp() {
    // Creates a timestamp in the format of: "7/28/2022, 3:35:43 EDT"
    let timestamp = new Date().toLocaleString('en-US', {
      hour12: false,
      timeZoneName: 'short',
    });
    timestamp = timestamp.replace(/,/g, '');
    return timestamp;
}

/**
 * Creates an 'item' object for use with 'view_item' and 'view_item_list' dataLayer pushes
 *
 * @param {object} itemObject - object that contains item details
 * @param {string} itemMake - maker of the item
 *
 * @returns {object} - assembled 'item'
 */
export function _createItem(itemObject , itemMake = '') {
    return {
        'item_id': itemObject['uuid'],
        'item_name': itemObject['name'],
        'index': '',
        'item_brand': itemObject['vehicle_make'] ? itemObject['vehicle_make']['name'] : itemMake,
        'item_category': itemObject['d2c_vehicle_type'],
        'item_variant': itemObject['slug'],
        'item_list_id': '', // This will be an empty string, there is no matching value at this time
        'item_list_name': itemObject['item_list_name'] ? itemObject['item_list_name'] : '',
        'price': itemObject['msrp'] ? itemObject['msrp'].toString() : '',
        'quantity': '1',
        }
}

/**
 * Provides the generic 'link click' object
 *
 * @param {string} urlString - the string containing the URL
 * @param {string} linkText - the string containing the link text
 * @returns {string} - the formatted timestamp
 */
function _getLinkClickObject(urlString = '', linkText= ''){
	const pushObj = {
        'event': '',
		'link_text': linkText,
		'link_url': urlString,
	};
	return pushObj;
}

/**
 * Pushes a 'pageview' event to the dataLayer.
 *
 * @param {string} pageType -  string for the current PageType
 */
export function pushPageView(pageType) {
    if (isWindowDefined() && window.hasOwnProperty('document')) {
        // Get device Category
        const deviceCategory = isMobile ? 'Mobile' : 'Desktop';
        _dataLayerPush({
            'event': 'pageview',
            'page': {
                'page_location': window.location.pathname,
                'page_referrer': window.document.referrer,
                'page_type': pageType,
            },
            'device_category': deviceCategory,
        });
    }
}

/**
 * Pushes an 'offer_click' custom event to the dataLayer.
 *
 * @param {string} linkText - string for link text
 * @param {string} linkUrl -  string for the link's URL
 */
export function pushOfferClick(linkText, linkUrl) {
    _dataLayerPush({
        'event': 'offer_click',
        'link_text': linkText,
        'link_url': linkUrl,
        'custom_events': {
            'offer_click': _incrementAndGetGtmValue('offer_click'),
        }
    });
}

/**
 * Pushes an 'select_item' custom event to the dataLayer.
 *
 * @param {object} vehicle_sub_model - object for vehicle_sub_model data
 * @param {object} rideOctaneStore - object for rideOctaneStore data
 */
export function pushSelectItem(vehicle_sub_model, rideOctaneStore) {
    if (isWindowDefined()) {
        const subModel = vehicle_sub_model;
        const octaneStore = rideOctaneStore;
        let text, id, name, brand, category, list_id, list_name, variant, price;
        if (subModel !== undefined) {
            text = subModel.slug;
            list_id = subModel.uuid;
            price = !subModel.msrp ? "" : subModel.msrp.toString();
            list_name = !subModel.vehicle_model ? "" : !subModel.vehicle_model.slug ? "" : subModel.vehicle_model.slug;
        }
        if (octaneStore !== undefined && octaneStore.stores !== undefined) {
            id = octaneStore.stores.listingsStore.pageStore.selected_vehicle_configuration.uuid;
            name = octaneStore.stores.listingsStore.pageStore.selected_vehicle_configuration.name;
            variant = !octaneStore.stores.listingsStore.pageStore.selected_vehicle_configuration.vehicle_trim ? "" : octaneStore.stores.listingsStore.pageStore.selected_vehicle_configuration.vehicle_trim.name;
            brand = !octaneStore.stores.slugsStore.slugData.vehicle_make ? "" : octaneStore.stores.slugsStore.slugData.vehicle_make.name;
            category = !octaneStore.stores.slugsStore.slugData.vehicle_type ? "" : octaneStore.stores.slugsStore.slugData.vehicle_type.name;
        }
        _dataLayerPush({
            ..._getLinkClickObject(window.location.pathname, text),
            'event': 'select_item',
            'ecommerce': {
                'currency': 'USD',
                'items': [{
                    'item_id': id,
                    'item_name': name,
                    'index': '0',
                    'item_brand': brand,
                    'item_category': category,
                    'item_list_id': list_id,
                    'item_list_name': list_name,
                    'item_variant': variant,
                    'price': price,
                    'quantity': '1',
                }]
            }
        });
    }
}

/**
 * Pushes an 'application_flow' custom event to the dataLayer.
 * @param {string} applicationId - string for the application_id
 * @param {string} applicationStepName -  string for the application_step_name
 *
 */
 export function pushApplicationFlow(applicationId, applicationStepName,) {
    const pushableObject = {
        'event': 'application_flow',
        'application_step_name': applicationStepName,
    }

    // Only append applicationId if it is not undefined
     if (applicationId) {
         pushableObject['application_id'] = applicationId;
     }
      _dataLayerPush(pushableObject);

}

/**
 * Pushes an 'offer_approval' custom event to the dataLayer.
 * @param {string} applicationId - string for the application_id
 *
 */
 export function pushOfferApproval(applicationId) {
    _dataLayerPush({
        'event': 'offer_approval',
        'application_id': applicationId,
        'custom_events':{
            'offer_approval': _incrementAndGetGtmValue('offer_approval'),
        }}
    );
}

/**
 * Pushes an 'offer_submit' custom event to the dataLayer.
 * @param {string} linkText - string for the linkText
 * @param {string} applicationId - string for the application_id
 *
 */
 export function pushOfferSubmit(linkText, applicationId) {
     if (isWindowDefined()) {
         _dataLayerPush({
                 ..._getLinkClickObject(window.location.pathname, linkText),
                 'event': 'offer_submit',
                 'application_id': applicationId,
                 'custom_events': {
                     'offer_submission': _incrementAndGetGtmValue('offer_submission'),
                 }
         });
     }
}

/** Pushes a 'view_item_list' custom event to the dataLayer.
 *
 * @param {string} itemMake - the make
 * @param {object[]} itemModels - provided from vehicle-make
 */
export function pushViewItemList(itemMake, itemModels) {
    let itemList = [];
    for (let entry of itemModels){
        const itemListName = entry[0];
        const itemArray = entry[1];
        if (itemListName != undefined && itemArray != undefined) {
            for (let i = 0; i < itemArray.length; i++) {
                const item = itemArray[i];
                let itemObj = _createItem(item, itemMake);
                itemObj.item_list_name = itemListName;
                itemObj.index = i.toString();
                itemList.push(itemObj);
            }
        }
    }
    _dataLayerPush({
        'event': 'view_item_list',
        'ecommerce': {
            'currency': 'USD',
            'items': itemList,
        }});
}

/**
 * Pushes a 'view_item' custom event to the dataLayer.
 *
 * @param {object} itemProps - props from vehicle-model-tile
 */
export function pushViewItem(itemProps) {
    let itemList = [];
    itemList.push(_createItem(itemProps));
    _dataLayerPush({
        'event': 'view_item',
        'product_views': _incrementAndGetGtmValue('product_views'),
        'ecommerce': {
            'currency': 'USD',
            'items': itemList,
        }});
}

/**
 * Pushes an 'offer_search' custom event to the dataLayer.
 *
 * @param {string} linkText - string for link text
 * @param {string} linkUrl -  string for the link's URL
 */
export function pushOfferSearch(linkText, linkUrl) {
    _dataLayerPush({
            ..._getLinkClickObject(linkUrl, linkText),
            'event': 'offer_search',
    });
}
