import React from 'react';
import PropTypes from 'prop-types';

import {BaseVehicleGrid} from './base-vehicle-grid';
import {VehicleCategoryTile} from "./vehicle-category-tile";


export class VehicleCategoryGrid extends React.PureComponent {
    static propTypes = {
        numCols: PropTypes.shape({
            xs: PropTypes.number.isRequired,
            md: PropTypes.number.isRequired,
            lg: PropTypes.number.isRequired,
        }).isRequired,
        maxRows: PropTypes.shape({
            xs: PropTypes.number.isRequired,
            md: PropTypes.number.isRequired,
            lg: PropTypes.number.isRequired,
        }).isRequired,

        // controlled component props
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired,
                href: PropTypes.string,
                src: PropTypes.string.isRequired,
                alt: PropTypes.string,
            })
        ),
        loadItems: PropTypes.func,
    };

    static defaultProps = {
        numCols: {
            xs: 2,
            md: 2,
            lg: 4,
        },
        maxRows: {
            xs: 1,
            md: 1,
            lg: 1,
        }
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            items: props.items,
        };
    }

    componentDidMount() {
        if (this.props.loadItems) {
            this.props.loadItems().then(items => {
                this.setState({items});
            });
        }
    }

    componentWillReceiveProps(props) {
        if (props.items !== this.props.items) {
            this.setState({items: props.items});
        }
    }

    render() {
        const {items} = this.state;
        const {numCols, maxRows} = this.props;

        if (!items || !items.length) {
            return (
                <div className="spinner-border spark-text-secondary d-block my-5 mx-auto">
                    <span className="sr-only">Loading...</span>
                </div>
            );
        }

        return (
            <BaseVehicleGrid numItems={items.length} numCols={numCols} maxRows={maxRows}>
                {this.state.items.map(item => (
                    <VehicleCategoryTile key={item.id} {...item}/>
                ))}
            </BaseVehicleGrid>
        );
    }
}


