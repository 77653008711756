import React from "react";
import {inject} from "mobx-react";


export const ShowCommit = inject('rideOctaneStore')(
    function ({rideOctaneStore}) {
        const commit = rideOctaneStore.settings.GIT_COMMIT;

        return (
            <div className="d-none" data-sha={commit}>
                commit: {getShortCommit(commit)}
            </div>
        );
    }
)


function getShortCommit(commit) {
    if (commit) {
        commit = commit.substring(0, 7);
    }
    return commit;
}
