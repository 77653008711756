import React from "react";

export const stopwatch = (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M51 38C51 38.5523 50.5523 39 50 39C49.4477 39 49 38.5523 49 38C49 37.4477 49.4477 37 50 37C50.5523 37 51 37.4477 51 38Z"/>
        <path
            d="M50 79C50.5523 79 51 78.5523 51 78C51 77.4477 50.5523 77 50 77C49.4477 77 49 77.4477 49 78C49 78.5523 49.4477 79 50 79Z"/>
        <path
            d="M69 58C69 58.5523 69.4477 59 70 59C70.5523 59 71 58.5523 71 58C71 57.4477 70.5523 57 70 57C69.4477 57 69 57.4477 69 58Z"/>
        <path
            d="M29 58C29 58.5523 29.4477 59 30 59C30.5523 59 31 58.5523 31 58C31 57.4477 30.5523 57 30 57C29.4477 57 29 57.4477 29 58Z"/>
        <path
            d="M63.435 44.565C63.8255 44.9555 64.4587 44.9555 64.8492 44.565C65.2397 44.1745 65.2397 43.5413 64.8492 43.1508C64.4587 42.7602 63.8255 42.7602 63.435 43.1508C63.0445 43.5413 63.0445 44.1745 63.435 44.565Z"/>
        <path
            d="M35.1508 72.8492C35.5413 73.2398 36.1745 73.2398 36.565 72.8492C36.9555 72.4587 36.9555 71.8255 36.565 71.435C36.1745 71.0445 35.5413 71.0445 35.1508 71.435C34.7603 71.8255 34.7603 72.4587 35.1508 72.8492Z"/>
        <path
            d="M63.4351 71.435C63.0446 71.8256 63.0446 72.4587 63.4351 72.8493C63.8256 73.2398 64.4588 73.2398 64.8493 72.8493C65.2398 72.4587 65.2398 71.8256 64.8493 71.435C64.4588 71.0445 63.8256 71.0445 63.4351 71.435Z"/>
        <path
            d="M35.1506 43.1507C34.7601 43.5413 34.7601 44.1744 35.1507 44.565L35.1602 44.5744L49.2973 58.7115C49.6879 59.102 50.321 59.102 50.7115 58.7115C51.1021 58.321 51.1021 57.6878 50.7115 57.2973L36.5694 43.1552L36.5579 43.1439C36.1669 42.7602 35.5389 42.7625 35.1506 43.1507Z"/>
        <path
            d="M21 58C21 41.9837 33.9837 29 50 29C66.0163 29 79 41.9837 79 58C79 74.0163 66.0163 87 50 87C33.9837 87 21 74.0163 21 58ZM50 31C35.0883 31 23 43.0883 23 58C23 72.9117 35.0883 85 50 85C64.9117 85 77 72.9117 77 58C77 43.0883 64.9117 31 50 31Z"/>
        <path
            fillRule="evenodd"
            d="M39 9C39 8.44772 39.4477 8 40 8H60C60.5523 8 61 8.44772 61 9C61 9.55228 60.5523 10 60 10H51V21L50.9999 21.0132C61.8639 21.3015 71.5608 26.2727 78.1458 33.982L83.1406 29.7909L79.9266 25.9606C79.5716 25.5376 79.6268 24.9068 80.0499 24.5518C80.4729 24.1968 81.1037 24.252 81.4587 24.6751L85.3105 29.2655L85.3155 29.2713L85.3204 29.2772L89.1722 33.8676C89.5272 34.2907 89.472 34.9214 89.0489 35.2764C88.6258 35.6314 87.9951 35.5763 87.6401 35.1532L84.4261 31.323L79.4036 35.5374C84.1689 41.7656 87 49.5522 87 58C87 78.4345 70.4345 95 50 95C29.5655 95 13 78.4345 13 58C13 37.8998 29.0278 21.5431 49.0001 21.0132L49 21V10H40C39.4477 10 39 9.55228 39 9ZM85 58C85 77.33 69.33 93 50 93C30.67 93 15 77.33 15 58C15 38.67 30.67 23 50 23C69.33 23 85 38.67 85 58Z"/>
    </svg>
);
