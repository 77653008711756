import {decorate, observable, computed, action} from 'mobx';

import {BaseVehicleListingPageStore} from "./base/base-vehicle-listing-page-store";
import {getSortedVehicleData} from "../../utils/vehicle-data-logic";
import {PageTitles} from "../../pages/octane-title";


/**
 * @class SubmodelPageStore
 * @inheritDoc
 *
 * Page store for submodel pages
 *
 * @property {object} vehicle_make
 * @property {object} vehicle_type
 * @property {object} vehicle_model
 * @property {object} vehicle_sub_model
 *
 * @property {object} default_vehicle_trim
 * @property {object} vehicle_trims             {map: <object>, uuids: <array>}
 *
 * @property {object} default_vehicle_configuration
 * @property {object} vehicle_configurations    {map: <object>, uuids: <array>}
 *
 * @property {object} vehicleSubmodelColorsAndStyles
 */
export class SubmodelPageStore extends BaseVehicleListingPageStore {
    vehicle_sub_model = null;
    vehicle_trims = null;
    vehicle_configurations = null;
    selected_vehicle_configuration = null;
    make_is_financeable = null;

    static MAKE_FIELDS = [
        'is_financeable',
    ];

    static SUBMODEL_FIELDS = [
        'uuid',
        'slug',
        'name',
        'default_image',
        'default_vehicle_trim',
        'copy',
        'spec_highlights',
        'vehicle_model',
        'live_years',
    ];

    static TRIMS_FIELDS = [
        'uuid',
        'slug',
        'default_vehicle_configuration',
        'vehicle_sub_model',
    ];
    static CONFIGURATIONS_FIELDS = [
        'name',
        'uuid',
        'slug',
        'msrp',
        'details',
        'default_image',
        'vehicle_trim',
        'color',
    ];

    getPrimaryVehicleObject() {
        return this.vehicle_sub_model;
    }

    get vehicle_make() {
        return this.listingsStore.slugsStore.slugData.vehicle_make;
    }

    get vehicle_type() {
        return this.listingsStore.slugsStore.slugData.vehicle_type;
    }

    get vehicle_model() {
        return this.listingsStore.slugsStore.slugData.vehicle_model;
    }

    get default_vehicle_trim() {
        const defaultVehicleConfiguration = this.default_vehicle_configuration;
        if (!!defaultVehicleConfiguration && !!defaultVehicleConfiguration.vehicle_trim) {
            return this.default_vehicle_configuration.vehicle_trim;
        }
        return null;
    }

    get default_vehicle_configuration() {
        if (!!this.vehicle_configurations) {
            const sortedConfigs = this.vehicle_configurations.getObjects();
            return sortedConfigs.length > 0 ? sortedConfigs[0] : null;
        }
        return null;
    }

    get logSummary() {
        return `Vehicle SubModel uuid: ${this.vehicle_sub_model.uuid} slug: ${this.vehicle_sub_model.slug}`
    }

    fetchInitialStoreData() {
        const {apiStore, slugsStore} = this.listingsStore;
        const makeUuid = slugsStore.slugData.vehicle_make.uuid;
        const uuid = slugsStore.slugData.vehicle_sub_model.uuid;

        // get the year passed in the slug
        const vehicleYear = slugsStore.pathSegments.length > 0 ? parseInt(slugsStore.pathSegments[0]) : null;

        if (isNaN(vehicleYear)) {
            return Promise.all([{status:404}]);
        }

        // FETCH MAKE
        const makeOptions = {
            query: {
                fields: SubmodelPageStore.MAKE_FIELDS,
            },
        };
        const makePromise = apiStore.fetch(`makes/${makeUuid}`, makeOptions)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.warn(`Failed to fetch make with uuid ${uuid} (status=${status})`);
                    return {status: 404};
                }

                return {status: 200, data: {vehicle_make: response}};
            })
            .catch(e => {
                console.warn(`Error occurred trying to fetch make with uuid=${uuid}`, e);
                return {status: 500};
            });

        // FETCH SUBMODEL
        const submodelOptions = {
            query: {
                vehicle_year: (vehicleYear == null)? 'default' : vehicleYear,
                fields: SubmodelPageStore.SUBMODEL_FIELDS,
            },
        };
        const submodelPromise = apiStore.fetch(`submodels/${uuid}`, submodelOptions)
            .then(({status, response}) => {
                if (status !== 200) {
                    console.warn(`Failed to fetch submodel with uuid=${uuid} (status=${status})`);
                    return {status: 404};
                }

                return {status: 200, data: {vehicle_sub_model: response}};
            })
            .catch(e => {
                console.warn(`Error occurred trying to fetch submodel with slug ${uuid}`, e);
                return {status: 500};
            });

        // FETCH VEHICLE TRIMS
        const trimsOptions = {
            query: {
                vehicle_sub_models: [uuid],
                fields: SubmodelPageStore.TRIMS_FIELDS,
            },
        };
        const trimsPromise = apiStore.fetch('trims', trimsOptions)
            .then(({status, response}) => {
                if (status !== 200 || !response || !response.results) {
                    console.warn(`Failed to fetch trims for submodel with uuid=${uuid} (status=${status})`);
                    return {status: 404};
                }
                return {status: 200, data: {vehicle_trims: response.results}};
            })
            .catch(e => {
                console.warn(`Error occurred trying to fetch trims for submodel with uuid=${uuid} `, e);
                return {status: 500};
            });

        // FETCH VEHICLE CONFIGURATIONS
        // this could be optimized to not request the details field if the page load is too slow
        const configurationsOptions = {
            query: {
                vehicle_sub_models: [uuid],
                fields: SubmodelPageStore.CONFIGURATIONS_FIELDS,
            },
        };
        const configurationsPromise = apiStore.fetch('configurations', configurationsOptions)
            .then(({status, response}) => {
                if (status !== 200 || !response || !response.results) {
                    console.warn(`Failed to fetch configurations for submodel with uuid=${uuid} (status=${status})`);
                    return {status: 404};
                }
                return {status: 200, data: {vehicle_configurations: response.results}};
            })
            .catch(e => {
                console.warn(`Error occurred trying to fetch configurations for submodel with uuid=${uuid} `, e);
                return {status: 500};
            });

        return Promise.all([makePromise, submodelPromise, trimsPromise, configurationsPromise]);
    }

    parseInitialRawData({vehicle_make, vehicle_sub_model, vehicle_trims, vehicle_configurations}) {
        this.make_is_financeable = vehicle_make.is_financeable;
        const sortedVehicleData = getSortedVehicleData({
            vehicleConfigurations: vehicle_configurations,
            vehicleTrims: vehicle_trims,
            vehicleSubmodels: [vehicle_sub_model],
        });

        // getSortedVehicleData provides some additional data from other levels of the taxonomy
        const processedSubmodel = sortedVehicleData.vehicleSubmodels.getObject(vehicle_sub_model.uuid);
        this.vehicle_sub_model = !!processedSubmodel ? {...vehicle_sub_model, ...processedSubmodel} : vehicle_sub_model;

        this.vehicle_trims = sortedVehicleData.vehicleTrims;
        this.vehicle_configurations = sortedVehicleData.vehicleConfigurations;
        this.selected_vehicle_configuration = this.default_vehicle_configuration;

        // set initial carousel image to the submodel's default image
        this.carouselImage = {
            alt: this._vehicleName(),
            image: this.vehicle_sub_model.default_image,
        };

        return this;
    }

    _vehicleName() {
        return [
            this.vehicle_make.name,
            this.vehicle_sub_model.name,
        ].join(' ');
    }

    _pageTitle() {
        return this._vehicleName();
    }

    _pageTitleWords() {
        const vehicleMakeName = !!this.vehicle_make && this.vehicle_make.name ? this.vehicle_make.name : '';
        const vehicleSubmodelName = !!this.vehicle_sub_model && this.vehicle_sub_model.name ? this.vehicle_sub_model.name : '';
        if (!!vehicleMakeName && !!vehicleSubmodelName) {
            const vehicleTitle = `${vehicleMakeName} ${vehicleSubmodelName} ${PageTitles.MODEL_AND_SUBMODEL_TITLE}`;
            return [vehicleTitle, PageTitles.OCTANE_TITLE];
        }
        return [];
    }

    _breadcrumbs() {
        return [
            {name: "Vehicles"},
            {name: this.vehicle_type.name + 's'},
            {name: this.vehicle_make.name},
            {name: this.vehicle_model.name, slug: this.vehicle_model.slug},
            {name: this.vehicle_sub_model.name},
        ];
    }

    _carouselImages() {
        // todo: eventually there should be a list of other images as well
        return [{...this.carouselImage}];
    }

    /**
     * Computed observable which builds props passed to VehicleSubmodelColorsAndStyles
     */
    get vehicleSubmodelColorsAndStyles() {
        return {
            vehicle_sub_model: this.vehicle_sub_model,
            vehicle_configuration: this.selected_vehicle_configuration,
        };
    }

    updateSelectedVehicle = selectedConfig => {
        this.selected_vehicle_configuration = this.vehicle_configurations.getObject(selectedConfig.uuid);
    }
}

decorate(SubmodelPageStore, {
    vehicle_sub_model: observable,
    vehicle_trims: observable,
    vehicle_configurations: observable,
    selected_vehicle_configuration: observable,

    vehicle_make: computed,
    vehicle_type: computed,
    vehicle_model: computed,
    default_vehicle_trim: computed,
    default_vehicle_configuration: computed,

    vehicleSubmodelColorsAndStyles: computed,

    parseInitialRawData: action,
    updateSelectedVehicle: action,
});
