import React from "react";
import ReactGA from "react-ga";

/**
 * Note that withTracker was largely lifted from an example
 * in the react-ga github wiki:
 *
 * https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */
export const withTracker = (WrappedComponent, options = {}) => {
    const trackPage = page => {
        ReactGA.set({
            page,
            ...options,
        });
        ReactGA.pageview(page);
    };

    class ComponentWithTrackerHOC extends React.Component {
        componentDidMount() {
            const page = this.props.location.pathname + this.props.location.search;
            trackPage(page);
        }

        componentDidUpdate(prevProps) {
            const currentPage =
                prevProps.location.pathname + prevProps.location.search;
            const nextPage =
                this.props.location.pathname + this.props.location.search;

            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    return ComponentWithTrackerHOC;
};
