import React from 'react';

export const offersAvailableWithNumber = (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M36.8525 20C36.8525 19.4477 36.4048 19 35.8525 19C35.3003 19 34.8525 19.4477 34.8525 20V20.6008C34.2102 20.7334 33.6196 20.9929 33.1323 21.3583C32.3999 21.9074 31.8533 22.7426 31.8533 23.7498C31.8533 24.7568 32.3997 25.5922 33.1319 26.1418C33.8638 26.6911 34.8291 27.0014 35.8525 27.0014C36.4846 27.0014 37.0177 27.1948 37.3702 27.4596C37.7223 27.724 37.8475 28.0135 37.8475 28.2501C37.8475 28.4856 37.7223 28.7756 37.3684 29.041C37.0143 29.3066 36.4795 29.5003 35.8467 29.5003C35.8335 29.5003 35.8202 29.5006 35.8069 29.5011C35.448 29.5154 35.0908 29.443 34.7658 29.2899C34.4408 29.1368 34.1574 28.9077 33.9398 28.6218C33.6053 28.1824 32.9778 28.0974 32.5384 28.432C32.099 28.7665 32.014 29.394 32.3485 29.8334C32.7609 30.375 33.2978 30.8092 33.9137 31.0993C34.2144 31.2409 34.5295 31.3461 34.8525 31.4135V32C34.8525 32.5523 35.3003 33 35.8525 33C36.4048 33 36.8525 32.5523 36.8525 32V31.3979C37.4932 31.2649 38.0822 31.0056 38.5683 30.6411C39.3006 30.0919 39.8475 29.2568 39.8475 28.2501C39.8475 27.2446 39.3028 26.4098 38.5712 25.8603C37.8401 25.3113 36.8757 25.0014 35.8525 25.0014C35.2207 25.0014 34.6863 24.8078 34.3324 24.5422C33.9788 24.2768 33.8533 23.9864 33.8533 23.7498C33.8533 23.5133 33.9787 23.2234 34.3321 22.9584C34.6845 22.6942 35.216 22.5012 35.8448 22.4996L35.8525 22.4996C35.8692 22.4996 35.8857 22.4992 35.9021 22.4984C36.2578 22.4855 36.6116 22.5578 36.9337 22.7095C37.2586 22.8625 37.5417 23.0917 37.759 23.3775C38.0933 23.8172 38.7206 23.9026 39.1603 23.5684C39.6 23.2341 39.6854 22.6068 39.3512 22.1671C38.939 21.6249 38.402 21.1903 37.7858 20.9001C37.4869 20.7594 37.1736 20.6546 36.8525 20.5872V20Z"/>
        <path
            d="M34.4357 44.7375C34.8357 44.9575 35.3057 45.0675 35.8457 45.0675C36.3807 45.0675 36.8457 44.9575 37.2407 44.7375C37.6407 44.5125 37.9482 44.1975 38.1632 43.7925C38.3782 43.3825 38.4857 42.9025 38.4857 42.3525C38.4857 41.8025 38.3782 41.325 38.1632 40.92C37.9532 40.51 37.6482 40.195 37.2482 39.975C36.8532 39.755 36.3857 39.645 35.8457 39.645C35.3057 39.645 34.8357 39.755 34.4357 39.975C34.0407 40.195 33.7357 40.51 33.5207 40.92C33.3057 41.325 33.1982 41.8025 33.1982 42.3525C33.1982 42.9025 33.3057 43.3825 33.5207 43.7925C33.7357 44.1975 34.0407 44.5125 34.4357 44.7375ZM36.7832 43.59C36.5582 43.87 36.2457 44.01 35.8457 44.01C35.4457 44.01 35.1332 43.87 34.9082 43.59C34.6832 43.31 34.5707 42.8975 34.5707 42.3525C34.5707 41.8075 34.6832 41.3975 34.9082 41.1225C35.1332 40.8425 35.4457 40.7025 35.8457 40.7025C36.2407 40.7025 36.5507 40.8425 36.7757 41.1225C37.0057 41.4025 37.1207 41.8125 37.1207 42.3525C37.1207 42.8925 37.0082 43.305 36.7832 43.59Z"/>
        <path
            d="M39.985 45.045C39.78 45.045 39.615 44.9825 39.49 44.8575C39.365 44.7325 39.3025 44.5675 39.3025 44.3625V40.35C39.3025 40.145 39.3575 39.9875 39.4675 39.8775C39.5775 39.7675 39.735 39.7125 39.94 39.7125H42.46C42.685 39.7125 42.85 39.7575 42.955 39.8475C43.06 39.9325 43.1125 40.07 43.1125 40.26C43.1125 40.445 43.0575 40.58 42.9475 40.665C42.8425 40.75 42.68 40.7925 42.46 40.7925H40.66V41.7675H42.31C42.53 41.7675 42.6925 41.8125 42.7975 41.9025C42.9075 41.9925 42.9625 42.13 42.9625 42.315C42.9625 42.5 42.9075 42.635 42.7975 42.72C42.6925 42.805 42.53 42.8475 42.31 42.8475H40.66V44.3625C40.66 44.5725 40.5975 44.74 40.4725 44.865C40.3525 44.985 40.19 45.045 39.985 45.045Z"/>
        <path
            d="M44.3209 45.045C44.1159 45.045 43.9509 44.9825 43.8259 44.8575C43.7009 44.7325 43.6384 44.5675 43.6384 44.3625V40.35C43.6384 40.145 43.6934 39.9875 43.8034 39.8775C43.9134 39.7675 44.0709 39.7125 44.2759 39.7125H46.7959C47.0209 39.7125 47.1859 39.7575 47.2909 39.8475C47.3959 39.9325 47.4484 40.07 47.4484 40.26C47.4484 40.445 47.3934 40.58 47.2834 40.665C47.1784 40.75 47.0159 40.7925 46.7959 40.7925H44.9959V41.7675H46.6459C46.8659 41.7675 47.0284 41.8125 47.1334 41.9025C47.2434 41.9925 47.2984 42.13 47.2984 42.315C47.2984 42.5 47.2434 42.635 47.1334 42.72C47.0284 42.805 46.8659 42.8475 46.6459 42.8475H44.9959V44.3625C44.9959 44.5725 44.9334 44.74 44.8084 44.865C44.6884 44.985 44.5259 45.045 44.3209 45.045Z"/>
        <path
            d="M48.6119 45C48.4069 45 48.2494 44.945 48.1394 44.835C48.0294 44.725 47.9744 44.5675 47.9744 44.3625V40.35C47.9744 40.145 48.0294 39.9875 48.1394 39.8775C48.2494 39.7675 48.4069 39.7125 48.6119 39.7125H51.2069C51.4219 39.7125 51.5844 39.7575 51.6944 39.8475C51.8044 39.9375 51.8594 40.0675 51.8594 40.2375C51.8594 40.5975 51.6419 40.7775 51.2069 40.7775H49.3169V41.7825H51.0569C51.4919 41.7825 51.7094 41.96 51.7094 42.315C51.7094 42.485 51.6544 42.615 51.5444 42.705C51.4344 42.795 51.2719 42.84 51.0569 42.84H49.3169V43.935H51.2069C51.6419 43.935 51.8594 44.115 51.8594 44.475C51.8594 44.645 51.8044 44.775 51.6944 44.865C51.5844 44.955 51.4219 45 51.2069 45H48.6119Z"/>
        <path
            d="M56.897 44.1225C56.987 44.2575 57.032 44.385 57.032 44.505C57.032 44.665 56.9645 44.8 56.8295 44.91C56.6995 45.015 56.547 45.0675 56.372 45.0675C56.252 45.0675 56.1395 45.04 56.0345 44.985C55.9295 44.93 55.8395 44.8475 55.7645 44.7375L54.7895 43.3125C54.7195 43.2125 54.6445 43.14 54.5645 43.095C54.4895 43.045 54.3995 43.02 54.2945 43.02H53.807V44.3625C53.807 44.5725 53.747 44.74 53.627 44.865C53.507 44.985 53.342 45.045 53.132 45.045C52.922 45.045 52.7545 44.985 52.6295 44.865C52.5095 44.74 52.4495 44.5725 52.4495 44.3625V40.35C52.4495 40.145 52.5045 39.9875 52.6145 39.8775C52.7245 39.7675 52.882 39.7125 53.087 39.7125H54.9695C55.6095 39.7125 56.0895 39.8525 56.4095 40.1325C56.7345 40.4075 56.897 40.8075 56.897 41.3325C56.897 41.7325 56.787 42.0725 56.567 42.3525C56.347 42.6275 56.037 42.8125 55.637 42.9075C55.802 42.9325 55.9445 42.9875 56.0645 43.0725C56.1895 43.1575 56.312 43.285 56.432 43.455L56.897 44.1225ZM54.752 41.985C55.057 41.985 55.2745 41.9375 55.4045 41.8425C55.5395 41.7475 55.607 41.5925 55.607 41.3775C55.607 41.1575 55.5395 41 55.4045 40.905C55.2745 40.805 55.057 40.755 54.752 40.755H53.792V41.985H54.752Z"/>
        <path
            d="M33.8533 59C33.301 59 32.8533 59.4477 32.8533 60C32.8533 60.5523 33.301 61 33.8533 61H63.8533C64.4056 61 64.8533 60.5523 64.8533 60C64.8533 59.4477 64.4056 59 63.8533 59H33.8533Z"/>
        <path
            d="M33.8533 53C33.301 53 32.8533 53.4477 32.8533 54C32.8533 54.5523 33.301 55 33.8533 55H63.8533C64.4056 55 64.8533 54.5523 64.8533 54C64.8533 53.4477 64.4056 53 63.8533 53H33.8533Z"/>
        <path
            d="M23.6078 51.6022C23.7413 51.6793 23.8943 51.6947 24.067 51.6484C24.2397 51.6021 24.363 51.5149 24.437 51.3868C24.5141 51.2533 24.5289 51.0981 24.4815 50.9212L24.1396 49.6452L25.5293 49.2728C25.7146 49.2232 25.8419 49.1507 25.9112 49.0554C25.9846 48.959 26.0005 48.8329 25.9587 48.677C25.917 48.5212 25.8396 48.4178 25.7267 48.3668C25.6179 48.3147 25.4709 48.3135 25.2856 48.3632L23.8959 48.7355L23.6758 47.9143L25.1919 47.5081C25.3772 47.4584 25.5045 47.386 25.5737 47.2907C25.6472 47.1943 25.663 47.0681 25.6213 46.9123C25.5784 46.7523 25.5032 46.6483 25.3955 46.6004C25.2868 46.5483 25.1377 46.5477 24.9482 46.5984L22.8256 47.1672C22.653 47.2134 22.5327 47.2953 22.4649 47.4128C22.3971 47.5302 22.3863 47.6753 22.4326 47.848L23.3381 51.2276C23.3844 51.4003 23.4743 51.5251 23.6078 51.6022Z"/>
        <path
            d="M19.3236 52.6418C19.7102 52.7368 20.1309 52.7234 20.5857 52.6015C21.0363 52.4808 21.4032 52.2832 21.6862 52.0087C21.9723 51.729 22.1603 51.3942 22.2499 51.0046C22.3385 50.6108 22.3207 50.1822 22.1966 49.719C22.0725 49.2557 21.8741 48.8778 21.6017 48.5852C21.3322 48.2872 21.0043 48.0908 20.6177 47.9957C20.2354 47.8996 19.8168 47.9124 19.3619 48.0343C18.9071 48.1562 18.5361 48.3549 18.2488 48.6305C17.9658 48.9049 17.78 49.2391 17.6914 49.6329C17.6017 50.0226 17.619 50.449 17.7431 50.9123C17.8672 51.3755 18.0661 51.7555 18.3397 52.0524C18.6122 52.3449 18.9402 52.5414 19.3236 52.6418ZM21.0419 51.1455C20.9156 51.4321 20.684 51.6206 20.3471 51.7108C20.0102 51.8011 19.7153 51.7537 19.4626 51.5686C19.2099 51.3836 19.0221 51.0616 18.8991 50.6025C18.7761 50.1435 18.7783 49.7728 18.9058 49.4903C19.0321 49.2037 19.2637 49.0153 19.6006 48.925C19.9333 48.8359 20.226 48.8838 20.4787 49.0689C20.7356 49.2528 20.925 49.5722 21.0469 50.027C21.1688 50.4818 21.1671 50.8547 21.0419 51.1455Z"/>
        <path
            d="M14.6756 30.5204C15.2091 30.3775 15.7574 30.694 15.9004 31.2275L16.0064 31.6231C16.2676 31.6159 16.5298 31.6355 16.7887 31.6822C17.3935 31.7912 17.963 32.0446 18.4488 32.4208C18.8855 32.759 18.9653 33.3871 18.6271 33.8238C18.289 34.2604 17.6609 34.3403 17.2242 34.0021C16.9929 33.823 16.7218 33.7024 16.434 33.6505C16.1461 33.5986 15.85 33.617 15.5707 33.7041C15.5578 33.7081 15.5449 33.7118 15.5318 33.7153C15.0235 33.8515 14.6417 34.1208 14.4223 34.4003C14.2031 34.6795 14.18 34.9112 14.2206 35.0627C14.2612 35.2144 14.3974 35.4039 14.727 35.5363C15.057 35.6689 15.5222 35.7114 16.0302 35.5752C16.9161 35.3378 17.827 35.3812 18.5948 35.69C19.3632 35.999 20.0453 36.606 20.2849 37.5005C20.5249 38.3961 20.2357 39.2635 19.7242 39.9152C19.4048 40.322 18.9853 40.6673 18.4994 40.9271L18.6086 41.3347C18.7516 41.8682 18.435 42.4165 17.9015 42.5595C17.368 42.7024 16.8197 42.3858 16.6768 41.8524L16.571 41.4575C16.3082 41.4651 16.0443 41.4455 15.7838 41.3985C15.1793 41.2896 14.61 41.0365 14.1242 40.6608C13.6873 40.3229 13.607 39.6949 13.9449 39.258C14.2827 38.8211 14.9108 38.7408 15.3477 39.0787C15.5792 39.2577 15.8505 39.3783 16.1385 39.4302C16.4266 39.4821 16.7229 39.4639 17.0024 39.3769L17.0166 39.3726C17.0246 39.3703 17.0326 39.368 17.0406 39.3658C17.5494 39.2295 17.9314 38.96 18.1511 38.6802C18.3705 38.4006 18.3935 38.1688 18.3531 38.0181C18.3124 37.8664 18.1766 37.6775 17.8486 37.5456C17.5201 37.4135 17.056 37.3709 16.5478 37.5071C15.6616 37.7445 14.75 37.7009 13.9814 37.3922C13.2126 37.0833 12.5287 36.4762 12.2887 35.5804C12.0486 34.6844 12.3376 33.8169 12.8492 33.1652C13.1694 32.7574 13.5901 32.4116 14.0775 32.1518L13.9685 31.7452C13.8256 31.2117 14.1421 30.6633 14.6756 30.5204Z"/>
        <path
            fillRule="evenodd"
            d="M31.8533 14C29.0918 14 26.8533 16.2386 26.8533 19V22.8762L10.8297 27.1697C8.16237 27.8844 6.57946 30.6261 7.29417 33.2934L18.2471 74.1704C18.9618 76.8377 21.7035 78.4206 24.3708 77.7059L38.2015 74H59.0273C59.0273 73.9999 59.0273 74.0001 59.0273 74L58.6525 74L59.0222 74.0991C59.0074 74.3977 59 74.6977 59 75C59 84.9411 67.0589 93 77 93C86.9411 93 95 84.9411 95 75C95 66.722 89.412 59.7491 81.8023 57.6477L88.4105 32.9855C89.1252 30.3182 87.5423 27.5765 84.875 26.8618L71.8532 23.3726L71.8533 19C71.8533 16.2386 69.6147 14 66.8533 14H31.8533ZM79.8454 57.2236L86.4787 32.4679C86.9075 30.8675 85.9577 29.2225 84.3574 28.7936L71.8532 25.4432L71.8533 57.7466C73.4839 57.2608 75.2115 57 77 57C77.9682 57 78.9186 57.0764 79.8454 57.2236ZM69.8533 58.4746V19C69.8533 17.3431 68.5101 16 66.8533 16H31.8533C30.1964 16 28.8533 17.3431 28.8533 19V69C28.8533 70.6569 30.1964 72 31.8533 72H59.2489C59.5454 70.2327 60.0994 68.5527 60.871 67H33.8533C33.301 67 32.8533 66.5523 32.8533 66C32.8533 65.4477 33.301 65 33.8533 65H62.0311C63.9463 62.1389 66.6599 59.8575 69.8533 58.4746ZM11.3473 29.1015L26.8533 24.9467V46.088L26.4777 46.1886C26.305 46.2349 26.1848 46.3167 26.1169 46.4342C26.0491 46.5517 26.0383 46.6968 26.0846 46.8694L26.8533 49.7382V57.8181L20.7552 59.4521C20.2217 59.595 19.9051 60.1434 20.0481 60.6768C20.191 61.2103 20.7394 61.5269 21.2728 61.3839L26.8533 59.8887V63.2345L22.1091 64.5057C21.5756 64.6486 21.259 65.197 21.402 65.7304C21.5449 66.2639 22.0932 66.5805 22.6267 66.4375L26.8533 65.305V68.651L23.4633 69.5593C22.9299 69.7023 22.6133 70.2506 22.7562 70.7841C22.8992 71.3176 23.4475 71.6341 23.981 71.4912L27.1309 70.6472C27.7047 72.2922 29.1137 73.5456 30.8499 73.8993L23.8532 75.7741C22.2528 76.2029 20.6078 75.2531 20.179 73.6527L9.22602 32.7758C8.7972 31.1754 9.74694 29.5304 11.3473 29.1015ZM93 75C93 83.8366 85.8366 91 77 91C68.1634 91 61 83.8366 61 75C61 66.1634 68.1634 59 77 59C85.8366 59 93 66.1634 93 75Z"/>
        <path
            d="M81.696 78.528C82.48 78.528 82.872 78.856 82.872 79.512C82.872 80.168 82.48 80.496 81.696 80.496H80.16V82.872C80.16 83.272 80.048 83.584 79.824 83.808C79.6 84.016 79.304 84.12 78.936 84.12C78.584 84.12 78.296 84.016 78.072 83.808C77.848 83.584 77.736 83.272 77.736 82.872V80.496H70.992C70.64 80.496 70.368 80.4 70.176 80.208C69.984 80.016 69.888 79.768 69.888 79.464C69.888 79.144 69.992 78.832 70.2 78.528L77.856 67.512C78.112 67.144 78.472 66.96 78.936 66.96C79.272 66.96 79.56 67.072 79.8 67.296C80.04 67.504 80.16 67.808 80.16 68.208V78.528H81.696ZM77.736 78.528V71.04L72.552 78.528H77.736Z"/>
    </svg>
);
