import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import noop from 'lodash.noop';
import template from "lodash.template";
import classnames from 'classnames';
import {inject, observer} from 'mobx-react';

import ReactMarkdown from 'react-markdown';
import {Button, Modal} from 'react-bootstrap';
import {RIDE_OCTANE_MODAL_ROUTES} from '../../stores/ride-octane-modal-store';

import copy from './copy';
import styles from './ride-octane-modal.module.scss';


/**
 * This modal is shown when a modal route is specified in the url hash. (i.e. #modal=financial_privacy_policy)
 */
export const RideOctaneModal = inject('rideOctaneModalStore', 'uxCopyStore', 'partnerStore', 'dealershipStore')(observer(
    class _RideOctaneModal extends React.Component {
        static propTypes = {
            rideOctaneModalStore: PropTypes.shape({
                shouldShowModal: PropTypes.bool.isRequired,
                specifiedModalRoute: PropTypes.string,
                specifiedModalConfig: PropTypes.shape({
                    props: PropTypes.object,
                    preventClose: PropTypes.bool,
                    fixedToBottom: PropTypes.bool,
                    dialogClassName: PropTypes.string,
                    ModalHeader: PropTypes.elementType.isRequired,
                    ModalContent: PropTypes.elementType.isRequired,
                }),
                closeModal: PropTypes.func.isRequired,
            }),
        };

        closeModal() {
            if (!this.props.rideOctaneModalStore.preventClose) {
                this.props.rideOctaneModalStore.closeModal();
            }
        }

        ModalHeader = ({modalRoute, modalConfig}) => {
            // modal specified by config
            if (modalConfig && modalConfig.ModalHeader) {
                const {ModalHeader, props = {}} = modalConfig;
                return <ModalHeader {...props}/>;
            }

            // modal specified by route or default, empty title ensures close button remains on the right
            const modalTitle = modalRoute ? get(copy,`${modalRoute}.title`, "") : "";
            return (
                <Modal.Header className={modalTitle === "" && "border-bottom-0"}>
                    <Modal.Title>
                        {modalTitle}
                    </Modal.Title>
                    <div className="text-right">
                        <Button className="text-decoration-none" variant="link" onClick={() => this.closeModal()}>
                            CLOSE X
                        </Button>
                    </div>
                </Modal.Header>
            );
        };

        ModalContent = ({modalRoute, modalConfig}) => {
            // modal specified by config
            if (modalConfig) {
                const {ModalContent, props = {}} = modalConfig;
                return <ModalContent {...props}/>;
            }

            // modal specified by route
            switch(modalRoute) {
                case RIDE_OCTANE_MODAL_ROUTES.e_sign_act_disclosure:
                    return (
                        <MarkdownModalContent
                            modalRoute={modalRoute}
                            uxCopyStore={this.props.uxCopyStore}
                            partnerStore={this.props.partnerStore}
                            dealershipStore={this.props.dealershipStore}
                        />
                    );

                case RIDE_OCTANE_MODAL_ROUTES.general_application_disclosure:
                    return (
                        <MarkdownModalContent
                            modalRoute={modalRoute}
                            uxCopyStore={this.props.uxCopyStore}
                            partnerStore={this.props.partnerStore}
                            dealershipStore={this.props.dealershipStore}
                        />
                    );

                case RIDE_OCTANE_MODAL_ROUTES.financial_privacy_policy:
                    return (
                        <IFrameModalContent modalRoute={modalRoute}/>
                    );

                case RIDE_OCTANE_MODAL_ROUTES.coppa_disclosure:
                    return (
                        <MarkdownModalContent
                            modalRoute={modalRoute}
                            uxCopyStore={this.props.uxCopyStore}
                        />
                    );

                case RIDE_OCTANE_MODAL_ROUTES.patriot_act_disclosure:
                    return (
                        <MarkdownModalContent
                            modalRoute={modalRoute}
                            uxCopyStore={this.props.uxCopyStore}
                        />
                    );

                case RIDE_OCTANE_MODAL_ROUTES.alternate_lenders_disclosure:
                    return (
                        <MarkdownModalContent
                            modalRoute={modalRoute}
                            uxCopyStore={this.props.uxCopyStore}
                        />
                    );

                default:
                    return null;
            }
        };

        render() {
            const {shouldShowModal, specifiedModalConfig} = this.props.rideOctaneModalStore;
            const dialogClassName = classnames(
                styles.rideOctaneModal,
                {[styles.openModal]: shouldShowModal},
                !!specifiedModalConfig && specifiedModalConfig.dialogClassName,
                (!!specifiedModalConfig && specifiedModalConfig.fixedToBottom) ? styles.rideOctaneModalMobileFixed : styles.rideOctaneModalMobile,
            );
            return (
                <Modal
                    show={shouldShowModal}
                    className={styles.modalContainer}
                    dialogClassName={dialogClassName}
                    onHide={this.props.rideOctaneModalStore.preventClose ? noop : this.props.rideOctaneModalStore.closeModal}
                    onExited={this.props.rideOctaneModalStore.clearModal}
                >
                    <this.ModalHeader
                        modalRoute={this.props.rideOctaneModalStore.specifiedModalRoute}
                        modalConfig={this.props.rideOctaneModalStore.specifiedModalConfig}
                    />
                    <Modal.Body>
                        <this.ModalContent
                            modalRoute={this.props.rideOctaneModalStore.specifiedModalRoute}
                            modalConfig={this.props.rideOctaneModalStore.specifiedModalConfig}
                        />
                    </Modal.Body>
                </Modal>
            );
        }
    }
));

/**
 * If our link starts with http, open the link in a new tab
*/
const LinkRenderer = props => {
    if (props.href.startsWith('http')) {
        return <a href={props.href} target="_blank">{props.children}</a>;
    }
    return <a href={props.href}>{props.children}</a>;
};

/**
 * This will use partner-specific markdown_content if the
 * partner has UXCopy set in API Server for the given modal route
 *
 * UXCopy keys (set in API Server)
 *  - ride-octane-modal.e_sign_act_disclosure.markdown_content
 *  - ride-octane-modal.general_application_disclosure.markdown_content
 */
function MarkdownModalContent({modalRoute, uxCopyStore, partnerStore, dealershipStore}) {
    const copyKey = `${modalRoute}.markdown_content`;
    const source_copy = uxCopyStore.getRideOctaneModalCopy(copyKey) || get(copy, copyKey) || "";
    const source_context = {
        legalEntityName: get(dealershipStore, 'dealership.legal_entity_name') ||
            get(partnerStore, 'partner.legal_entity_name'),
    };

    return (
        <ReactMarkdown
            className={styles.markdownContent}
            source={template(source_copy)(source_context)}
            renderers={{link: LinkRenderer}}
        />
    );
}

function IFrameModalContent({modalRoute}) {
    return (
        <iframe
            title="iframeModalContent"
            className={styles.iframeModalContent}
            src={get(copy, `${modalRoute}.iframe_src`, "")}
        />
    );
}
