import React from 'react';
import classnames from 'classnames';
import {Route, Switch} from "react-router-dom";
import ReactMarkdown from "react-markdown";

import {RideOctaneLink as Link} from '../pages/url-renderer';
import {Row, Col} from "react-bootstrap";

import copy from './copy.json';
import {ShowCommit} from "../show-commit";
import {Svg} from "../components/svg";

import styles from "./ride-octane-footer.module.scss";
import {getCurrentYear} from "../utils/date-utils";


export function RideOctaneFooter() {
    return (
        <div className="mt-auto spark-bg-primary spark-text-white p-3">
            <ShowCommit/>
            <Row>
                <Col xs={12} className="pb-3">
                    <Svg className="spark-text-white img-responsive" svg="octane-logo" style={{height: 33}}/>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={3}>
                    <p className="mb-2">
                        <span className="spark-text-white"
                        >{copy.physical_address}</span>
                    </p>
                </Col>
                {
                    copy.link_groups.map(
                        (rawLinkGroup, i) => {
                            return <LinkGroup key={i}
                                              rawLinkGroup={rawLinkGroup} />
                        }
                    )
                }
            </Row>
            <Row>
                <Col xs={12} className="col-md-12 border-top border-secondary-light pb-2 mb-3" />
            </Row>
            <Row>
                <Col md={3}>© {getCurrentYear()} Octane Lending. All Rights Reserved.</Col>
                {
                    copy.legal_labels.map((legalLabel, i) => (
                        <Col key={i} className={`${styles.legalLink} col-md-3`}>
                            {
                                legalLabel.is_link?
                                    <Link className="spark-text-white"
                                          to={legalLabel.href}
                                    >{legalLabel.label}</Link>
                                    : legalLabel.label
                            }
                        </Col>
                    ))
                }
            </Row>
            <Row>
                <Col xs={12} className="col-md-12 border-top border-secondary-light pb-2 mb-3" />
            </Row>
            <Row>
                <Col md={3}>
                    <p className="mb-4">
                        <button id='ot-sdk-btn' className='ot-sdk-show-settings'>Cookie Settings</button>
                    </p>
                </Col>
            </Row>

            {/* Disclosure only displayed on footer if footer is not on home page */}
            <Switch>
                {/* This is for matching the home page: eg: octane.co/ */}
                <Route exact path="/">
                    <Row>
                        <Col md={12}>
                            <ReactMarkdown className={classnames(styles.disclosureVehiclePage, "small spark-text-secondary")} source={copy.disclosure}/>
                        </Col>
                    </Row>
                </Route>
                {/* This is for matching the exact path of a slug: eg: /can-am-commander/ */}
                <Route exact path="/:slug">
                    <Row>
                        <Col md={12}>
                            <ReactMarkdown className={classnames(styles.disclosureVehiclePage, "small spark-text-secondary")} source={copy.disclosure}/>
                        </Col>
                    </Row>
                </Route>
                {/* This is for matching anything with a slug and extra paths appended to it. Eg: /2019-can-am-commander-800r-white/apply/ */}
                <Route path="/:slug">
                    <Row>
                        <Col md={12}>
                            <ReactMarkdown className={classnames(styles.disclosureGeneric, "small spark-text-white")} source={copy.disclosure}/>
                        </Col>
                    </Row>
                </Route>
            </Switch>

        </div>
    );
}

function LinkGroup({rawLinkGroup}) {
    return (
        <Col md={3}>
            {rawLinkGroup.links.map((rawLink, i) => (
                <p key={i} className="mb-2">
                    {
                        rawLink.is_external ?
                            rawLink.new_tab ? <a className="spark-text-white" href={rawLink.href} target={'_blank'}>{rawLink.label}</a>
                                : <a className="spark-text-white" href={rawLink.href}>{rawLink.label}</a>
                            : <Link className="spark-text-white"
                                  to={rawLink.href}>{rawLink.label}</Link>
                    }
                </p>
            ))}
        </Col>
    );
}
