import get from 'lodash.get';
import {BaseStore} from './base-store';


export class UXCopyStore extends BaseStore {
    copy = null;

    preRenderSetup() {
        // load copy on the server
        if (this.rideOctaneStore.isUXServer) {
            // make sure historyStore is ready (so that we know partnerIdentifier is populated)
            this.historyStore.onReady(() => {
                // make sure the apiStore is ready (so we know the urls to connect to the api are set)
                this.apiStore.onReady(() => {
                    // load copy and then update uxCopyStore.isReady
                    this.loadCopy().then(() => {
                        this.isReady = true;
                    });
                });
            });
            return this;
        }
        return super.preRenderSetup();
    }

    /**
     * Loads the copy from the api server
     *  e.g.
     *  {
     *      'ride-octane-modal.e_sign_act_disclosure.markdown_content': {string},
     *      'ride-octane-modal.general_application_disclosure.markdown_content': {string},
     *
     *      'about-you.continue_button.label': {string},
     *      'about-you.coapplicant_title': {string},
     *      'about-you.coapplicant_secondary_title': {string},
     *      'about-you.show_consent_text': {string}
     * 
     *      'additional-info.submit_button.label': {string},
     *
     *      'offers.approved.disclosure_block.body': {string},
     *      'offers.approved.decision_block.headline': {string},
     *      'offers.approved.decision_block.body': {string},
     *      'offers.approved.paperwork_block.body': {string},
     *      'offers-declined.decision_block.headline': {string},
     *      'offers-declined.decision_block.body': {string},
     *
     *      'additional-info.title': {string},
     *      'additional-info.titles.individual': {string},
     *      'additional-info.titles.joint' {string},
     *      'additional-info.subtitles.primary': {string},
     *      'additional-info.subtitles.coapplicant' {string},
     * 
     *      'pending-info.titles.principal': {string},
     *      'pending-info.titles.coapplicant': {string},
     *      'pending-info.body.principal': {string},
     *      'pending-info.body.coapplicant': {string},
     *      'pending-info.subtext.principal': {string},
     *      'pending-info.subtext.coapplicant': {string},
     *      'pending-info.button.principal': {string},
     *      'pending-info.button.coapplicant': {string},
     *
     *      'checklist-items.info_to_review.title': {string},
     *      'checklist-items.info_to_review.has_seen_text': {string},
     *      'checklist-items.info_to_review.instructions': {string},
     * 
     *      'dealer-checklist.title': {string}
     *      'dealer-checklist.text': {string}
     * 
     *      'complete-my-paperwork.manual_review.title': {string}
     *      'complete-my-paperwork.manual_review.text': {string}
     * 
     *  }
     *  @see https://github.com/OctaneLending/ride-octane-api/blob/master/ride_octane_api/intergalactic/enums.py#L4-L27
     */
    loadCopy() {
        const partnerIdentifier = this.partnerStore.partnerIdentifier;

        let optionsRequest = {}
        // if this is a partner experience pull copy for this partner
        if (partnerIdentifier) {
            optionsRequest = {query: {partner: partnerIdentifier}}
        }

        // fetch copy
        return this.apiStore.fetch(
            '/ux-copy',
            optionsRequest
        ).then(({response}) => {
            // we don't need to log an error if no copy is found because not all partners need have copy
            return response || {};
        }).catch(error => {
            console.error('Failed to fetch ux copy', {partnerIdentifier, error});
            return {};
        }).then(copy => {
            return this.updateCopy({copy});
        });
    }

    /**
     * Updates the store's copy property (called in load copy)
     */
    updateCopy({copy}) {
        this.copy = copy;
        return this;
    }

    /**
     * Gets partner specific copy for ride-octane-modal
     * @see src/app/components/ride-octane-modal/copy.json
     */
    getRideOctaneModalCopy(key, fallback = "") {
        return get(this.copy, `ride-octane-modal.${key}`, fallback);
    }

    /**
     * Gets partner specific copy for about-you, also used to create the disclosure in consent-store
     * @see src/app/prequal/about-you/copy.json
     */
    getAboutYouCopy(key, fallback = "") {
        return get(this.copy, `about-you.${key}`, fallback);
    }

    /**
     * Gets partner specific copy for prequal offers page
     * @see src/app/prequal/offers/copy.json
     */
    getOffersCopy(key, fallback = "") {
        return get(this.copy, `offers.${key}`, fallback);
    }

    /**
     * Gets partner specific copy for offers declined component
     * @see src/app/components/offers/offers-declined/copy.json
     */
    getOffersDeclinedCopy(key, fallback = "") {
        return get(this.copy, `offers-declined.${key}`, fallback);
    }

    /**
     * Gets partner specific copy for additional info
     * @see src/app/complete-my-paperwork/additional-info/copy.json
     */
    getAdditionalInfoCopy(key, fallback = "") {
        return get(this.copy, `additional-info.${key}`, fallback);
    }

    /**
     * Gets partner specific copy for additional info pending page
     * @see src/app/complete-my-paperwork/additional-info/pending-page/copy.json
     */
     getPendingCopy(key, fallback = "") {
        return get(this.copy, `pending-info.${key}`, fallback);
    }

    /**
     * Gets partner specific copy for info to review item
     * @see src/app/complete-my-paperwork/dealer-checklist/checklist-items/copy.json
     */
    getDealerChecklistItemCopy(key, fallback = "") {
        return get(this.copy, `checklist-items.${key}`, fallback);
    }

    /**
     * Gets partner specific copy for info to review item
     * @see src/app/complete-my-paperwork/dealer-checklist/copy.json
     */
     getDealerChecklistCopy(key, fallback = "") {
        return get(this.copy, `dealer-checklist.${key}`, fallback);
    }

    /**
     * Gets partner specific copy for complete my paperwork - manual review
     * @see src/app/complete-my-paperwork/additional-info/copy.json
     */
     getCompleteMyPaperworkManualReviewCopy(key, fallback = "") {
        return get(this.copy, `complete-my-paperwork.manual_review.${key}`, fallback);
    }
}
