import React from 'react';
import classnames from 'classnames';
import HamburgerMenuButton from "../side-drawer/hamburger-menu-button";
import {RideOctaneLink as Link} from "../../pages/url-renderer";
import {RideOctaneLinkContainer as LinkContainer} from "../../pages/url-renderer"; 

import {Svg} from "../svg";
import styles from './octane-toolbar.module.scss';
import copy from "./copy";
import {NavDropdown} from 'react-bootstrap';
import {OlGtmDataHelpers} from "../../utils/gtm-utils";

const OctaneToolbar = props => {

    const vehicle_make_names = props.vehicle_makes;
    const optionItems = vehicle_make_names.map((make) =>
    <LinkContainer key={make.name} to={"/".concat(make.slug)}>
        {/* parent containers can have the same key as their children, sibling components need unique keys*/}
        <NavDropdown.Item key={make.name} className={styles.navDropdownItems}>
                {make.name}
        </NavDropdown.Item>
    </LinkContainer>
    );

    const vehiclesTitle = <div>{copy.vehicles.label}<i className={classnames(styles.caretDown)}></i></div>;
    OlGtmDataHelpers.initOlGtmData();
    return (
        <div>
            <header className="spark-bg-primary spark-text-white">
            <nav className={classnames(styles.octaneToolbarNavigation, "px-3 d-flex align-items-center")}>
                <div className="d-md-none position-absolute">
                    <HamburgerMenuButton click={props.drawerClickHandler}/>
                </div>

                <div className="mx-auto mx-md-0">
                    <Link to={copy.home.url}>
                        <Svg className="spark-text-white" svg="octane-logo" style={{height: 33}}/>
                    </Link>
                </div>

                <div className="flex-grow-1 d-none d-md-block">
                    <ul className="d-flex justify-content-between list-unstyled my-0 mx-auto w-450px">
                        <li>
                            <Link to={copy.home.url} title={copy.home.label} className="nav-link">
                                {copy.home.label}
                            </Link>
                        </li>
                        <li>
                        <NavDropdown title={vehiclesTitle}>
                            {optionItems}
                        </NavDropdown>
                        </li>
                        <li>
                            <Link to={copy.check_your_rate.url} title={copy.check_your_rate.label} className="nav-link">
                                {copy.check_your_rate.label}
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="d-none d-md-block">
                    <a href={copy.for_dealers.url} title={copy.for_dealers.label}>
                        {copy.for_dealers.label}
                    </a>
                </div>
            </nav>
        </header>
        </div>
    );
};

export default OctaneToolbar;