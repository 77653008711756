export const residentialStatusKeys = ['OWN', 'RENT', 'RENT_FREE'];

export const residentialStatuses = {
    OWN: 0,
    RENT: 1,
    RENT_FREE: 2,
};

export const apiResidentialStatuses = {
    OWN: 'O',
    RENT: 'R',
    RENT_FREE: 'L',
};

export const residentialStatusDescriptions = {
    OWN: "I own",
    RENT: "I rent",
    RENT_FREE: "Rent-free",
};

/**
 *
 * @param residentialStatusIndex: Maps to one of the choices in residentialStatus.
 * @returns The API friendly value which corresponds to a given residential status.
 */
export function getResidentialStatusAPIValue(residentialStatusIndex) {
    for (const key of residentialStatusKeys) {
        if (residentialStatuses[key] === residentialStatusIndex) {
            return apiResidentialStatuses[key];
        }
    }
    return null;
}

/**
 *
 * @param residentialStatusIndex: Maps to one of the choices in residentialStatus.
 * @returns The user friendly value which corresponds to a given residential status.
 */
export function getResidentialStatusDescription(residentialStatusIndex) {
    for (const key of residentialStatusKeys) {
        if (residentialStatuses[key] === residentialStatusIndex) {
            return residentialStatusDescriptions[key];
        }
    }
    return null;
}
