import React from 'react';
import PropTypes from 'prop-types';
import {observer, inject} from 'mobx-react';
import {observable, runInAction} from "mobx";
import OctaneToolbar from "../toolbar/octane-toolbar";
import SideDrawer from "../side-drawer/side-drawer";
import Backdrop from "../backdrop/backdrop";


export const OctaneNav = inject('vehicleStore')(
    observer(
        class _OctaneNav extends React.Component {
            uiState = observable({
                sideDrawerOpen: false,
            });
            static propTypes = {
                vehicleStore: PropTypes.shape({
                    vehicle_makes: PropTypes.array.isRequired,
                }).isRequired,
            };

            drawerToggleClickHandler = () => {
                runInAction(() => {
                    this.uiState.sideDrawerOpen = !this.uiState.sideDrawerOpen;
                });
            };

            sideDrawerCloseHandler = () => {
                runInAction(() => {
                    this.uiState.sideDrawerOpen = false;
                });
            };

            backdropClickHandler = () => {
                runInAction(() => {
                    this.uiState.sideDrawerOpen = false;
                });
            };

            ToolbarComponent = observer(props => (
                <OctaneToolbar
                    {...props}
                    drawerClickHandler={this.drawerToggleClickHandler} 
                    vehicle_makes={this.props.vehicleStore.vehicle_makes}
                />
            ));

            SidebarComponent = observer(props => (
                <SideDrawer 
                    {...props}
                    show={this.uiState.sideDrawerOpen} 
                    closeDrawer={this.sideDrawerCloseHandler} 
                    vehicle_makes={this.props.vehicleStore.vehicle_makes}
                />
            ));

            render() {
                return (
                    <div>
                        <div>
                            <this.ToolbarComponent/>
                            <this.SidebarComponent/>
                            {!!this.uiState.sideDrawerOpen && (
                                <Backdrop click={this.backdropClickHandler}/>
                            )}
                            </div>
                    </div>
                );
            }
        }
    )
);
