import React from "react";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import classnames from 'classnames/dedupe';
import {FixedBottomBar} from "../fixed-bottom-bar";

import {FileUploadButton} from "../file-upload-button";
import {RideOctaneLinkContainer as LinkContainer} from "../../pages/url-renderer";
import {LOS_ALLOWED_DOCUMENT_CONTENT_TYPES} from "../../enums/allowedDocumentTypes";

/**
 * CTA Footer
 *
 * Renders a CTA footer with a primary CTA and (optionally) a secondary link CTA.
 * Used in our StickyCtaFooter
 */
export const CTAFooter = ({primary, secondary, className, isSticky = false}) => {
    let footer = (
        <div className={classnames("w-450px mw-100 mx-auto", {"my-4": !isSticky}, className)}>
            {!!primary && <CTAButton isPrimary {...primary} />}
            {!!secondary && <CTAButton isPrimary={false} {...secondary} />}
        </div>
    );

    if (isSticky === true) {
        footer = (
            <FixedBottomBar containerClass="p-3 p-sm-4">
                {footer}
            </FixedBottomBar>
        );
    }

    return footer;
}

CTAFooter.propTypes = {
    primary: PropTypes.object,
    secondary: PropTypes.object,
    className: PropTypes.oneOf([            // supports options for classnames/dedupe
        PropTypes.string,                   // simple class name "foo-class bar-class ..."
        PropTypes.objectOf(PropTypes.bool), // {"className": true || false}
        PropTypes.arrayOf(                  // more complex content combining multiple of the above
            PropTypes.oneOf([
                PropTypes.string,
                PropTypes.objectOf(PropTypes.bool)
            ])
        )
    ]),
    isSticky: PropTypes.bool,
};

/**
 * Helper component to handle logic to set props based on configs passed for each CTA
 */
const CTAButton = (props) => {
    // determine appearance of rendered cta (primary or secondary cta)
    let ctaAppearanceProps = null;
    if (props.isPrimary) {
        // large blue (or other theme color) button for primary CTA
        ctaAppearanceProps = {
            size: "lg",
            variant: "primary",
            className: "spark-text-16px-bold",
        };
    } else {
        // small blue (or other theme color) link for secondary CTA
        ctaAppearanceProps = {
            size: "sm",
            variant: "link",
            className: "spark-text-primary spark-text-16px",
        };
    }

    // render file upload button
    if (props.isFileUploadButton) {
        return (
            <FileUploadButton
                oid={props.oid}
                single={props.single}
                isDisabled={props.isDisabled}
                label={props.label}
                onClick={props.onClick}
                onFilesSelected={props.onFilesSelected}
                allowedDocumentTypes={LOS_ALLOWED_DOCUMENT_CONTENT_TYPES}
                {...ctaAppearanceProps}
            />
        );
    }

    // determine how to render ctas with href (open in new tab, same tab to external url, same tab to internal url)
    let ctaLinkProps = null;
    let ctaLinkContainerProps = null;
    if (props.href) {
        if (props.openLinkInNewTab) {
            // renders cta as a <a/> that opens a new tab
            ctaLinkProps = {
                as: "a",
                target: "_blank",
                href: props.href,
            };
        } else if (props.isExternalLink) {
            // renders cta as a <a/> that opens an external link in the same tab
            ctaLinkProps = {
                as: "a",
                href: props.href,
            };
        } else {
            // renders cta as a <a/> that navigates to another page within our ux application
            ctaLinkContainerProps = {
                to: props.href,
            };
        }
    }

    // render button CTA
    const buttonCta = (
        <Button
            block
            data-oid={props.oid}
            onClick={props.onClick}
            disabled={props.isDisabled}
            {...ctaAppearanceProps}
            {...ctaLinkProps}
        >
            {props.label}
        </Button>
    );

    // check if we need to wrap in <LinkContainer/>
    if (ctaLinkContainerProps) {
        // Note: activeClassName="" fixes bug that applies "active" class to button when we don't want it
        return (
            <LinkContainer {...ctaLinkContainerProps} activeClassName="">
                {buttonCta}
            </LinkContainer>
        );
    }
    return buttonCta;
}

CTAButton.propTypes = {
    isPrimary: PropTypes.bool,

    oid: PropTypes.string,
    href: PropTypes.string,
    label: PropTypes.string,

    isDisabled: PropTypes.bool,
    isExternalLink: PropTypes.bool,
    openLinkInNewTab: PropTypes.bool,

    onClick: PropTypes.func,

    // File Upload Button Specific Props
    isFileUploadButton: PropTypes.bool,
    single: PropTypes.bool,
    inputId: PropTypes.string,
    fieldName: PropTypes.string,
    onFilesSelected: PropTypes.func,
};
