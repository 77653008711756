import React from "react";
import PropTypes from "prop-types";

import { Tab } from "octane-spark";
import copy from "../copy.json";

export const Condition = ({ value, disabled, onChange }) => {
    return (
        <Tab
            id="tab-condition"
            label={copy.fields.condition.label}
            initialValue={value}
            disabled={disabled}
            items={[
                {
                    value: "N",
                    label: copy.fields.condition.items.new,
                },
                {
                    value: "U",
                    label: copy.fields.condition.items.used,
                },
            ]}
            onChange={onChange}
        />
    );
};

Condition.propTypes = {
    value: PropTypes.oneOf(["N", "U"]),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};
