import {decorate, observable, computed, action} from 'mobx';
import {BaseVehicleListingPageStore} from "./base/base-vehicle-listing-page-store";
import {PageTitles} from "../../pages/octane-title";
import {UUIDMap} from "../../utils/uuid-map";

/**
 * @class MakePageStore
 * @inheritDoc
 *
 * Page store for make pages
 *
 * @property {object} vehicle_make
 * @property {object} vehicle_type
 */
export class MakePageStore extends BaseVehicleListingPageStore {
    vehicle_make = null;
    vehicle_models = null;
    vehicle_configurations = null;

    static MODEL_FIELDS = [
        'uuid',
        'slug',
        'name',
        'default_image',
        'curated',
        'default_vehicle_configuration',
        'd2c_vehicle_type',
    ];

    static MAKE_FIELDS = [
        'uuid',
        'slug',
        'name',
        'curated',
    ];

    static CONFIGURATIONS_FIELDS = [
        'uuid',
        'msrp',
        'default_image',
    ];

    // represented in the display order
    static DISPLAYED_VEHICLE_TYPES = {
        'UTV': 'UTVs',
        'ATV': 'ATVs',
        'Motorcycle': 'Motorcycles',
        'Scooter': 'Scooters',
        '3-Wheel Motorcycle': '3-Wheel Motorcycles',
        'Pontoon': 'Pontoons',
        'Watercraft': 'Watercraft',
        'Snowmobile': 'Snowmobiles',
        'null': 'Others',
    }

    getPrimaryVehicleObject() {
        return this.vehicle_make;
    }

    get logSummary() {
        return `Vehicle Make uuid: ${this.vehicle_make.uuid} slug: ${this.vehicle_make.slug}`
    }

    get vehicle_type() {
        return this.listingsStore.slugsStore.slugData.vehicle_type;
    }

    fetchInitialStoreData() {
        const {apiStore, slugsStore} = this.listingsStore;
        const uuid = slugsStore.slugData.vehicle_make.uuid;

        // FETCH DATA RELATED TO THE MAKE
        const makeOptions = {
            query: {
                fields: MakePageStore.MAKE_FIELDS,
            },
        };
        const makePromise = apiStore.fetch(`makes/${uuid}`, makeOptions)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.warn(`Failed to fetch make with uuid ${uuid} (status=${status})`);
                    return {status: 404};
                }

                return {status: 200, data: {vehicle_make: response}};
            })
            .catch(e => {
                console.warn(`Error occurred trying to fetch make with uuid=${uuid}`, e);
                return {status: 500};
            });

        const getConfigurationsPromise = (vehicle_configuration_uuids) => {
            const configurationsOptions = {
                query: {
                    vehicle_configurations: vehicle_configuration_uuids,
                    fields: MakePageStore.CONFIGURATIONS_FIELDS,
                },
            };
            return apiStore.fetch('configurations', configurationsOptions)
                .then(({status, response}) => {
                    if (status !== 200 || !response || !response.results) {
                        console.warn(`Failed to fetch configurations for make (status=${status}). Configuration uuids: ${vehicle_configuration_uuids}`);
                        return {status: 404};
                    }
                    return {status: 200, data: {vehicle_configurations: response.results}};
                })
                .catch(e => {
                    console.warn(`Error occurred trying to fetch configurations for make. Configuration uuids: ${vehicle_configuration_uuids}`, e);
                    return {status: 500};
                });
        };

        // FETCH MODELS RELATED TO THE VEHICLE MAKE
        const modelOptions = {
            query: {
                vehicle_makes: [uuid],
                fields: MakePageStore.MODEL_FIELDS,
            },
        };
        const modelPromise = apiStore.fetch('models', modelOptions)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.warn(`Failed to fetch model with uuid ${uuid} (status=${status})`);
                    return {status: 404};
                }

                return {status: 200, data: {vehicle_models: response.results}};
            })
            .then(({data}) => {
                const {vehicle_models} = data;
                const configurationsToFetch = vehicle_models.map((vehicle_model) => vehicle_model.default_vehicle_configuration);
                const configurationsPromise = getConfigurationsPromise(configurationsToFetch);
                return configurationsPromise
                    .then(({status, data}) => {
                        const {vehicle_configurations} = data;
                        return {status, data: {vehicle_models, vehicle_configurations}};
                    });
            })
            .catch(e => {
                console.warn(`Error occurred trying to fetch model with uuid=${uuid}`, e);
                return {status: 500};
            });

        return Promise.all([makePromise, modelPromise]);
    }

    parseInitialRawData({vehicle_make, vehicle_models, vehicle_configurations}) {
        this.vehicle_make = {
            'name': vehicle_make.name,
            'uuid': vehicle_make.uuid,
            'slug': vehicle_make.slug,
            curated: vehicle_make.curated,
        };
        this.vehicle_models = new UUIDMap({dataArray: vehicle_models});
        this.vehicle_configurations = new UUIDMap({dataArray: vehicle_configurations});

        // set initial carousel image to the make's default image
        this.carouselImage = {
            alt: this._vehicleName(),
            image: this._vehicleImage(),
        };

        return this;
    }

    _vehicleName() {
        return this.vehicle_make.name;
    }

    _vehicleImage() {
        // This will change as a primary_image field or a similar field is added to VehicleMake.
        return this.vehicle_make.hero_image;
    }

    _pageTitle() {
        return this._vehicleName();
    }

    _pageTitleWords() {
        const vehicleMakeName = !!this.vehicle_make && this.vehicle_make.name ? this.vehicle_make.name : '';
        if (!!vehicleMakeName) {
            const vehicleTitle = `${vehicleMakeName} ${PageTitles.MAKE_TITLE}`;
            return [vehicleTitle, PageTitles.OCTANE_TITLE];
        }
        return [];
    }

    _breadcrumbs() {
        return [
            {name: "Vehicles"},
            {name: this.vehicle_type.name + 's'},
            {name: this.vehicle_make.name},
        ];
    }

    _carouselImages() {
        // todo: eventually there should be a list of other images as well
        return [{...this.carouselImage}];
    }

    /**
     * Computed observable which builds props passed to Vehicle Models Tile grouped by Vehicle Type
     */
    get vehicleModels() {
        // vehicle types mapped to its intended display index
        let indexed_vehicle_types = {};
        Object.keys(MakePageStore.DISPLAYED_VEHICLE_TYPES).map((vehicleType,index) => {
            indexed_vehicle_types[MakePageStore.DISPLAYED_VEHICLE_TYPES[vehicleType]] = index;
        })

        // dict of display vehicle types to models for each type
        let models_dict = this.vehicle_models.getObjects().filter(vehicle_model => {
           return !!vehicle_model.slug;
        }).reduce((vehicles_by_type, vehicle_model) => {
            const default_vehicle_configuration = !!vehicle_model.default_vehicle_configuration ?
                this.vehicle_configurations.getObject(vehicle_model.default_vehicle_configuration) : null;

            let model_to_push = vehicle_model;
            if (!!default_vehicle_configuration) {
                model_to_push = {
                    ...vehicle_model,
                    default_image: default_vehicle_configuration.default_image,
                    msrp: default_vehicle_configuration.msrp,
                }
            }

            let vehicle_type = MakePageStore.DISPLAYED_VEHICLE_TYPES['null'];
            if (vehicle_model['d2c_vehicle_type'] in MakePageStore.DISPLAYED_VEHICLE_TYPES) {
                vehicle_type = MakePageStore.DISPLAYED_VEHICLE_TYPES[vehicle_model['d2c_vehicle_type']];
            }
            (vehicles_by_type[vehicle_type] = vehicles_by_type[vehicle_type] || []).push(model_to_push);
            return vehicles_by_type;
        }, {});

        // sorted display vehicle types with list of models for each type
        return Object.keys(models_dict).map(
            vehicle_type => [vehicle_type, models_dict[vehicle_type]]
        ).sort(
            (a, b) => indexed_vehicle_types[a[0]] - indexed_vehicle_types[b[0]]
        );
    }

}

decorate(MakePageStore, {
    vehicle_make: observable,
    vehicle_models: observable,
    vehicle_configurations: observable,

    vehicle_type: computed,
    vehicleModels: computed,

    parseInitialRawData: action,
});
