import React from 'react';
import {observer} from "mobx-react";
import template from 'lodash.template';
import classnames from 'classnames';
import {Container, Row, Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";

import copy from './vehicle-make-copy.json';
import {VehicleModelTile} from "../components/vehicle-tile";
import {CustomIntercomButton} from "../components/intercom/custom-intercom-button";
import styles from "./vehicle-make.module.scss";
import {pushViewItemList} from "../utils/gtm-utils";

export const VehicleMake = observer(
    class _VehicleMake extends React.Component {

        componentDidMount() {
            const vehicleMake = this.props.pageStore.getPrimaryVehicleObject()['name'];
            pushViewItemList(vehicleMake, this.props.pageStore.vehicleModels);
            window.scrollTo(0, 0);
        }

        /**
         * Component that renders meta tags specific to this page.
         */
        MetaTags = () => {
            const {vehicleName} = this.props.pageStore;
            const metaDescription = template(copy.meta_description)({vehicleName}) || "";
            return (
                <React.Fragment>
                    <Helmet>
                        <meta name="description" content={metaDescription}/>
                    </Helmet>
                </React.Fragment>
            );
        };

        render() {
            const vehicleMake = this.props.pageStore.getPrimaryVehicleObject();
            const vehicleModels = this.props.pageStore.vehicleModels;
            return (
                <div>
                    <Container className={classnames(styles.heroContainer, "pt-0 px-0")}>
                        <this.MetaTags/>
                        {/* HERO IMAGE */}
                        <Row>
                            <Col xs={12}>
                                <img className={classnames(styles.heroImage, "img-fluid d-none d-sm-block")}
                                     alt={`${vehicleMake.name} hero image`}
                                     src={!!vehicleMake.curated ? vehicleMake.curated.hero_image : null}/>
                                <img className={classnames(styles.heroImageMobile, "img-fluid d-block d-sm-none")}
                                     alt={`${vehicleMake.name} hero image`}
                                     src={!!vehicleMake.curated ? (vehicleMake.curated.hero_image_mobile ? vehicleMake.curated.hero_image_mobile : vehicleMake.curated.hero_image) : null}/>
                            </Col>
                        </Row>
                    </Container>

                    <CustomIntercomButton/>
                    
                    <Container>
                        {/* Make Name */}
                        <Row className="mt-4">
                            <Col xs={12}>
                                <span className="h1">{vehicleMake.name}</span>
                            </Col>
                        </Row>

                        {/* DESCRIPTION: */}
                        {!!vehicleMake.curated && !!vehicleMake.curated.description && (
                            <Row className="mt-3 mb-3">
                                <Col xs={12}>
                                    <p>{vehicleMake.curated.description}</p>
                                </Col>
                            </Row>
                        )}

                        {/* Vehicle Models */}
                        {vehicleModels.map(modelsForType => (
                            modelsForType.length === 2 && (
                                <React.Fragment>
                                    <Row className="mt-3">
                                        <Col xs={12}>
                                            <h4>{modelsForType[0]}</h4>
                                        </Col>
                                    </Row>

                                     <Row>
                                        <Col xs={12}>
                                            <hr className="mt-1 mb-1"/>
                                        </Col>
                                    </Row>

                                    <Row>
                                        {modelsForType[1].map(vehicleModel => (
                                            <Col
                                                key={vehicleModel.name}
                                                xs={{span: 12, order: 2}}
                                                lg={{span: 5, order: 2}}
                                                className="mt-2"
                                            >
                                                <VehicleModelTile
                                                    slug={vehicleModel.slug}
                                                    name={vehicleModel.name}
                                                    description={!!vehicleModel.curated ? vehicleModel.curated.short_description : ''}
                                                    vehicle_make={vehicleMake}
                                                    default_image={vehicleModel.default_image}
                                                    msrp={vehicleModel.msrp}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </React.Fragment>
                            )
                        ))}
                    </Container>
                </div>
            );
        }
    }
);
